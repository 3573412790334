import React from "react";
import { Navbar, Nav, Container, Spinner, Offcanvas, Stack } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faRightFromBracket,
  faHouse,
  faAddressCard,
  faCircleInfo,
  faEnvelope,
  faBook,
  faClipboard,
  faCircleQuestion,
  faShieldHalved,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { signOut } from "aws-amplify/auth";

import "./Menu.css";
import { useAuthContext } from "../contexts/AuthContext";
import { getUserRoleName } from "../model/constant";

const Menu: React.FC = () => {
  const { state, dispatcher } = useAuthContext();
  const navigate = useNavigate();

  const onSignOut = () => {
    if (signOut) {
      void (async (): Promise<void> => {
        await signOut();
      })();
    }
    dispatcher({
      type: "SET_USER",
      payload: undefined,
    });
    navigate(`/`);
  };

  return (
    <>
      <Navbar variant="dark" expand={false} className="text-light menu_bg" sticky="top">
        <Container fluid>
          <Navbar.Brand href="#">
            <Stack direction="horizontal" gap={1}>
              <img alt="" src="/logo-website.png" width="30" height="30" />
              <img alt="" src="/logo-name.png" height="30" />
              {state.user ? (
                <div className="ms-2">
                  <span>
                    {state.user.first_name} {state.user.last_name}
                  </span>
                  <small>
                    {" ("}
                    {getUserRoleName(state.user.role)}
                    {")"}
                  </small>
                </div>
              ) : (
                <Spinner animation="border" />
              )}
            </Stack>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="offcanvasNavbar-expand" />
          <Navbar.Offcanvas id="offcanvasNavbar-expand" aria-labelledby="offcanvasNavbar-expand" placement="end">
            <Offcanvas.Header closeButton>
              <Offcanvas.Title id="offcanvasNavbar-expand">
                <img alt="" src="/logo-name.png" height="30" className="d-inline-block" />
                <p className="my-0 mt-1">
                  <small className="text-secondary">{state.user_attributes?.email}</small>
                </p>
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Nav className="justify-content-end flex-grow-1 pe-3">
                <Nav.Link href="/">
                  <FontAwesomeIcon className="mx-2" icon={faHouse} />
                  Dashboard
                </Nav.Link>
                <Nav.Link href="/" className="text-secondary" disabled>
                  <FontAwesomeIcon className="mx-2" icon={faAddressCard} />
                  Account
                </Nav.Link>
                <hr />
                <Nav.Link href="#action1" className="text-secondary" disabled>
                  <FontAwesomeIcon className="mx-2 fa-lg" icon={faCircleInfo} />
                  Help
                </Nav.Link>
                <ul style={{ listStyleType: "none" }}>
                  <li>
                    <Nav.Link href="#action1" className="text-secondary" disabled>
                      <FontAwesomeIcon className="mx-2" icon={faCircleQuestion} />
                      FAQ
                    </Nav.Link>
                    <Nav.Link href="#action1" className="text-secondary" disabled>
                      <FontAwesomeIcon className="mx-2" icon={faEnvelope} />
                      Contact Us
                    </Nav.Link>
                    <Nav.Link href="#action1" className="text-secondary" disabled>
                      <FontAwesomeIcon className="mx-2" icon={faBook} />
                      User Guide
                    </Nav.Link>
                    <Nav.Link href="#action1" className="text-secondary" disabled>
                      <FontAwesomeIcon className="mx-2" icon={faClipboard} />
                      Instructions
                    </Nav.Link>
                  </li>
                </ul>
                <hr />
                {state.user ? (
                  <>
                    <Nav.Link onClick={onSignOut} disabled>
                      <FontAwesomeIcon className="mx-2" icon={faShieldHalved} />
                      Two-Factor Authentication
                    </Nav.Link>
                    <Nav.Link onClick={onSignOut}>
                      <FontAwesomeIcon className="mx-2" icon={faRightFromBracket} />
                      Sign Out
                    </Nav.Link>
                  </>
                ) : (
                  <Nav.Link href="/">
                    <FontAwesomeIcon className="mx-2" icon={faRightFromBracket} />
                    Sing In
                  </Nav.Link>
                )}
              </Nav>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
    </>
  );
};

export default Menu;
