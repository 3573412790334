/* eslint-disable no-case-declarations */
import React from "react";
import {
  ApplicationDetailResponse,
  CohortApplicationResponse,
  QuerryCohortResponse,
  QueryFormResponse,
  ReviewOfwodResponse,
  ReviewWaiverResponse,
  ReviewSummaryResponse,
  ReviewFollowupActionResponse,
  AssessmentResult,
  SurgeonNotesModelResponse,
  FollowUpQAResponse,
} from "../client";
import { AIMedicalReportResponse } from "../model/ai_model";

export interface FlightSurgeonContextState {
  cohort_list?: QuerryCohortResponse[] | undefined;
  cohort?: QuerryCohortResponse | undefined;
  candidate_list?: CohortApplicationResponse[] | undefined;

  candidate?: CohortApplicationResponse | undefined;
  application_details?: ApplicationDetailResponse | undefined;
  question_from?: QueryFormResponse;
  medical_report?: AIMedicalReportResponse | undefined;
  review_summary?: ReviewSummaryResponse | undefined;
  followup_action_list?: ReviewFollowupActionResponse[] | undefined;
  followup_qa_list?: FollowUpQAResponse[] | undefined;
  waiver_list?: ReviewWaiverResponse[] | undefined;
  ofwod_list?: ReviewOfwodResponse[] | undefined;
  surgeon_notes?: SurgeonNotesModelResponse;
  assessment_result?: AssessmentResult | undefined;
}

export type FlightSurgeonActions =
  | {
      type: "SET_COHORT_LIST";
      payload: QuerryCohortResponse[] | undefined;
    }
  | {
      type: "SET_COHORT";
      payload: QuerryCohortResponse | undefined;
    }
  | {
      type: "SET_CANDIDATE_LIST";
      payload: CohortApplicationResponse[] | undefined;
    }
  | {
      type: "SET_CANDIDATE";
      payload: CohortApplicationResponse | undefined;
    }
  | {
      type: "SET_APPLICATION_DETAILS";
      payload: ApplicationDetailResponse | undefined;
    }
  | {
      type: "SET_QUESTION_FORM";
      payload: QueryFormResponse;
    }
  | {
      type: "SET_MEDICAL_REPORT";
      payload: AIMedicalReportResponse | undefined;
    }
  | {
      type: "SET_REVIEW_SUMMARY";
      payload: ReviewSummaryResponse | undefined;
    }
  | {
      type: "SET_FOLLOWUP_ACTIONS";
      payload: ReviewFollowupActionResponse[] | undefined;
    }
  | {
      type: "SET_FOLLOWUP_QA_LIST";
      payload: FollowUpQAResponse[] | undefined;
    }
  | {
      type: "ADD_FOLLOWUP_QA";
      payload: FollowUpQAResponse;
    }
  | {
      type: "DELETE_FOLLOWUP_QA";
      payload: string;
    }
  | {
      type: "SET_WAIVERS_LIST";
      payload: ReviewWaiverResponse[];
    }
  | {
      type: "ADD_WAIVER";
      payload: ReviewWaiverResponse;
    }
  | {
      type: "DELETE_WAIVER";
      payload: string;
    }
  | {
      type: "UPDATE_WAIVER";
      payload: ReviewWaiverResponse;
    }
  | {
      type: "SET_OFWOD_LIST";
      payload: ReviewOfwodResponse[];
    }
  | {
      type: "ADD_OFWOD";
      payload: ReviewOfwodResponse;
    }
  | {
      type: "DELETE_OFWOD";
      payload: string;
    }
  | {
      type: "UPDATE_OFWOD";
      payload: ReviewOfwodResponse;
    }
  | {
      type: "SET_ASSESSEMENT_RESULT";
      payload: AssessmentResult | undefined;
    }
  | {
      type: "SET_SURGEON_NOTES";
      payload: SurgeonNotesModelResponse | undefined;
    }
  | {
      type: "RESET_DETAILS";
      payload: undefined;
    };

const flightSurgeonReducer: React.Reducer<FlightSurgeonContextState, FlightSurgeonActions> = (state, action) => {
  switch (action.type) {
    case "SET_COHORT_LIST":
      return { ...state, cohort_list: action.payload };
    case "SET_COHORT":
      return { ...state, cohort: action.payload };
    case "SET_CANDIDATE_LIST":
      return { ...state, candidate_list: action.payload };
    case "SET_CANDIDATE":
      return { ...state, candidate: action.payload };
    case "SET_APPLICATION_DETAILS":
      return { ...state, application_details: action.payload };
    case "SET_QUESTION_FORM":
      return { ...state, question_from: action.payload };
    case "SET_MEDICAL_REPORT":
      return { ...state, medical_report: action.payload };
    case "SET_REVIEW_SUMMARY":
      return { ...state, review_summary: action.payload };
    case "SET_FOLLOWUP_ACTIONS":
      return { ...state, followup_action_list: action.payload };
    case "SET_FOLLOWUP_QA_LIST":
      return { ...state, followup_qa_list: action.payload };
    case "ADD_FOLLOWUP_QA":
      return {
        ...state,
        followup_qa_list: [...(state.followup_qa_list || []), action.payload],
      };
    case "DELETE_FOLLOWUP_QA":
      return {
        ...state,
        followup_qa_list: (state.followup_qa_list || []).filter((item) => item.uid !== action.payload),
      };
    case "SET_WAIVERS_LIST":
      return { ...state, waiver_list: action.payload };
    case "ADD_WAIVER":
      return {
        ...state,
        waiver_list: [...(state.waiver_list || []), action.payload],
      };
    case "DELETE_WAIVER":
      return {
        ...state,
        waiver_list: (state.waiver_list || []).filter((item) => item.uid !== action.payload),
      };
    case "UPDATE_WAIVER":
      return {
        ...state,
        waiver_list: (state.waiver_list || []).map((item) => (item.uid === action.payload.uid ? action.payload : item)),
      };
    case "SET_OFWOD_LIST":
      return { ...state, ofwod_list: action.payload };
    case "ADD_OFWOD":
      return {
        ...state,
        ofwod_list: [...(state.ofwod_list || []), action.payload],
      };
    case "DELETE_OFWOD":
      return {
        ...state,
        ofwod_list: (state.ofwod_list || []).filter((item) => item.uid !== action.payload),
      };
    case "UPDATE_OFWOD":
      return {
        ...state,
        ofwod_list: (state.ofwod_list || []).map((item) => (item.uid === action.payload.uid ? action.payload : item)),
      };
    case "SET_ASSESSEMENT_RESULT":
      return {
        ...state,
        assessment_result: action.payload,
      };
    case "SET_SURGEON_NOTES":
      return { ...state, surgeon_notes: action.payload };
    case "RESET_DETAILS":
      return {
        ...state,
        candidate: undefined,
        application_details: undefined,
        question_from: undefined,
        medical_report: undefined,
        followup_qa_list: undefined,
        waiver_list: undefined,
        ofwod_list: undefined,
        review_summary: undefined,
        followup_action_list: undefined,
      };
  }
};

export type FlightSurgeonContextType = {
  state: FlightSurgeonContextState;
  dispatcher: React.Dispatch<React.ReducerAction<React.Reducer<FlightSurgeonContextState, FlightSurgeonActions>>>;
};
const FlightSurgeonContext = React.createContext<FlightSurgeonContextType | undefined>(undefined);

export interface FlightSurgeonProviderProps {
  children: React.ReactNode;
}
const FlightSurgeonContextProvider: React.FC<FlightSurgeonProviderProps & FlightSurgeonContextType> = ({
  children,
  ...context
}) => <FlightSurgeonContext.Provider value={context}>{children}</FlightSurgeonContext.Provider>;

const useFlightSurgeonContext = (): FlightSurgeonContextType => {
  const context = React.useContext(FlightSurgeonContext) as FlightSurgeonContextType;
  if (context == null) {
    throw new Error("useFlightSurgeonContext must be used within FlightSurgeonContextProvider");
  }
  return context;
};

export { FlightSurgeonContextProvider, useFlightSurgeonContext, flightSurgeonReducer };
