import React, { useState } from "react";
import DatePicker from "react-datepicker";
import { Col, Form, Row } from "react-bootstrap";

import "react-datepicker/dist/react-datepicker.css";
import { ExplanationAnswerModel, ExplanationTypeEnum } from "../../client";
import { useMemberContext } from "../../contexts/MemberContext";

export interface ExplanationProps {
  group_id: number;
  question_id: number;
  explanation_id: number;
  updateExplantionAnswer: (id: number, data: ExplanationAnswerModel) => void;
}

const ExplanationItem: React.FC<ExplanationProps> = ({
  group_id,
  question_id,
  explanation_id,
  updateExplantionAnswer,
}) => {
  const {
    state: { medical_history, question_from },
  } = useMemberContext();

  const explanation = question_from?.group_list
    ?.find((g) => g.group_id === group_id)
    ?.question_list?.find((q) => q.question_id === question_id)
    ?.explanation_list?.find((e) => e.explanation_id === explanation_id);

  const explanation_answer = medical_history?.answer_list
    ?.find((a) => a.question_id === question_id)
    ?.explanation_answer_list?.find((e) => e.explanation_id === explanation_id);

  // Use local state to avoid rending when editting comment
  const [comment, setComment] = useState<string | undefined>(explanation_answer?.comment || undefined);

  const onChangeExplanationOptions = (
    explanation_id: number,
    category: ExplanationTypeEnum,
    option_val: string | null,
    checked: boolean
  ) => {
    const answer =
      explanation_answer ||
      ({
        explanation_id: explanation_id,
        option_list: [],
      } as ExplanationAnswerModel);

    if (category === ExplanationTypeEnum._2) {
      const new_option_list = (answer.option_list || []).filter((opt) => opt !== option_val);
      if (checked === true && option_val) {
        new_option_list.push(option_val);
      }
      updateExplantionAnswer(question_id, {
        ...answer,
        option_list: new_option_list,
      } as ExplanationAnswerModel);
    }
    if (category === ExplanationTypeEnum._1) {
      updateExplantionAnswer(question_id, {
        ...answer,
        option_list: [option_val],
      } as ExplanationAnswerModel);
    }
  };

  const isOptionSelected = (option_val: string | null | undefined): boolean => {
    if (
      option_val &&
      explanation_answer !== undefined &&
      explanation_answer.option_list &&
      explanation_answer.option_list.find((item) => item === option_val) !== undefined
    ) {
      return true;
    }
    return false;
  };

  const onChangeComment = (val: string) => {
    updateExplantionAnswer(question_id, {
      ...(explanation_answer ||
        ({
          explanation_id: explanation_id,
        } as ExplanationAnswerModel)),
      comment: val,
    } as ExplanationAnswerModel);
  };

  if (explanation === undefined) {
    return <>Unknow explanation question</>;
  }

  return (
    <Row key={explanation.explanation_id}>
      <Col className="px-1 col-lg-auto">{explanation.question}:</Col>
      <Col>
        {(explanation.category === ExplanationTypeEnum._2 || explanation.category === ExplanationTypeEnum._1) && (
          <>
            {explanation.options.option_list.map((option) => {
              return (
                <Form.Check
                  inline
                  type={explanation.category === ExplanationTypeEnum._2 ? "checkbox" : "radio"}
                  id={`chk_${explanation.explanation_id}_${option.value}`}
                  key={`opt_${explanation.explanation_id}_${option.value}`}
                  className="d-inline-flex"
                >
                  <Form.Check.Input
                    type={explanation.category === ExplanationTypeEnum._2 ? "checkbox" : "radio"}
                    className="me-2"
                    checked={isOptionSelected(option.value)}
                    onChange={(e) => {
                      onChangeExplanationOptions(
                        explanation.explanation_id,
                        explanation.category,
                        option.value,
                        e.target.checked
                      );
                    }}
                  />
                  <Form.Check.Label>{option.label}</Form.Check.Label>
                  {option.comment && (
                    <>
                      <span className="me-3">:</span>
                      <Form.Control type="text" size="sm" placeholder="" />
                    </>
                  )}
                </Form.Check>
              );
            })}
          </>
        )}

        {explanation.category === ExplanationTypeEnum._3 && (
          <Form.Control
            type="input"
            size="sm"
            placeholder=""
            value={comment || ""}
            onChange={(e) => {
              setComment(e.target.value);
            }}
            onBlur={(e) => {
              onChangeComment(e.target.value);
            }}
          />
        )}

        {explanation.category === ExplanationTypeEnum._4 && (
          <>
            <DatePicker
              selected={comment ? new Date(comment) : null}
              onChange={(date) => setComment(date?.toISOString())}
              dateFormat="MM/yyyy"
              showMonthYearPicker
              onBlur={() => {
                onChangeComment(comment || "");
              }}
            />
          </>
        )}
      </Col>
    </Row>
  );
};

export default ExplanationItem;
