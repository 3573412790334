import React, { Fragment, useState } from "react";
import DatePicker from "react-datepicker";
import { v4 as uuid } from "uuid";
import { Button, Col, Form, Row, ListGroup, Alert, Spinner, Container } from "react-bootstrap";
import ProgressSteps, { application_step_list } from "../../component/ProgressSteps";
import QuestionHeader from "./QuestionHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faTrashCan } from "@fortawesome/free-regular-svg-icons";

import CountrySelector, { getCountryName } from "../../component/CountrySelector";

import { useMemberContext } from "../../contexts/MemberContext";
import { MedicalVisitBasic, MedicalVisitItemModel } from "../../client";
import { useAuthContext } from "../../contexts/AuthContext";
import apiClient from "../../api/api_client";
import { yes_no_list } from "../../model/constant";
import { showMMYYYY } from "../../api/ui_util";
import { ApiErrorModal } from "../../component/ApiErrorModal";
import { ApiStatus } from "../../model/constant_data";
import "./CandidatePage.css";

const StepMedicalVisits: React.FC = () => {
  const {
    state: { user },
  } = useAuthContext();
  const {
    state: { step, general, medical_visit },
    dispatcher,
  } = useMemberContext();

  const [saveStatus, setSaveStatus] = useState<ApiStatus>(ApiStatus.IDEL);
  const [nextStatus, setNextStatus] = useState<ApiStatus>(ApiStatus.IDEL);
  const [updated, setUpdated] = useState<boolean>(false);
  const [apiFailedFlg, setApiFailedFlg] = useState<boolean>(false);

  const [index, setIndex] = useState<number | undefined>(undefined);
  const [current_visit, setCurrentVisit] = useState<MedicalVisitItemModel | undefined>(undefined);

  const onUpdateCurrentVisit = (property: string, val: string | undefined) => {
    setCurrentVisit({ ...current_visit, [property]: val });
  };

  const [errorFlg, setErrorFlg] = useState<boolean>(false);

  const onChangeProperty = (property: string, val: string | boolean | undefined) => {
    setUpdated(true);
    dispatcher({
      type: "SET_MEDICAL_VISIT",
      payload: { ...medical_visit, [property]: val },
    });
  };

  const onClickAdd = () => {
    if (current_visit?.date && current_visit?.type && current_visit?.reason) {
      setUpdated(true);
      setErrorFlg(false);
      let new_list = [];
      if (index === undefined) {
        new_list = [...(medical_visit?.visit_list || []), current_visit];
      } else {
        new_list = [...(medical_visit?.visit_list || []).filter((_, i) => i !== index), current_visit];
      }
      dispatcher({
        type: "SET_MEDICAL_VISIT",
        payload: {
          ...medical_visit,
          visit_list: new_list,
        } as MedicalVisitBasic,
      });
      setCurrentVisit(undefined);
      setIndex(undefined);
    } else {
      setErrorFlg(true);
    }
  };

  const onClickClear = () => {
    setCurrentVisit(undefined);
    setErrorFlg(false);
  };

  const onClickEdit = (idx: number) => {
    if (medical_visit && medical_visit.visit_list) {
      setCurrentVisit(medical_visit.visit_list[idx]);
      setIndex(idx);
    }
  };

  const onClickDelete = (idx: number) => {
    if (medical_visit && medical_visit.visit_list) {
      setUpdated(true);
      dispatcher({
        type: "SET_MEDICAL_VISIT",
        payload: {
          ...medical_visit,
          visit_list: medical_visit.visit_list.filter((_, i) => i !== idx),
        } as MedicalVisitBasic,
      });
    }
  };

  const onPrevious = (event: React.UIEvent<HTMLButtonElement>) => {
    event.preventDefault();
    dispatcher({
      type: "SET_STEP",
      payload: step - 1,
    });
  };

  const onCancel = (event: React.UIEvent<HTMLButtonElement>) => {
    event.preventDefault();
    dispatcher({
      type: "SET_STEP",
      payload: 0,
    });
  };

  const onSave = (event: React.UIEvent<HTMLButtonElement>) => {
    event.preventDefault();
    void (async (): Promise<void> => {
      try {
        setSaveStatus(ApiStatus.BUSY);
        await doSave();
        setSaveStatus(ApiStatus.IDEL);
      } catch (e) {
        setSaveStatus(ApiStatus.IDEL);
        setApiFailedFlg(true);
      }
    })();
  };

  const onNext = (event: React.UIEvent<HTMLButtonElement>) => {
    event.preventDefault();
    void (async (): Promise<void> => {
      try {
        setNextStatus(ApiStatus.BUSY);
        if (updated) {
          await doSave();
        }
        setNextStatus(ApiStatus.IDEL);
        dispatcher({
          type: "SET_STEP",
          payload: step + 1,
        });
      } catch (e) {
        setNextStatus(ApiStatus.IDEL);
        setApiFailedFlg(true);
      }
    })();
  };

  const doSave = async () => {
    if (user && general && general.application_id !== undefined && medical_visit !== undefined) {
      await apiClient.updateApplicationMedicalVisit(user.user_id, general.application_id, medical_visit);
    }
  };

  return (
    <>
      <Container className="mt-3 border shadow rounded bg-light" fluid>
        <Row className="py-2 ">
          <Col>
            <ProgressSteps step_list={application_step_list} current_step={step} />
            <div className="d-flex justify-content-between mt-3">
              <div>
                <h3>Medical Visits</h3>
              </div>
              <div>
                <Button variant="dark" className="mx-2" onClick={onPrevious}>
                  Previous
                </Button>
                <Button variant="warning" className="mx-2" onClick={onCancel}>
                  Cancel
                </Button>
                <Button variant="success" className="mx-2" onClick={onSave}>
                  {saveStatus === ApiStatus.BUSY && <Spinner animation="border" size="sm" className="me-2" />}
                  Save
                </Button>
                <Button
                  variant="dark"
                  className="mx-2"
                  disabled={general?.category === undefined || nextStatus === ApiStatus.BUSY}
                  onClick={onNext}
                >
                  {nextStatus === ApiStatus.BUSY && <Spinner animation="border" size="sm" className="me-2" />}
                  Next
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </Container>

      <Form className="candidate_overflower_container mt-3 px-0">
        <ListGroup>
          <QuestionHeader
            index={34}
            header={"Have you visited any health professionals within the last 3 years?"}
            collapsed={false}
          >
            <small>
              <ol>
                <li>
                  Select Yes or No
                  <ul>
                    <li>
                      You are required to enter ALL visits to any health professionals (such as a physician, physician
                      assistant, nurse practitioner, psychologist, psychiatrist, chiropractor, clinical social worker,
                      or substance abuse specialist, including an EAP employer-sponsored specialist) for treatment,
                      examination, or medical/mental evaluation.
                    </li>
                    <li>
                      Multiple visits to one health professional for the same condition may be aggregated on one line
                      (you may use the most recent date in the date field).
                    </li>
                    <li>
                      You do not need to enter routine dental and eye examinations or periodic FAA medical examinations
                      and visits to health professionals related to an Authorization for Special Issuance.
                    </li>
                  </ul>
                </li>
                <li>
                  If yous elected Yes
                  <ul>
                    <li>Enter the month and year in the Date of Visit box</li>
                    <li>Enter health professional`s name in the Name box</li>
                    <li>Enter the type of professional in the Type of Professional box</li>
                    <li>Enter the reason in the Reason box</li>
                    <li>Enter the health professional`s address in the address boxes</li>
                    <li>Click the Add button</li>
                  </ul>
                </li>
                <li>Repeat Step 2 to add all your visits to health professionals.</li>
              </ol>
            </small>
          </QuestionHeader>

          <ListGroup.Item style={{ paddingLeft: "4em", paddingBottom: "2em" }}>
            <Row className="mb-3">
              <Col lg="auto">
                {yes_no_list.map((item, idx) => {
                  return (
                    <Form.Check inline type="radio" id={`rdo_${item.val}`} key={idx} className="me-5">
                      <Form.Check.Input
                        type="radio"
                        checked={medical_visit?.has_visit === item.val}
                        onChange={(e) => {
                          if (e.target.checked) {
                            onChangeProperty("has_visit", item.val);
                          }
                        }}
                      />
                      <Form.Check.Label>{item.name}</Form.Check.Label>
                    </Form.Check>
                  );
                })}
              </Col>
            </Row>

            {medical_visit?.has_visit && (
              <>
                <Form noValidate validated={errorFlg}>
                  <Row className="mb-3">
                    <Col lg={2}>
                      <Row>
                        <Col>
                          <Form.Label>
                            Date of Visit <small className="text-muted">(MM/yyyy)</small>
                            <strong> *</strong>
                          </Form.Label>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <DatePicker
                            selected={current_visit?.date ? new Date(current_visit.date) : null}
                            className="form-control form-control-sm"
                            onChange={(date) => {
                              date?.setDate(date?.getDate() + 1);
                              onUpdateCurrentVisit("date", date?.toISOString());
                            }}
                            dateFormat="MM/yyyy"
                            showMonthYearPicker
                            required
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col lg={3}>
                      <Form.Label>Name</Form.Label>
                      <Form.Control
                        size="sm"
                        value={current_visit?.name || ""}
                        onChange={(e) => {
                          onUpdateCurrentVisit("name", e.target.value);
                        }}
                        required
                      />
                    </Col>
                    <Col lg={3}>
                      <Form.Label>
                        Type Professional<strong> *</strong>
                      </Form.Label>
                      <Form.Control
                        size="sm"
                        value={current_visit?.type || ""}
                        onChange={(e) => {
                          onUpdateCurrentVisit("type", e.target.value);
                        }}
                        required
                      />
                    </Col>
                    <Col lg={4}>
                      <Form.Label>
                        Reason<strong> *</strong>
                      </Form.Label>
                      <Form.Control
                        size="sm"
                        value={current_visit?.reason || ""}
                        onChange={(e) => {
                          onUpdateCurrentVisit("reason", e.target.value);
                        }}
                        required
                      />
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Form.Group as={Col} lg={6} controlId="visitAddress">
                      <Form.Label>Number/Street</Form.Label>
                      <Form.Control
                        size="sm"
                        value={current_visit?.address || ""}
                        onChange={(e) => {
                          onUpdateCurrentVisit("address", e.target.value);
                        }}
                      />
                    </Form.Group>
                  </Row>
                  <Row className="mb-3">
                    <Form.Group as={Col} lg={3} controlId="visitCity">
                      <Form.Label>City</Form.Label>
                      <Form.Control
                        size="sm"
                        value={current_visit?.city || ""}
                        onChange={(e) => {
                          onUpdateCurrentVisit("city", e.target.value);
                        }}
                      />
                    </Form.Group>
                    <Form.Group as={Col} lg={2} controlId="visitState">
                      <Form.Label>State</Form.Label>
                      <Form.Control
                        size="sm"
                        value={current_visit?.state || ""}
                        onChange={(e) => {
                          onUpdateCurrentVisit("state", e.target.value);
                        }}
                      />
                    </Form.Group>
                    <Form.Group as={Col} lg={2} controlId="visitZip">
                      <Form.Label>Zip Code</Form.Label>
                      <Form.Control
                        size="sm"
                        value={current_visit?.zip || ""}
                        onChange={(e) => {
                          onUpdateCurrentVisit("zip", e.target.value);
                        }}
                      />
                    </Form.Group>
                    <Form.Group as={Col} lg={3} controlId="visitCountry">
                      <CountrySelector
                        label="Country"
                        val={current_visit?.country || ""}
                        property="country"
                        onChanged={onUpdateCurrentVisit}
                      />
                    </Form.Group>
                  </Row>
                  {errorFlg && (
                    <Row>
                      <Col>
                        <Alert variant="danger">
                          <small>Please input required medical visit data.</small>
                        </Alert>
                      </Col>
                    </Row>
                  )}
                  <Row className="mb-3">
                    <Col lg={4}>
                      <Button variant="primary" className="me-2 px-3" onClick={onClickAdd}>
                        {index !== undefined ? "Update" : "Add"}
                      </Button>
                      <Button variant="primary" onClick={onClickClear}>
                        Cancel
                      </Button>
                    </Col>
                  </Row>

                  {medical_visit && medical_visit.visit_list && medical_visit.visit_list.length > 0 && (
                    <>
                      <Row className="mt-4 py-2 bg-info">
                        <Col lg={1}>Date</Col>
                        <Col lg={1}>Name</Col>
                        <Col lg={2}>Number/Street</Col>
                        <Col lg={1}>City</Col>
                        <Col lg={1}>State</Col>
                        <Col lg={1}>Zip Code</Col>
                        <Col lg={1}>Country</Col>
                        <Col lg={2}>Type Professional</Col>
                        <Col lg={1}>Reason</Col>
                        <Col lg={1}>&nbsp;</Col>
                      </Row>
                      {medical_visit.visit_list.map((visit, idx) => {
                        return (
                          <Fragment key={uuid()}>
                            <Row className="py-2 border-bottom">
                              <Col lg={1}>{showMMYYYY(visit.date || undefined)}</Col>
                              <Col lg={1}>{visit.name}</Col>
                              <Col lg={2}>{visit.address}</Col>
                              <Col lg={1}>{visit.city}</Col>
                              <Col lg={1}>{visit.state}</Col>
                              <Col lg={1}>{visit.zip}</Col>
                              <Col lg={1}>{getCountryName(visit.country)}</Col>
                              <Col lg={2}>{visit.type}</Col>
                              <Col lg={1}>{visit.reason}</Col>
                              <Col lg={1} className="text-end">
                                <FontAwesomeIcon
                                  icon={faPenToSquare}
                                  size="lg"
                                  className="me-3"
                                  onClick={() => {
                                    onClickEdit(idx);
                                  }}
                                />
                                <FontAwesomeIcon
                                  icon={faTrashCan}
                                  size={"lg"}
                                  color="red"
                                  onClick={() => {
                                    onClickDelete(idx);
                                  }}
                                />
                              </Col>
                            </Row>
                          </Fragment>
                        );
                      })}
                    </>
                  )}
                </Form>
              </>
            )}
          </ListGroup.Item>
        </ListGroup>
      </Form>

      <ApiErrorModal show={apiFailedFlg} setShow={setApiFailedFlg} />
    </>
  );
};

export default StepMedicalVisits;
