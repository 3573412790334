import React, { useMemo, useState } from "react";
import { v4 as uuid } from "uuid";
import { Alert, Badge, Col, Row, Stack } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck, faCircleXmark } from "@fortawesome/free-regular-svg-icons";
import { useFlightSurgeonContext } from "../../contexts/FlightSurgeonContext";
import { ASSESSMENT_CATEGORY_LIST } from "../../model/constant";
import ConfirmModal from "../../component/ConfirmModal";
import apiClient from "../../api/api_client";
import { AIStatusEnum, ApplicationProperties, ApplicationStatusEnum, FollowUpStatusEnum } from "../../client";
import { Tooltip } from "react-tooltip";
import { ApiStatus } from "../../model/constant_data";
import { faArrowsSpin, faSort, faSortDown, faSortUp, faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import _ from "lodash";
import "./CohortMembers.css";

export type SORT_KEY = "NAME" | "TOTAL_WAIVERE" | "OFWOD" | "SUITABILITY" | "COST_HOUR" | "COST_USD" | "AI_REVIEW";

const CohortMembers: React.FC = () => {
  const {
    state: { cohort, candidate_list },
    dispatcher,
  } = useFlightSurgeonContext();

  const [showConfirm, setShowConfirm] = useState<boolean>(false);
  const [targetId, setTargetId] = useState<string | undefined>(undefined);
  const [, setArchiveStatus] = useState<ApiStatus>(ApiStatus.IDEL);
  const [sortKey, setSortKey] = useState<SORT_KEY>("NAME");
  const [ascOrder, setAscOrder] = useState<boolean | undefined>(true);

  const getIcon = (key: SORT_KEY, order: boolean | undefined) => {
    if (sortKey !== key) {
      return faSort;
    }
    return order ? faSortDown : faSortUp;
  };

  const sortCandidates = (key: SORT_KEY) => {
    // desc first for one sorter
    const order = sortKey === key ? !ascOrder : false;
    setSortKey(key);
    setAscOrder(order);
  };

  const sorted_candidates = useMemo(() => {
    if (candidate_list === undefined) {
      return [];
    }

    if (sortKey === "NAME") {
      return _.orderBy(
        candidate_list || [],
        [(item) => `${item.first_name} ${item.last_name}`],
        [!ascOrder ? "desc" : "asc"]
      );
    }
    if (sortKey === "TOTAL_WAIVERE") {
      return _.orderBy(
        candidate_list || [],
        [(item) => item.assessment?.waivers?.reduce((s, b) => s + (b.value || 0), 0) || 0],
        [!ascOrder ? "desc" : "asc"]
      );
    }
    if (sortKey === "OFWOD") {
      return _.orderBy(
        candidate_list || [],
        [(item) => item.assessment?.ofwod?.reduce((s, b) => s + (b.value || 0), 0) || 0],
        [!ascOrder ? "desc" : "asc"]
      );
    }
    if (sortKey === "SUITABILITY") {
      return _.orderBy(
        candidate_list || [],
        [(item) => item.assessment?.suitability?.value ?? 0],
        [!ascOrder ? "desc" : "asc"]
      );
    }
    if (sortKey === "COST_HOUR") {
      return _.orderBy(
        candidate_list || [],
        [(item) => item.assessment?.cost?.hours ?? 0],
        [!ascOrder ? "desc" : "asc"]
      );
    }
    if (sortKey === "COST_USD") {
      return _.orderBy(candidate_list || [], [(item) => item.assessment?.cost?.usd ?? 0], [!ascOrder ? "desc" : "asc"]);
    }
    if (sortKey === "AI_REVIEW") {
      return _.orderBy(
        candidate_list || [],
        [(item) => item.overview_status?.ai_status?.status || "-"],
        [!ascOrder ? "desc" : "asc"]
      );
    }

    return _.orderBy(candidate_list || [], [(item) => `${item.first_name} ${item.last_name}`], ["desc"]);
  }, [candidate_list, sortKey, ascOrder]);

  const onClickCandidate = (application_id: string) => {
    if (cohort === undefined || candidate_list === undefined) {
      return;
    }
    dispatcher({
      type: "SET_CANDIDATE",
      payload: candidate_list.find((item) => item.application_id === application_id),
    });
  };

  return (
    <>
      {cohort === undefined && <Alert variant={"info"}>Please select a cohort.</Alert>}
      {sorted_candidates && sorted_candidates.length > 0 && (
        <div className="cohort_members_table mt-3">
          <table className="table table-hover table rounded-3 overflow-hidden mb-0">
            <thead className="table-secondary bordered rounded-top">
              <tr>
                <td colSpan={11} className="bg-primary rounded-top">
                  Cohort Candidates
                </td>
              </tr>
              <tr>
                <td rowSpan={2} className="align-bottom" style={{ width: "2%" }}>
                  #
                </td>
                <td rowSpan={2} className="align-bottom" style={{ width: "17%" }}>
                  Name
                  <FontAwesomeIcon
                    icon={getIcon("NAME", ascOrder)}
                    className="ms-2 cursor-pointer"
                    onClick={() => {
                      sortCandidates("NAME");
                    }}
                  />
                </td>
                <td style={{ width: "25%" }} className="text-center">
                  Waivers
                </td>
                <td rowSpan={2} style={{ width: "5%" }} className="align-bottom text-center">
                  OFWOD
                  <FontAwesomeIcon
                    icon={getIcon("OFWOD", ascOrder)}
                    className="ms-2 cursor-pointer"
                    onClick={() => {
                      sortCandidates("OFWOD");
                    }}
                  />
                </td>
                <td rowSpan={2} className="align-bottom text-center" style={{ width: "6%" }}>
                  Suitability
                  <FontAwesomeIcon
                    icon={getIcon("SUITABILITY", ascOrder)}
                    className="ms-2 cursor-pointer"
                    onClick={() => {
                      sortCandidates("SUITABILITY");
                    }}
                  />
                </td>
                <td style={{ width: "15%" }} className="text-center">
                  Cost
                </td>
                <td rowSpan={2} className="align-bottom text-center" style={{ width: "6%" }}>
                  Risk
                </td>
                <td rowSpan={2} className="align-bottom text-center" style={{ width: "6%" }}>
                  AI Review
                  <FontAwesomeIcon
                    icon={getIcon("AI_REVIEW", ascOrder)}
                    className="ms-2 cursor-pointer"
                    onClick={() => {
                      sortCandidates("AI_REVIEW");
                    }}
                  />
                </td>
                <td rowSpan={2} className="align-bottom text-center" style={{ width: "6%" }}>
                  First Review
                </td>
                <td rowSpan={2} className="align-bottom text-center" style={{ width: "6%" }}>
                  Second Review
                </td>
                <td rowSpan={2} className="align-bottom text-center" style={{ width: "6%" }}>
                  AMB
                </td>
              </tr>
              <tr>
                <td className="align-middle text-center">
                  <Stack direction="horizontal">
                    <Col className="m-0 p-0 align-middle">
                      Total
                      <FontAwesomeIcon
                        icon={getIcon("TOTAL_WAIVERE", ascOrder)}
                        className="ms-2 cursor-pointer"
                        onClick={() => {
                          sortCandidates("TOTAL_WAIVERE");
                        }}
                      />
                    </Col>
                    {ASSESSMENT_CATEGORY_LIST.map((waiverName, idx) => {
                      return (
                        <Col className="m-0 p-0 align-middle" key={idx}>
                          {waiverName}
                        </Col>
                      );
                    })}
                  </Stack>
                </td>
                <td className="align-middle text-center">
                  <Stack direction="horizontal">
                    {/* <Col lg={6} className="m-0 p-0 rotate-text align-middle"> */}
                    <Col lg={6} className="m-0 p-0 align-middle">
                      Hours
                      <FontAwesomeIcon
                        icon={getIcon("COST_HOUR", ascOrder)}
                        className="ms-2 cursor-pointer"
                        onClick={() => {
                          sortCandidates("COST_HOUR");
                        }}
                      />
                    </Col>
                    {/* <Col lg={6} className="m-0 p-0 rotate-text align-middle"> */}
                    <Col lg={6} className="m-0 p-0 align-middle">
                      USD
                      <small className="ms-2 text-muted">,000</small>
                      <FontAwesomeIcon
                        icon={getIcon("COST_USD", ascOrder)}
                        className="ms-2 cursor-pointer"
                        onClick={() => {
                          sortCandidates("COST_USD");
                        }}
                      />
                    </Col>
                  </Stack>
                </td>
              </tr>
            </thead>
            <tbody>
              {sorted_candidates
                .filter((item) => item.status !== ApplicationStatusEnum._90)
                .map((item, idx) => {
                  return (
                    <tr
                      key={uuid()}
                      onClick={() => {
                        if (item.application_id) {
                          onClickCandidate(item.application_id);
                        }
                      }}
                      style={{
                        cursor: item.application_id ? "pointer" : "arrow",
                      }}
                    >
                      <td>{idx + 1}</td>
                      <td>
                        <div>
                          {item.first_name} {item.last_name}
                        </div>
                        {item.properties?.submit_date ? (
                          <Badge bg="primary">{new Date(item.properties?.submit_date).toLocaleDateString()}</Badge>
                        ) : (
                          <Badge bg="secondary">No Application</Badge>
                        )}
                        {item.overview_status?.followup_status?.status === FollowUpStatusEnum.OPEN && (
                          <Badge bg="info" className="mx-1">
                            Follow-up Open
                          </Badge>
                        )}
                        {item.overview_status?.followup_status?.status === FollowUpStatusEnum.CLOSED && (
                          <Badge bg="success" className="mx-1">
                            Follow-up Done
                          </Badge>
                        )}
                      </td>
                      <td className="text-center">
                        <Row className="m-0 p-0">
                          <Col className="m-0 p-0">
                            {item.assessment?.waivers?.reduce((s, b) => s + (b.value || 0), 0) || "-"}
                          </Col>
                          {ASSESSMENT_CATEGORY_LIST.map((cat) => {
                            return (
                              <Col className="m-0 p-0" key={uuid()}>
                                {item.assessment?.waivers?.filter((data) => data.category === cat).length || "-"}
                              </Col>
                            );
                          })}
                        </Row>
                      </td>
                      <td className="text-center">
                        <Row className="m-0 p-0">
                          <Col className="m-0 p-0">
                            {item.assessment?.ofwod?.reduce((s, b) => s + (b.value || 0), 0) || "-"}
                          </Col>
                        </Row>
                      </td>
                      <td className="text-center">{item.assessment?.suitability?.value || "-"}</td>
                      <td className="text-center">
                        <Row className="m-0 p-0">
                          <Col lg={6} className="m-0 p-0">
                            {item.assessment?.cost?.hours || "-"}
                          </Col>
                          <Col lg={6} className="m-0 p-0">
                            {(item.assessment?.cost?.usd || 0) / 1000 || "-"}
                          </Col>
                        </Row>
                      </td>
                      <td className="text-center">
                        <>-</>
                      </td>
                      <td className="text-center">
                        {item.overview_status && item.overview_status.ai_status ? (
                          <>
                            {item.overview_status.ai_status?.status === AIStatusEnum.PROCESSING && (
                              <>
                                <FontAwesomeIcon
                                  icon={faArrowsSpin}
                                  className="fa-lg"
                                  cursor={"pointer"}
                                  color={"darkorange"}
                                  spin
                                  id="ai_in_process"
                                />
                                <Tooltip
                                  anchorSelect="#ai_in_process"
                                  variant="warning"
                                  style={{ borderRadius: "6px" }}
                                >
                                  AI revierw in process.
                                </Tooltip>
                              </>
                            )}
                            {item.overview_status.ai_status?.status === AIStatusEnum.DONE && (
                              <>
                                <FontAwesomeIcon
                                  icon={faCircleCheck}
                                  className="fa-lg"
                                  cursor={"pointer"}
                                  color={"green"}
                                  id="ai_in_done"
                                />
                                <Tooltip anchorSelect="#ai_in_done" variant="success" style={{ borderRadius: "6px" }}>
                                  AI revierw has been processed.
                                </Tooltip>
                              </>
                            )}
                            {item.overview_status.ai_status?.status === AIStatusEnum.ERROR && (
                              <>
                                <FontAwesomeIcon
                                  icon={faTriangleExclamation}
                                  className="fa-lg"
                                  cursor={"pointer"}
                                  color={"red"}
                                  id="ai_in_error"
                                />
                                <Tooltip anchorSelect="#ai_in_error" variant="error" style={{ borderRadius: "6px" }}>
                                  Unexpectted error occurred during AI review. Please re-run the AI again. If the
                                  problem persists, please contact the support.
                                </Tooltip>
                              </>
                            )}
                          </>
                        ) : (
                          <>-</>
                        )}
                      </td>
                      <td className="text-center">
                        {item.review?.first_review?.user_id !== undefined ? (
                          <FontAwesomeIcon
                            icon={item.review.first_review.user_id ? faCircleCheck : faCircleXmark}
                            className="fa-lg"
                            cursor={"pointer"}
                            color={item.review.first_review.user_id ? "green" : "red"}
                          />
                        ) : (
                          <>-</>
                        )}
                      </td>
                      <td className="text-center">
                        {item.review?.second_review?.user_id ? (
                          <FontAwesomeIcon
                            icon={item.review.second_review.user_id ? faCircleCheck : faCircleXmark}
                            className="fa-lg"
                            cursor={"pointer"}
                            color={item.review.second_review.user_id ? "green" : "red"}
                          />
                        ) : (
                          <>-</>
                        )}
                      </td>
                      <td className="text-center">
                        {item.review?.amb_review?.user_id ? (
                          <FontAwesomeIcon
                            icon={item.review.amb_review.user_id ? faCircleCheck : faCircleXmark}
                            className="fa-lg"
                            cursor={"pointer"}
                            color={item.review.amb_review.user_id ? "green" : "red"}
                          />
                        ) : (
                          <>-</>
                        )}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      )}
      {candidate_list && candidate_list.length === 0 && (
        <Alert variant={"warning"}>There is no candidate in this cohort.</Alert>
      )}

      <ConfirmModal
        show={showConfirm}
        setShow={setShowConfirm}
        doContinue={() => {
          const candidate = candidate_list?.find((item) => item.application_id === targetId);
          if (candidate && targetId) {
            void (async (): Promise<void> => {
              setArchiveStatus(ApiStatus.BUSY);
              await apiClient.updateApplicationStatus(
                candidate.user_id,
                targetId,
                ApplicationStatusEnum._90,
                candidate.properties || ({} as ApplicationProperties)
              );
              setTargetId(undefined);
              dispatcher({
                type: "SET_CANDIDATE_LIST",
                payload: candidate_list?.filter((item) => item.application_id !== targetId),
              });
              setArchiveStatus(ApiStatus.IDEL);
            })();
          }
        }}
      >
        <span>Are you sure to archive this application?</span>
      </ConfirmModal>
    </>
  );
};

export default CohortMembers;
