import React, { useState } from "react";
import { v4 as uuid } from "uuid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Card, Col, Container, Row, Spinner, Stack } from "react-bootstrap";
import { useManagerContext } from "../../contexts/ManagerContext";
import { faPenToSquare, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { faSquareMinus, faSquarePlus } from "@fortawesome/free-regular-svg-icons";
import ConfirmModal from "../../component/ConfirmModal";
import FlifghtSurgeonEditModal from "./FlifgtSurgeonEditModal";
import { ApiStatus } from "../../model/constant_data";
import { QueryUserResponse, UserRoleEnum } from "../../client";
import apiClient from "../../api/api_client";

const FlightSurgeonSection: React.FC = () => {
  const {
    state: { surgeon_list, flight_surgeon },
    dispatcher,
  } = useManagerContext();

  const [collapseStatus, setCollapseStatus] = useState<boolean>(false);
  const onClickCollapse = () => {
    setCollapseStatus(!collapseStatus);
  };

  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState<boolean>(false);
  const [status, setStatus] = useState<ApiStatus>(ApiStatus.IDEL);

  const onClickEditMember = (candidateId: string) => {
    const target = surgeon_list?.find((item) => item.user_id === candidateId);
    if (target) {
      dispatcher({
        type: "SET_FLIGHT_SURGEON",
        payload: target,
      });
      setShowEditModal(true);
    }
  };

  const onClickDeleteMember = (candidateId: string) => {
    const target = surgeon_list?.find((item) => item.user_id === candidateId);
    if (target) {
      dispatcher({
        type: "SET_FLIGHT_SURGEON",
        payload: target,
      });
      setShowDeleteConfirm(true);
    }
  };

  const doDeleteMember = () => {
    if (flight_surgeon) {
      setStatus(ApiStatus.BUSY);

      void (async (): Promise<void> => {
        await apiClient.deleteTokenUser(flight_surgeon.user_id);

        const new_list = await apiClient.getAllFlightSurgeons();
        dispatcher({
          type: "SET_SURGEON_LIST",
          payload: new_list,
        });

        setStatus(ApiStatus.IDEL);
      })();

      // dispatcher({
      //   type: "SET_FLIGHT_SURGEON",
      //   payload: undefined,
      // });
    }
  };

  const onClickNewMember = () => {
    dispatcher({
      type: "SET_FLIGHT_SURGEON",
      payload: { role: UserRoleEnum._2 } as QueryUserResponse,
    });
    setShowEditModal(true);
  };

  return (
    <>
      <br />
      <Card border="secondary" className="mb-3">
        <Card.Header className="bg-warning fw-bold py-0">
          <Row className="px-0">
            <Col
              lg={10}
              className="py-2 d-flex align-items-center flex-grow-1"
              style={{ cursor: "pointer" }}
              onClick={() => {
                onClickCollapse();
              }}
            >
              <FontAwesomeIcon className="me-2" icon={collapseStatus ? faSquarePlus : faSquareMinus} />
              Flight Surgeon
            </Col>
            <Col className="py-2 text-end">&nbsp;</Col>
          </Row>
        </Card.Header>
        <Card.Body className={`collapse ${!collapseStatus && "show"}`}>
          <Container className="px-3">
            <Row className="mt-0 mb-2 p-0">
              <Col className="m-0 p-0 d-flex justify-content-end">
                <Button
                  variant="primary"
                  // className="mt-3 me-3"
                  onClick={() => onClickNewMember()}
                >
                  Add New Flight Surgeon
                </Button>
              </Col>
            </Row>

            <Row className="bg-light border border-secondary rounded-top">
              <Col lg={3} className="py-2 px-3">
                Name
              </Col>
              <Col lg={2} className="py-2 px-3">
                Role
              </Col>
              <Col lg={3} className="py-2 px-3">
                Email
              </Col>
              <Col lg={3} className="py-2 px-3">
                Phone
              </Col>
              <Col lg={1} className="py-2 px-3">
                &nbsp;
              </Col>
            </Row>
            {surgeon_list ? (
              surgeon_list
                .sort((a, b) => (`${a.first_name} ${a.last_name}` > `${b.first_name} ${b.last_name}` ? 1 : -1))
                .map((member) => {
                  return (
                    <Row className="border border-secondary border-top-0" key={uuid()}>
                      <Col lg={3} className="p-3">
                        {member.first_name} {member.last_name}
                      </Col>
                      <Col lg={2} className="p-3">
                        Flight Surgeon
                      </Col>
                      <Col lg={3} className="p-3">
                        {member.email}
                      </Col>
                      <Col lg={3} className="p-3">
                        {member.phone}
                      </Col>
                      <Col lg={1} className="p-3 text-end">
                        {member.user_id === flight_surgeon?.user_id && status === ApiStatus.BUSY ? (
                          <Spinner animation="border" size="sm" />
                        ) : (
                          <>
                            <Stack
                              direction="horizontal"
                              gap={2}
                              // className="d-flex align-items-end justify-content-end"
                            >
                              <FontAwesomeIcon
                                icon={faPenToSquare}
                                size={"lg"}
                                className="px-1"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  onClickEditMember(member.user_id);
                                }}
                              />
                              <FontAwesomeIcon
                                icon={faTrashCan}
                                size={"lg"}
                                color="red"
                                className="px-1"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  onClickDeleteMember(member.user_id);
                                }}
                              />
                            </Stack>
                          </>
                        )}
                      </Col>
                    </Row>
                  );
                })
            ) : (
              <Spinner animation="border" className="m-2" />
            )}
          </Container>
        </Card.Body>
      </Card>

      <ConfirmModal show={showDeleteConfirm} setShow={setShowDeleteConfirm} doContinue={doDeleteMember}>
        <span>
          Are you sure to delete the member
          {flight_surgeon && (
            <>
              {" "}
              ({flight_surgeon.first_name} {flight_surgeon.last_name})
            </>
          )}
          ?
        </span>
      </ConfirmModal>

      <FlifghtSurgeonEditModal show={showEditModal} setShow={setShowEditModal} />
    </>
  );
};

export default FlightSurgeonSection;
