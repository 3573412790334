/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * Question Category.
 */
export enum QuestionTypeEnum {
    '_1' = 1,
    '_2' = 2,
}
