import React from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { useFlightSurgeonContext } from "../../contexts/FlightSurgeonContext";

const ViewAssessmentSummary: React.FC = () => {
  const {
    state: { assessment_result },
  } = useFlightSurgeonContext();

  return (
    <Container className="shadow border rounded mt-4 bg-light" fluid>
      <Row className="pt-2">
        <Form.Group as={Col} lg={2} className="mb-3">
          <Form.Label>Waivers</Form.Label>
          <Form.Control type="text" value={assessment_result?.waivers?.length || "-"} disabled />
        </Form.Group>
        <Form.Group as={Col} lg={2} className="mb-3">
          <Form.Label>OFWOD</Form.Label>
          <Form.Control type="text" value={assessment_result?.ofwod?.length || "-"} disabled />
        </Form.Group>
        <Form.Group as={Col} lg={2} className="mb-3">
          <Form.Label>Suitability</Form.Label>
          <Form.Control type="text" value={assessment_result?.suitability?.value || "-"} disabled />
        </Form.Group>
        <Form.Group as={Col} lg={2} className="mb-3">
          <Form.Label>
            Cost <sub>(hours)</sub>
          </Form.Label>
          <Form.Control type="text" value={assessment_result?.cost?.hours || "-"} disabled />
        </Form.Group>
        <Form.Group as={Col} lg={2} className="mb-3">
          <Form.Label>
            Cost <sub>(USD)</sub>
          </Form.Label>
          <Form.Control type="text" value={assessment_result?.cost?.usd || "-"} disabled />
        </Form.Group>
        <Form.Group as={Col} lg={2} className="mb-3">
          <Form.Label>Quality</Form.Label>
          <Form.Control type="text" value={assessment_result?.quality || "-"} disabled />
        </Form.Group>
      </Row>
    </Container>
  );
};

export default ViewAssessmentSummary;
