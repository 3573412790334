import React from "react";
import { v4 as uuid } from "uuid";
import { Col, ListGroup, Row } from "react-bootstrap";
import { ExerciseBasic } from "../client";

export interface ViewExerciseSuitabilityProps {
  exercise?: ExerciseBasic | undefined | null;
}

const ViewExerciseSuitability: React.FC<ViewExerciseSuitabilityProps> = ({ exercise }) => {
  return (
    <ListGroup>
      <ListGroup.Item variant="secondary">
        <Row>
          <Col lg={1}>
            <strong>No</strong>
          </Col>
          <Col lg={5}>
            <strong>Exercise Acitivity</strong>
          </Col>
          <Col lg={3}>
            <strong>Times per week</strong>
          </Col>
          <Col lg={3}>
            <strong>Duration in minutes</strong>
          </Col>
        </Row>
      </ListGroup.Item>
      {exercise?.exercise_list?.map((visit, idx) => {
        return (
          <ListGroup.Item className="p-0" variant="light" key={uuid()}>
            <Row className="mx-0 py-2">
              <Col lg={1} className="py-2">
                {idx + 1}
              </Col>
              <Col lg={5} className="py-2">
                {visit.activity}
              </Col>
              <Col lg={3} className="py-2">
                {visit.frequency}
              </Col>
              <Col lg={3} className="py-2">
                {visit.duration}
              </Col>
            </Row>
          </ListGroup.Item>
        );
      })}
      {(!exercise || !exercise.exercise_list || exercise.exercise_list?.length === 0) && (
        <ListGroup.Item className="p-0" variant="light">
          <Row className="mx-0 py-2">
            <Col lg={12} className="py-2">
              No exercise activity recorded
            </Col>
          </Row>
        </ListGroup.Item>
      )}
    </ListGroup>
  );
};

export default ViewExerciseSuitability;
