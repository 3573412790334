/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * Follow up QA Status.
 */
export enum FollowUpQAStatusEnum {
    DRAFT = 'draft',
    ASKED = 'asked',
    ANSWERED = 'answered',
}
