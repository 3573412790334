import { getAge, showMMYYYY } from "../api/ui_util";
import {
  DemographicsBasic,
  ExtremeQAModel,
  FollowUpQAResponse,
  MedicalHistoryAnswerModel,
  MedicalHistoryBasic,
  MedicalVisitBasic,
  MedicationBasic,
  QueryFormResponse,
  QuestionModel,
  // WaiverMedicationItem,
} from "../client";
import { getCountryName } from "../component/CountrySelector";
import { dosage_unit_list, frequency_list, getDisplayName } from "./constant_data";

export type AIQuestionModel = {
  question: string;
  answer: string | number | boolean;
  details: string | undefined;
};

export type AIDemographicsModel = {
  age: number;
  hair_color: string | undefined;
  sex: string | undefined;
};

export type AIMedicalHistoty = {
  demographics: AIDemographicsModel;
  medical_visits: MedicalVisitBasic;
  medications: MedicationBasic;
  question_list: AIQuestionModel[];
};

export type AIMedicalReportRequest = {
  application_id: string;
  use_previous_data: boolean;
  // overwrite_review_data: boolean;
  thread_id: string | undefined;
  // full_name: string;
  medical_history: AIMedicalHistoty;
  suitability_question_list: ExtremeQAModel[];
  follow_up_questions: AIQuestionModel[];

  // question_list: AIQuestionModel[];
  // demographics: DemographicsBasic;
  // medications: MedicationBasic;
  // medical_visit: MedicalVisitBasic;
  // follow_up_questions: List[]
};

export type AIWaiverMedicationItem = {
  name?: string | null;
  dosage?: string | null;
  frequency?: string | null;
  duration?: string | null;
};

export type AIWaiverModel = {
  section: string;
  title: string;
  category?: string;
  opinion?: string;
  contents?: string;
  medications?: AIWaiverMedicationItem[];
};

export type AIOFWODModel = {
  section: string;
  title: string;
  category?: string;
  opinion?: string;
  contents?: string;
};

export type AIFollowUpModel = {
  question: string;
  context: string;
  clinical_notes: string;
};

export type AISuitabilityModel = {
  value: string;
  comment: string;
};

export type AIQualityModel = {
  value: string;
  comment: string;
};

export type AICostModel = {
  time: string;
  USD: string;
  comment: string;
};

export type AIRiskModel = {
  value: string;
  comment: string;
};

export type AIMedicalReportModel = {
  summary: string[];
  findings: string[];
  waivers: AIWaiverModel[];
  OFWODs: string[] | AIOFWODModel[];
  follow_up_actions: string[];
  follow_up_questions: AIFollowUpModel[];
  suitability?: AISuitabilityModel;
  cost?: AICostModel;
  quality?: AIQualityModel;
  risk?: AIRiskModel;
};

export type AIMedicalReportResponse = {
  application_id: string;
  thread_id: string;
  medical_report: AIMedicalReportModel;
};

const flatFormQuestions = (question_from?: QueryFormResponse) => {
  const g_questions = question_from?.group_list.map((item) => item.question_list);
  const all_questions = g_questions?.flat() || [];
  return all_questions;
};

const getQuestion = (all_questions: QuestionModel[], question_id: number): string => {
  return all_questions.find((item) => item.question_id === question_id)?.question || "";
};

const getAnswer = (
  all_questions: QuestionModel[],
  question_id: number,
  answer: number[] | boolean | null | undefined
): string | boolean | number => {
  if (answer === null || answer === undefined) {
    return "";
  }
  if (typeof answer === "boolean") {
    return answer ? "Yes" : "No";
  }

  const q = all_questions.find((item) => item.question_id === question_id);
  if (!q) {
    return answer.join(", ");
  }
  const opts = answer.map((ans) => {
    return q.options?.option_list?.find((item) => item.value === ans.toString())?.label;
  });

  return opts.length > 0 ? opts.join(", ") : answer.join(", ");
};

const getComment = (all_questions: QuestionModel[], answer: MedicalHistoryAnswerModel): string => {
  const comments: string[] = [];
  if (answer?.answer_comment) {
    comments.push(answer.answer_comment);
  }
  const question = all_questions.find((item) => item.question_id === answer.question_id);
  if (question) {
    answer.explanation_answer_list?.map((exp) => {
      const ex = question.explanation_list?.find((item) => item.explanation_id === exp.explanation_id);
      let an = "";
      if (ex?.category && ex?.category === 4) {
        an = showMMYYYY(exp.comment || "");
      }
      if (ex?.category && ex?.category === 3) {
        an = exp.comment || "";
      }
      if ((ex?.category && ex?.category === 1) || ex?.category === 2) {
        an = (
          exp.option_list?.map((val) => {
            return ex.options?.option_list?.find((op) => op.value === val.toString())?.label;
          }) || []
        ).join(", ");
      }
      comments.push(`${ex?.question || ""}: ${an}`);
    });
  }
  return comments.join(", ");
};

// Convert application details into AI input data.
export const convertApplicationDetailsToAIRequest = (
  application_id: string,
  overwrite_review_data: boolean,
  question_from?: QueryFormResponse,
  demographics?: DemographicsBasic,
  medications?: MedicationBasic,
  medical_visit?: MedicalVisitBasic,
  medical_history?: MedicalHistoryBasic,
  extreme_qa_list?: ExtremeQAModel[],
  followup_qa_list?: FollowUpQAResponse[]
): AIMedicalReportRequest => {
  const all_questions = flatFormQuestions(question_from);

  const new_medications = {
    ...medications,
    medication_list: medications?.medication_list?.map((item) => {
      return {
        ...item,
        dosage_unit: getDisplayName(dosage_unit_list, item.dosage_unit || ""),
        frequency: getDisplayName(frequency_list, item.frequency || ""),
      };
    }),
  };

  const new_medical_visit = {
    ...medical_visit,
    visit_list: medical_visit?.visit_list?.map((item) => {
      return {
        ...item,
        country: getCountryName(item.country),
      };
    }),
  };

  return {
    application_id: application_id,
    use_previous_data: false,
    overwrite_review_data: overwrite_review_data,
    thread_id: undefined,
    medical_history: {
      demographics: {
        age: getAge(demographics?.birthday || undefined) || 0,
        hair_color: demographics?.hair_color || "",
        sex: demographics?.sex || "",
      } as AIDemographicsModel,
      medical_visits: new_medical_visit || {},
      medications: new_medications || {},
      question_list: medical_history?.answer_list
        ? medical_history.answer_list
            .filter((item) => item.answer !== undefined && item.answer !== null)
            .map((item) => {
              return {
                question: getQuestion(all_questions, item.question_id),
                answer: getAnswer(all_questions, item.question_id, item.answer),
                details: getComment(all_questions, item),
              } as AIQuestionModel;
            })
        : [],
    } as AIMedicalHistoty,
    suitability_question_list: extreme_qa_list || [],
    follow_up_questions:
      followup_qa_list
        ?.filter(
          (item) =>
            item.doctor_question !== undefined &&
            item.doctor_question !== null &&
            item.candidate_answer !== undefined &&
            item.candidate_answer !== null
        )
        .map((item) => {
          return {
            question: item.doctor_question?.question || "",
            answer: item.candidate_answer?.answer || "",
          } as AIQuestionModel;
        }) || [],
  } as AIMedicalReportRequest;
};
