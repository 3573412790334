/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * Medical Category.
 */
export enum MedeicalCategoryEnum {
    CARDIO = 'cardio',
    NEURO = 'neuro',
    PSYCH = 'psych',
    UNCLASSIFIED = 'unclassified',
}
