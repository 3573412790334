/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * Follow up application Status.
 */
export enum FollowUpStatusEnum {
    OPEN = 'open',
    CLOSED = 'closed',
}
