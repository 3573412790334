import React from "react";
import { v4 as uuid } from "uuid";
import { Accordion, Col, Container, Form, ListGroup, Row } from "react-bootstrap";
import { useFlightSurgeonContext } from "../../contexts/FlightSurgeonContext";
import { ReviewFollowupActionResponse } from "../../client";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faTrashCan } from "@fortawesome/free-regular-svg-icons";

export const FollowupActionSection: React.FC = () => {
  const {
    state: { candidate, followup_action_list },
    dispatcher,
  } = useFlightSurgeonContext();

  const onClickAddAction = () => {
    if (candidate?.application_id) {
      dispatcher({
        type: "SET_FOLLOWUP_ACTIONS",
        payload: [
          ...(followup_action_list || []),
          {
            uid: uuid(),
            application_id: candidate?.application_id,
            action: { contents: "" },
          } as ReviewFollowupActionResponse,
        ],
      });
    }
  };

  const onDeleteAction = (uid: string) => {
    dispatcher({
      type: "SET_FOLLOWUP_ACTIONS",
      payload: followup_action_list?.filter((item) => item.uid !== uid),
    });
  };

  const onUpdateAction = (uid: string, val: string) => {
    dispatcher({
      type: "SET_FOLLOWUP_ACTIONS",
      payload: followup_action_list?.map((item) => {
        if (item.uid === uid) {
          return {
            ...item,
            action: { contents: val },
          };
        }
        return item;
      }),
    });
  };

  return (
    <>
      <Accordion defaultActiveKey={"followup_action"} className="shadow rounded mt-4">
        <Accordion.Item eventKey={"followup_action"}>
          <Accordion.Header>
            <Container fluid>
              <Row>
                <Col lg={12} className="d-flex justify-content-between">
                  <h5 className="my-0">Follow Up Actions</h5>
                  <FontAwesomeIcon
                    className="float-end my-0 me-3"
                    size="lg"
                    color="red"
                    onClick={(e) => {
                      e.stopPropagation();
                      onClickAddAction();
                    }}
                    icon={faPenToSquare}
                    title="Add Follow Up Action"
                  />
                </Col>
              </Row>
            </Container>
          </Accordion.Header>
          <Accordion.Body className="p-0 rounded">
            <ListGroup as="ol" numbered>
              {followup_action_list?.map((item) => {
                return (
                  <ListGroup.Item
                    as="li"
                    key={item.uid}
                    className="d-flex justify-content-between align-items-start bg-light"
                  >
                    <Form.Control
                      as="textarea"
                      className="mx-3"
                      rows={2}
                      placeholder="Follow Up Action"
                      value={item.action.contents || ""}
                      onChange={(e) => {
                        onUpdateAction(item.uid, e.target.value);
                      }}
                    />
                    <FontAwesomeIcon
                      className="m-2"
                      icon={faTrashCan}
                      cursor={"pointer"}
                      size={"lg"}
                      color="red"
                      onClick={(e) => {
                        e.stopPropagation();
                        onDeleteAction(item.uid);
                      }}
                    />
                  </ListGroup.Item>
                );
              })}
            </ListGroup>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </>
  );
};
