import React, { useState } from "react";
import { Alert, Button, Card, Col, Container, Form, ListGroup, Row, Spinner } from "react-bootstrap";

import ProgressSteps, { application_step_list } from "../../component/ProgressSteps";
import QuestionHeader from "./QuestionHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-regular-svg-icons";
import { ApiStatus, dosage_unit_list, frequency_list, getDisplayName } from "../../model/constant_data";
import { useMemberContext } from "../../contexts/MemberContext";
import { useAuthContext } from "../../contexts/AuthContext";
import apiClient from "../../api/api_client";
import { ApiErrorModal } from "../../component/ApiErrorModal";
import { yes_no_list } from "../../model/constant";
import "./CandidatePage.css";

const StepMedication: React.FC = () => {
  const {
    state: { user },
  } = useAuthContext();
  const {
    state: { step, general, medication, medication_item },
    dispatcher,
  } = useMemberContext();

  const [saveStatus, setSaveStatus] = useState<ApiStatus>(ApiStatus.IDEL);
  const [nextStatus, setNextStatus] = useState<ApiStatus>(ApiStatus.IDEL);
  const [updated, setUpdated] = useState<boolean>(false);
  const [apiFailedFlg, setApiFailedFlg] = useState<boolean>(false);

  const onChangeProperty = (property: string, val: string | boolean | undefined) => {
    setUpdated(true);
    dispatcher({
      type: "SET_MEDICATION",
      payload: { ...medication, [property]: val },
    });
  };

  const onUpdateMedicationInfo = (property: string, val: string | boolean | undefined) => {
    dispatcher({
      type: "SET_MEDICATION_ITEM",
      payload: { ...medication_item, [property]: val },
    });
  };

  const [errorFlg, setErrorFlg] = useState<boolean>(false);

  const onClickAdd = () => {
    if (
      (medication_item?.name &&
        medication_item?.dosage &&
        medication_item?.dosage_unit &&
        medication_item?.frequency) ||
      medication_item?.cannot_locate === true
    ) {
      setUpdated(true);
      setErrorFlg(false);
      dispatcher({
        type: "SET_MEDICATION",
        payload: {
          ...medication,
          medication_list: [...(medication?.medication_list || []), medication_item],
        },
      });
      dispatcher({
        type: "SET_MEDICATION_ITEM",
        payload: undefined,
      });
    } else {
      setErrorFlg(true);
    }
  };

  const onClickClear = () => {
    dispatcher({
      type: "SET_MEDICATION_ITEM",
      payload: undefined,
    });
    setErrorFlg(false);
  };

  const onClickDelete = (idx: number) => {
    if (medication?.medication_list) {
      setUpdated(true);
      const new_data = medication?.medication_list;
      new_data.splice(idx, 1);
      dispatcher({
        type: "SET_MEDICATION",
        payload: {
          ...medication,
          medication_list: new_data,
        },
      });
    }
  };

  const onPrevious = (event: React.UIEvent<HTMLButtonElement>) => {
    event.preventDefault();
    dispatcher({
      type: "SET_STEP",
      payload: step - 1,
    });
  };

  const onCancel = (event: React.UIEvent<HTMLButtonElement>) => {
    event.preventDefault();
    dispatcher({
      type: "SET_STEP",
      payload: 0,
    });
  };

  const onSave = (event: React.UIEvent<HTMLButtonElement>) => {
    event.preventDefault();
    void (async (): Promise<void> => {
      try {
        setSaveStatus(ApiStatus.BUSY);
        await doSave();
        setSaveStatus(ApiStatus.IDEL);
      } catch (e) {
        setSaveStatus(ApiStatus.IDEL);
        setApiFailedFlg(true);
      }
    })();
  };

  const onNext = (event: React.UIEvent<HTMLButtonElement>) => {
    event.preventDefault();
    void (async (): Promise<void> => {
      try {
        setNextStatus(ApiStatus.BUSY);
        if (updated) {
          await doSave();
        }
        setNextStatus(ApiStatus.IDEL);
        dispatcher({
          type: "SET_STEP",
          payload: step + 1,
        });
      } catch (e) {
        setNextStatus(ApiStatus.IDEL);
        setApiFailedFlg(true);
      }
    })();
  };

  const doSave = async () => {
    if (user && general && general.application_id !== undefined && medication !== undefined) {
      await apiClient.updateApplicationMedication(user.user_id, general.application_id, medication);
    }
  };

  return (
    <>
      <Container className="mt-3 border shadow rounded bg-light" fluid>
        <Row className="py-2">
          <Col>
            <ProgressSteps step_list={application_step_list} current_step={step} />
            <div className="d-flex justify-content-between mt-3">
              <div>
                <h3>Medication</h3>
              </div>
              <div>
                <Button variant="dark" className="mx-2" onClick={onPrevious}>
                  Previous
                </Button>
                <Button variant="warning" className="mx-2" onClick={onCancel}>
                  Cancel
                </Button>
                <Button variant="success" className="mx-2" onClick={onSave}>
                  {saveStatus === ApiStatus.BUSY && <Spinner animation="border" size="sm" className="me-2" />}
                  Save
                </Button>
                <Button variant="dark" className="mx-2" onClick={onNext}>
                  {nextStatus === ApiStatus.BUSY && <Spinner animation="border" size="sm" className="me-2" />}
                  Next
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </Container>

      {/* <Row>
        <Col className="mb-3"> */}
      <Form className="candidate_overflower_container mt-3 px-0">
        <ListGroup>
          <QuestionHeader
            index={"10"}
            header="Do You Currently Use Any Medication (Prescription or Nonprescription)?"
            collapsed={false}
          >
            <small>
              <ol>
                <li>
                  Select Yes or No.
                  <ul>
                    <li>You are required to enter ALL prescription and nonprescription medication you take. </li>
                    <li>You must enter the medication name; all other fields are optional.</li>
                  </ul>
                </li>
                <li>
                  If you selected Yes
                  <ul>
                    <li>Enter the name of the first medication in the Medication Name box.</li>
                    <li>Enter the dosage amount in the Dosage box.</li>
                    <li>Select a dosage unit for your medication from the Dosage Unit box.</li>
                    <li>Select how often you use the medication from the Frequency box.</li>
                    <li>
                      Select Previously Reported if you have previously reported the medication on an FAA medical
                      application.
                    </li>
                    <li>Click the Add button</li>
                  </ul>
                </li>
                <li>
                  If an exact match for the medication does not appear, you will see an error message followed by a
                  drop-down box of possible matches.
                  <ul>
                    <li>If you see the correct match, select it and click the Add button again.</li>
                    <li>
                      If you do not see the correct match, select Could not Locate Medication and click the Add button
                      again.
                    </li>
                  </ul>
                </li>
                <li>Repeat Steps 2 and 3 for each medication.</li>
              </ol>
            </small>
          </QuestionHeader>
          <ListGroup.Item style={{ paddingLeft: "4em" }}>
            <Row>
              <Col lg="auto">
                {yes_no_list.map((item, idx) => {
                  return (
                    <Form.Check inline type="radio" id={`rdo_${item.val}`} key={idx} className="me-5">
                      <Form.Check.Input
                        type="radio"
                        checked={medication?.has_medication === item.val}
                        onChange={(e) => {
                          if (e.target.checked) {
                            onChangeProperty("has_medication", item.val);
                          }
                        }}
                      />
                      <Form.Check.Label>{item.name}</Form.Check.Label>
                    </Form.Check>
                  );
                })}
              </Col>
            </Row>
            {medication?.has_medication && (
              <Form noValidate validated={errorFlg}>
                <Row>
                  <Col>
                    <Card className="mt-3">
                      <Card.Header className="bg-light">
                        If yes, enter medication information for each medication and click the Add button
                      </Card.Header>
                      <Card.Body>
                        <Row className="mb-2">
                          <Col lg="auto">
                            <Form.Label>
                              Medication Name<strong> *</strong>
                            </Form.Label>
                            <Form.Control
                              size="sm"
                              value={medication_item?.name || ""}
                              onChange={(e) => {
                                onUpdateMedicationInfo("name", e.target.value);
                              }}
                              required
                            />
                          </Col>
                          <Col lg="auto">
                            <Form.Label>
                              Dosage<strong> *</strong>
                            </Form.Label>
                            <Form.Control
                              size="sm"
                              value={medication_item?.dosage || ""}
                              onChange={(e) => {
                                onUpdateMedicationInfo("dosage", e.target.value);
                              }}
                              required
                            />
                          </Col>
                          <Col lg="auto">
                            <Form.Label>
                              Dosage Unit<strong> *</strong>
                            </Form.Label>
                            <Form.Control
                              as="select"
                              type="select"
                              required
                              size="sm"
                              value={medication_item?.dosage_unit || ""}
                              onChange={(e) => {
                                onUpdateMedicationInfo("dosage_unit", e.target.value);
                              }}
                            >
                              <option value={""}>&nbsp;</option>
                              {dosage_unit_list.map((unit, idx) => {
                                return (
                                  <option value={unit.id} key={idx}>
                                    {unit.name}
                                  </option>
                                );
                              })}
                            </Form.Control>
                          </Col>
                          <Col lg="auto">
                            <Form.Label>
                              Frequency<strong> *</strong>
                            </Form.Label>
                            <Form.Control
                              required
                              as="select"
                              type="select"
                              size="sm"
                              value={medication_item?.frequency || ""}
                              onChange={(e) => {
                                onUpdateMedicationInfo("frequency", e.target.value);
                              }}
                            >
                              <option value={""}>&nbsp;</option>
                              {frequency_list.map((item) => {
                                return (
                                  <option value={item.id} key={item.id}>
                                    {item.name}
                                  </option>
                                );
                              })}
                            </Form.Control>
                          </Col>

                          <Col lg="auto">
                            <Form.Check type="checkbox" id="chk_is_reported" style={{ paddingTop: "2em" }}>
                              <Form.Check.Input
                                type="checkbox"
                                checked={medication_item?.reported === true}
                                onChange={(e) => {
                                  onUpdateMedicationInfo("reported", e.target.checked);
                                }}
                              />
                              <Form.Check.Label>Previously Reported</Form.Check.Label>
                            </Form.Check>
                          </Col>
                        </Row>
                        {errorFlg && (
                          <Row>
                            <Col className="mt-3 mb-2 d-flex justify-content-center">
                              <Form.Check type="checkbox" id="chk_not_locate">
                                <Form.Check.Input
                                  type="checkbox"
                                  checked={medication_item?.cannot_locate === true}
                                  onChange={(e) => {
                                    onUpdateMedicationInfo("cannot_locate", e.target.checked);
                                  }}
                                />
                                <Form.Check.Label>Could not Locate Medication</Form.Check.Label>
                              </Form.Check>
                            </Col>
                          </Row>
                        )}
                        <Row>
                          <Col className="d-flex justify-content-center">
                            <Button variant="primary" className="mx-2 float-start" onClick={onClickAdd}>
                              Add
                            </Button>
                            <Button variant="primary" className="mx-2 float-end" onClick={onClickClear}>
                              Clear
                            </Button>
                          </Col>
                        </Row>
                        {errorFlg && (
                          <Alert variant="danger">
                            VALIDATION ISSUES AND ERROR MESSAGES
                            <br />
                            <small>
                              Please select the correct medication name form the drop down list below.
                              <br />
                              If you cannot locate the correct medication name, please check the &quot;Could not Locate
                              Medication&quot; checkbox and click the Add button again. Click the Clear button to
                              refresh the screen.
                            </small>
                          </Alert>
                        )}
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </Form>
            )}

            {medication?.has_medication && medication?.medication_list && medication?.medication_list.length > 0 && (
              <Row>
                <Col className="my-3">
                  <ListGroup>
                    <ListGroup.Item className="bg-light">
                      <Row className="py-1">
                        <Col lg={3}>
                          <b>Medication Name</b>
                        </Col>
                        <Col lg={2}>
                          <b>Dosage</b>
                        </Col>
                        <Col lg={2}>
                          <b>Dosage Unit</b>
                        </Col>
                        <Col lg={2}>
                          <b>Frequency</b>
                        </Col>
                        <Col lg={2}>
                          <b>Previously Reported</b>
                        </Col>
                        <Col lg={1}>&nbsp;</Col>
                      </Row>
                    </ListGroup.Item>
                    {medication?.medication_list.map((item, idx) => {
                      return (
                        <ListGroup.Item action key={idx}>
                          <Row className="py-2">
                            <Col lg={3}>{item.name}</Col>
                            <Col lg={2}>{item.dosage}</Col>
                            <Col lg={2}>{item.dosage_unit && getDisplayName(dosage_unit_list, item.dosage_unit)}</Col>
                            <Col lg={2}>{item.frequency && getDisplayName(frequency_list, item.frequency)}</Col>
                            <Col lg={2}>{item.reported ? "Y" : "N"}</Col>
                            <Col lg={1} className="text-center">
                              <FontAwesomeIcon
                                icon={faTrashCan}
                                size={"lg"}
                                color="red"
                                onClick={() => {
                                  onClickDelete(idx);
                                }}
                              />
                            </Col>
                          </Row>
                        </ListGroup.Item>
                      );
                    })}
                  </ListGroup>
                </Col>
              </Row>
            )}
          </ListGroup.Item>
          <QuestionHeader
            index={"11"}
            header="Do You Ever Use Near Vision Contact Lenses While Driving?"
            collapsed={false}
          >
            <small>
              Do you use a contact lens in either eye for near vision? (for example: for reading or close up work)
            </small>
          </QuestionHeader>
          <ListGroup.Item style={{ paddingLeft: "4em" }}>
            <Row>
              <Col lg="auto">
                {yes_no_list.map((item, idx) => {
                  return (
                    <Form.Check inline type="radio" id={`rdo_lens_${item.val}`} key={idx} className="me-5">
                      <Form.Check.Input
                        type="radio"
                        checked={medication?.has_contact_lens === item.val}
                        onChange={(e) => {
                          if (e.target.checked) {
                            onChangeProperty("has_contact_lens", item.val);
                          }
                        }}
                      />
                      <Form.Check.Label>{item.name}</Form.Check.Label>
                    </Form.Check>
                  );
                })}
              </Col>
            </Row>
          </ListGroup.Item>
        </ListGroup>
      </Form>
      {/* </Col>
      </Row> */}

      <ApiErrorModal show={apiFailedFlg} setShow={setApiFailedFlg} />
    </>
  );
};

export default StepMedication;
