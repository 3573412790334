import React from "react";
import { Tab, Tabs } from "react-bootstrap";
import ViewDemographics from "../../component/ViewDemographics";
import { useFlightSurgeonContext } from "../../contexts/FlightSurgeonContext";
import ViewMedication from "../../component/ViewMedication";
import ViewMedicalHistory from "../../component/ViewMedicalHistory";
import ViewMedicationVisit from "../../component/ViewMedicationVisit";
import ViewExerciseSuitability from "../../component/ViewExerciseSuitability";
import ViewExtremeEnvQA from "../../component/ViewExtremeEnvQA";
import FollowupQaSection from "./FollowupQaSection";

export const ApplicationTabs: React.FC = () => {
  const {
    state: { question_from, application_details },
  } = useFlightSurgeonContext();

  return (
    <>
      {question_from && application_details && (
        <Tabs defaultActiveKey="demographics" variant="pills" className="mt-3 rounded bg-light">
          <Tab eventKey="demographics" title="Demographics" className="shadow">
            <ViewDemographics category={question_from?.category} demographics={application_details?.demographics} />
          </Tab>
          <Tab eventKey="medication" title="Medication" className="shadow">
            <ViewMedication medication={application_details?.medication} />
          </Tab>
          <Tab eventKey="history" title="Medical History" className="shadow">
            <ViewMedicalHistory question_from={question_from} medical_history={application_details?.medical_history} />
          </Tab>
          <Tab eventKey="visit" title="Medical Visits" className="shadow">
            <ViewMedicationVisit medical_visit={application_details?.medical_visit} />
          </Tab>
          <Tab eventKey="exercise" title="Exercise & Suitability" className="shadow">
            <ViewExerciseSuitability exercise={application_details?.exercise} />
          </Tab>
          <Tab eventKey="extreme" title="Extreme Environment" className="shadow">
            <ViewExtremeEnvQA extreme_qa_list={application_details?.extreme_qa_list} />
          </Tab>
          <Tab eventKey="followup_qa" title="Follow-Up Questions" className="shadow rounded">
            <FollowupQaSection />
          </Tab>
        </Tabs>
      )}
    </>
  );
};
