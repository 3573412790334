import React, { useState } from "react";
import { Button, Col, Container, Form, InputGroup, ListGroup, Row, Spinner } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { ApiStatus, us_status_list } from "../../model/constant_data";
import ProgressSteps, { application_step_list } from "../../component/ProgressSteps";
import QuestionHeader from "./QuestionHeader";
import CountrySelector from "../../component/CountrySelector";
import { useAuthContext } from "../../contexts/AuthContext";
import { useMemberContext } from "../../contexts/MemberContext";
import apiClient from "../../api/api_client";
import { DemographicsBasic } from "../../client";
import { ApiErrorModal } from "../../component/ApiErrorModal";
import "./CandidatePage.css";

const StepDemographics: React.FC = () => {
  const {
    state: { user },
  } = useAuthContext();
  const {
    state: { step, general, demographics },
    dispatcher,
  } = useMemberContext();

  const [saveStatus, setSaveStatus] = useState<ApiStatus>(ApiStatus.IDEL);
  const [nextStatus, setNextStatus] = useState<ApiStatus>(ApiStatus.IDEL);
  const [updated, setUpdated] = useState<boolean>(false);
  const [apiFailedFlg, setApiFailedFlg] = useState<boolean>(false);

  const [validated, setValidated] = useState<boolean | undefined>(undefined);
  const validateData = (): boolean => {
    return demographics?.birthday !== undefined && demographics?.sex !== undefined;
  };

  const onChangeProperty = (property: string, val: string | number | boolean | undefined) => {
    setUpdated(true);
    dispatcher({
      type: "SET_DEMOGRAPHICS",
      payload: { ...demographics, [property]: val },
    });
  };

  const onPrevious = (event: React.UIEvent<HTMLButtonElement>) => {
    event.preventDefault();
    dispatcher({
      type: "SET_STEP",
      payload: step - 1,
    });
  };

  const onCancel = (event: React.UIEvent<HTMLButtonElement>) => {
    event.preventDefault();
    dispatcher({
      type: "SET_STEP",
      payload: 0,
    });
  };

  const onSave = (event: React.UIEvent<HTMLButtonElement>) => {
    event.preventDefault();
    void (async (): Promise<void> => {
      try {
        setSaveStatus(ApiStatus.BUSY);
        await doSave();
        setSaveStatus(ApiStatus.IDEL);
      } catch (e) {
        setSaveStatus(ApiStatus.IDEL);
        setApiFailedFlg(true);
      }
    })();
  };

  const onNext = (event: React.UIEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (validateData() === true) {
      void (async (): Promise<void> => {
        try {
          setNextStatus(ApiStatus.BUSY);
          if (updated) {
            await doSave();
          }
          setNextStatus(ApiStatus.IDEL);
          dispatcher({
            type: "SET_STEP",
            payload: step + 1,
          });
        } catch (e) {
          setNextStatus(ApiStatus.IDEL);
          setApiFailedFlg(true);
        }
      })();
    }

    setValidated(false);
  };

  const doSave = async () => {
    if (user && general && general.application_id !== undefined && demographics !== undefined) {
      await apiClient.updateApplicationDemographics(user.user_id, general.application_id, {
        ...demographics,
        first_name: user.first_name,
        last_name: user.last_name,
        height_unit: demographics.height_unit || "cm",
        weight_unit: demographics.weight_unit || "kg",
      } as DemographicsBasic);
    }
  };

  return (
    <>
      <Container className="mt-3 border shadow rounded bg-light" fluid>
        <Row className="py-2">
          <Col>
            <ProgressSteps step_list={application_step_list} current_step={step} />
            <div className="d-flex justify-content-between mt-3">
              <div>
                <h3>Demographics</h3>
              </div>
              <div>
                <Button variant="dark" className="mx-2" onClick={onPrevious}>
                  Previous
                </Button>
                <Button variant="warning" className="mx-2" onClick={onCancel}>
                  Cancel
                </Button>
                <Button variant="success" className="mx-2" onClick={onSave}>
                  {saveStatus === ApiStatus.BUSY && <Spinner animation="border" size="sm" className="me-2" />}
                  Save
                </Button>
                <Button variant="dark" className="mx-2" onClick={onNext}>
                  {nextStatus === ApiStatus.BUSY && <Spinner animation="border" size="sm" className="me-2" />}
                  Next
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </Container>

      <Form noValidate validated={validated} className="candidate_overflower_container mt-3 px-0">
        <ListGroup>
          <QuestionHeader index={2} header="Full Name" collapsed={false}>
            <small>
              Enter your legal name. If your name changed for any reason since the date of your most recent medical
              examination, list your current legal name in the General Pertaining to Medical History comment box (in the
              Medical History section). See 14 CFR §61.25 for the for change of name.
            </small>
          </QuestionHeader>
          <ListGroup.Item style={{ paddingLeft: "4em" }} className="bg-light">
            <Row className="mb-2">
              <Col lg="auto">
                <Form.Label>First Name</Form.Label>
                <Form.Control size="sm" disabled value={user?.first_name || ""} />
              </Col>
              <Col lg="auto">
                <Form.Label>Last Name</Form.Label>
                <Form.Control size="sm" disabled value={user?.last_name || ""} />
              </Col>
              <Col lg="auto">
                <Form.Label>Middle Name</Form.Label>
                <Form.Control
                  size="sm"
                  value={demographics?.middle_name || undefined}
                  onChange={(e) => {
                    onChangeProperty("middle_name", e.target.value);
                  }}
                />
              </Col>
              <Col lg="auto">
                <Form.Label>Suffix</Form.Label>
                <Form.Select
                  size="sm"
                  value={demographics?.suffix || undefined}
                  onChange={(e) => {
                    onChangeProperty("suffix", e.target.value);
                  }}
                  placeholder="Select ..."
                >
                  <option value={undefined}>&nbsp;</option>
                  <option value="JR">JR</option>
                  <option value="SR">SR</option>
                  <option value="I">I</option>
                  <option value="VII">VII</option>
                  <option value="II">II</option>
                  <option value="III">III</option>
                  <option value="IV">IV</option>
                  <option value="V">V</option>
                  <option value="VI">VI</option>
                </Form.Select>
              </Col>
            </Row>
          </ListGroup.Item>
          <QuestionHeader index={3} header="Address" collapsed={false}>
            <small>
              Enter your mailing address. Enter your telephone number. See 14 CFR §61.60 for the requirements for change
              of address. Do not use punctuation.
            </small>
          </QuestionHeader>
          <ListGroup.Item style={{ paddingLeft: "4em" }} className="bg-light">
            <Row className="mb-2">
              <Col lg={6}>
                <Form.Label>Number/Street</Form.Label>
                <Form.Control
                  size="sm"
                  value={demographics?.address || undefined}
                  onChange={(e) => {
                    onChangeProperty("address", e.target.value);
                  }}
                />
              </Col>
            </Row>
            <Row className="mb-2">
              <Col lg="auto">
                <Form.Label>City</Form.Label>
                <Form.Control
                  size="sm"
                  value={demographics?.city || undefined}
                  onChange={(e) => {
                    onChangeProperty("city", e.target.value);
                  }}
                />
              </Col>
              <Col lg="auto">
                <Form.Label>State</Form.Label>
                <Form.Select
                  size="sm"
                  value={demographics?.state || undefined}
                  onChange={(e) => {
                    onChangeProperty("state", e.target.value);
                  }}
                >
                  <option value={undefined}>&nbsp;</option>
                  {us_status_list.map((st) => {
                    return (
                      <option value={st} key={st}>
                        {st}
                      </option>
                    );
                  })}
                </Form.Select>
              </Col>
              <Col lg="auto">
                <Form.Label>Zip Code</Form.Label>
                <Form.Control
                  size="sm"
                  value={demographics?.zip_code || undefined}
                  onChange={(e) => {
                    onChangeProperty("zip_code", e.target.value);
                  }}
                />
              </Col>
              <Col lg="auto">
                <CountrySelector
                  label="Country"
                  val={demographics?.country || undefined}
                  property="country"
                  onChanged={onChangeProperty}
                />
              </Col>
            </Row>
            <Row className="mb-2">
              <Col lg="auto">
                <Form.Label>Telephone</Form.Label>
                <Form.Control
                  size="sm"
                  value={demographics?.telephone || undefined}
                  onChange={(e) => {
                    onChangeProperty("telephone", e.target.value);
                  }}
                />
              </Col>
            </Row>
          </ListGroup.Item>
          <QuestionHeader index={4} header="Date of Birth *" collapsed={false}>
            <small>
              Select the month, day, and year of your date of birth. Select citizenship (e.g. United States).
            </small>
          </QuestionHeader>
          <ListGroup.Item style={{ paddingLeft: "4em" }} className="bg-light">
            <Row className="mb-2">
              <Col lg="auto">
                <Form.Label>
                  Birthday <small className="text-muted">(MM/dd/yyyy)</small>
                </Form.Label>
                <DatePicker
                  className={`form-control form-control-sm ${
                    validated === false && demographics?.birthday === undefined && "is-invalid"
                  }`}
                  selected={demographics?.birthday ? new Date(demographics.birthday) : null}
                  onChange={(date) => onChangeProperty("birthday", date?.toISOString())}
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  dateFormat="MM/dd/yyyy"
                  required
                />
                <Form.Control.Feedback
                  type="invalid"
                  style={{
                    display: validated === false && demographics?.birthday === undefined ? "block" : "none",
                  }}
                >
                  Birthday is required.
                </Form.Control.Feedback>
              </Col>
              <Col lg="auto">
                <CountrySelector
                  label="Citizenship"
                  val={demographics?.citizenship || undefined}
                  property="citizenship"
                  onChanged={onChangeProperty}
                />
              </Col>
            </Row>
          </ListGroup.Item>
          <QuestionHeader index={5} header="Color of Hair" collapsed={false}>
            <small>
              Specify hair color as bald, black, blond, brown, gray, or red by selecting the appropriate value from the
              drop down box.
            </small>
          </QuestionHeader>
          <ListGroup.Item style={{ paddingLeft: "4em" }} className="bg-light">
            <Row className="mb-2">
              <Col lg="auto">
                <Form.Select
                  size="sm"
                  value={demographics?.hair_color || undefined}
                  onChange={(e) => {
                    onChangeProperty("hair_color", e.target.value);
                  }}
                >
                  <option value={undefined}>Select Hair Color</option>
                  <option value="RED">RED</option>
                  <option value="BALD">BALD</option>
                  <option value="BLACK">BLACK</option>
                  <option value="BLOND">BLOND</option>
                  <option value="BROWN">BROWN</option>
                  <option value="GRAY">GRAY</option>
                  <option value="WHITE">WHITE</option>
                </Form.Select>
              </Col>
            </Row>
          </ListGroup.Item>
          <QuestionHeader index={6} header="Color of Eyes" collapsed={false}>
            <small>
              Specify actual eye color as black, blue, brown, green, gray, or hazel by selecting the appropriate value
              from the drop down box.
            </small>
          </QuestionHeader>
          <ListGroup.Item style={{ paddingLeft: "4em" }} className="bg-light">
            <Row className="mb-2">
              <Col lg="auto">
                <Form.Select
                  size="sm"
                  value={demographics?.eyes_color || undefined}
                  onChange={(e) => {
                    onChangeProperty("eyes_color", e.target.value);
                  }}
                >
                  <option value={undefined}>Select Eye Color</option>
                  <option value="BLACK">BLACK</option>
                  <option value="BLUE">BLUE</option>
                  <option value="BROWN">BROWN</option>
                  <option value="HAZEL">HAZEL</option>
                  <option value="GREEN">GREEN</option>
                  <option value="GRAY">GRAY</option>
                </Form.Select>
              </Col>
            </Row>
          </ListGroup.Item>
          <QuestionHeader index={7} header="Sex *" collapsed={false}>
            <small>Indicate male or female by selecting the appropriate radio button.</small>
          </QuestionHeader>
          <ListGroup.Item style={{ paddingLeft: "4em" }} className="bg-light">
            <Row className="mb-2">
              <Col lg="auto">
                <Form.Check type="radio" inline id="sex_m">
                  <Form.Check.Input
                    type="radio"
                    checked={demographics?.sex === "M"}
                    onChange={(e) => {
                      if (e.target.checked) {
                        onChangeProperty("sex", "M");
                      }
                    }}
                    isInvalid={validated === false && demographics?.sex === undefined}
                  />
                  <Form.Check.Label>Male</Form.Check.Label>
                </Form.Check>
                <Form.Check type="radio" inline id="sex_f">
                  <Form.Check.Input
                    type="radio"
                    checked={demographics?.sex === "F"}
                    onChange={(e) => {
                      if (e.target.checked) {
                        onChangeProperty("sex", "F");
                      }
                    }}
                    isInvalid={validated === false && demographics?.sex === undefined}
                  />
                  <Form.Check.Label>Female</Form.Check.Label>
                </Form.Check>
                <Form.Check type="radio" inline id="sex_na">
                  <Form.Check.Input
                    type="radio"
                    checked={demographics?.sex === "-"}
                    onChange={(e) => {
                      if (e.target.checked) {
                        onChangeProperty("sex", "-");
                      }
                    }}
                    isInvalid={validated === false && demographics?.sex === undefined}
                  />
                  <Form.Check.Label>Prefer not to say</Form.Check.Label>
                </Form.Check>
                <Form.Control.Feedback
                  type="invalid"
                  style={{
                    display: validated === false && demographics?.sex === undefined ? "block" : "none",
                  }}
                >
                  Sex is required.
                </Form.Control.Feedback>
              </Col>
            </Row>
          </ListGroup.Item>

          <QuestionHeader index={8} header="Height" collapsed={false}>
            <small>Candiate&apos;s height.</small>
          </QuestionHeader>
          <ListGroup.Item style={{ paddingLeft: "4em" }} className="bg-light">
            <Row className="mb-2">
              <Col lg="3">
                <InputGroup className="mb-3">
                  <Form.Control
                    placeholder="Height"
                    aria-label="Candiate's height"
                    aria-describedby="basic-height"
                    size="sm"
                    value={demographics?.height || undefined}
                    onChange={(e) => {
                      onChangeProperty("height", e.target.value);
                    }}
                    as="input"
                    type="number"
                    step={0.1}
                    style={{ maxWidth: "8rem" }}
                  />
                  <Form.Select
                    size="sm"
                    value={demographics?.height_unit || "cm"}
                    onChange={(e) => {
                      onChangeProperty("height_unit", e.target.value);
                    }}
                    style={{ maxWidth: "6rem", backgroundColor: "#f8f9fa" }}
                  >
                    <option value="cm">cm</option>
                    <option value="inches">inches</option>
                  </Form.Select>
                </InputGroup>
              </Col>
            </Row>
          </ListGroup.Item>
          <QuestionHeader index={9} header="Weight" collapsed={false}>
            <small>Candiate&apos;s weight.</small>
          </QuestionHeader>
          <ListGroup.Item style={{ paddingLeft: "4em" }} className="bg-light">
            <Row className="mb-2">
              <Col lg="3">
                <InputGroup className="mb-3">
                  <Form.Control
                    placeholder="Weight"
                    aria-label="Candiate's weight"
                    aria-describedby="basic-weight"
                    size="sm"
                    value={demographics?.weight || undefined}
                    onChange={(e) => {
                      onChangeProperty("weight", e.target.value);
                    }}
                    as="input"
                    type="number"
                    step={0.1}
                    style={{ maxWidth: "8rem" }}
                  />
                  <Form.Select
                    size="sm"
                    value={demographics?.weight_unit || "kg"}
                    onChange={(e) => {
                      onChangeProperty("weight_unit", e.target.value);
                    }}
                    style={{ maxWidth: "6rem", backgroundColor: "#f8f9fa" }}
                  >
                    <option value="kg">kg</option>
                    <option value="lbs">lbs</option>
                  </Form.Select>
                </InputGroup>
              </Col>
            </Row>
          </ListGroup.Item>
        </ListGroup>
      </Form>

      <ApiErrorModal show={apiFailedFlg} setShow={setApiFailedFlg} />
    </>
  );
};

export default StepDemographics;
