import React, { useState } from "react";
import { Button, Col, Container, FloatingLabel, Form, Modal, Row, Spinner } from "react-bootstrap";
import { useManagerContext } from "../../contexts/ManagerContext";
import apiClient from "../../api/api_client";
import { CreateCohortUserRequest, UpdateCohortUserRequest, UserRoleEnum } from "../../client";
import { ApiStatus } from "../../model/constant_data";

export interface CohortMemberEditModalProps {
  show: boolean;
  setShow: (val: boolean) => void;
}

const CohortMemberEditModal: React.FC<CohortMemberEditModalProps> = ({ show, setShow }) => {
  const {
    state: { candidate },
    dispatcher,
  } = useManagerContext();

  const [status, setStatus] = useState<ApiStatus>(ApiStatus.IDEL);
  const [dataValidated, setDataValidated] = useState<boolean | undefined>(undefined);

  const onChangeCandidate = (key: string, value: string | number | undefined) => {
    if (candidate) {
      dispatcher({
        type: "SET_CANDIDATE",
        payload: { ...candidate, [key]: value },
      });
    }
  };

  const onChangeCandidateProperty = (key: string, value: string | number | undefined) => {
    if (candidate) {
      dispatcher({
        type: "SET_CANDIDATE",
        payload: {
          ...candidate,
          properties: { ...candidate.properties, [key]: value },
        },
      });
    }
  };

  const onClickSubmit = () => {
    if (candidate) {
      if (candidate?.first_name && candidate?.last_name && candidate?.role && candidate?.email && candidate?.phone) {
        setStatus(ApiStatus.BUSY);
        setDataValidated(true);

        void (async (): Promise<void> => {
          if (candidate.user_id !== undefined) {
            await apiClient.updateCohortMember(candidate.cohort_id, {
              ...candidate,
            } as UpdateCohortUserRequest);
          } else {
            await apiClient.createCohortUser(candidate.cohort_id, {
              ...candidate,
            } as CreateCohortUserRequest);
          }

          const users = await apiClient.getCohortUsers(candidate.cohort_id);
          dispatcher({
            type: "SET_COHORT_USER_LIST",
            payload: users.sort((a, b) => (a.email > b.email ? 1 : -1)),
          });

          setStatus(ApiStatus.IDEL);
          setShow(false);
        })();
      } else {
        setDataValidated(false);
      }
    }
  };

  return (
    <Modal size="xl" centered show={show}>
      <Modal.Header closeButton onHide={() => setShow(false)}>
        <Modal.Title>{candidate?.user_id ? <>Edit Member</> : <>Add Member</>}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container className="mt-3 border rounded bg-light">
          <Form className="b">
            <Row className="mt-3">
              <Col lg={4}>
                <FloatingLabel controlId="f_name" label="First Name *" className="mb-3">
                  <Form.Control
                    placeholder="First name *"
                    required
                    defaultValue={candidate?.first_name ?? ""}
                    isInvalid={dataValidated === false && !candidate?.first_name}
                    onChange={(e) => {
                      onChangeCandidate("first_name", e.target.value);
                    }}
                  />
                  <Form.Control.Feedback type="invalid">First name is required</Form.Control.Feedback>
                </FloatingLabel>
              </Col>
              <Col lg={4}>
                <FloatingLabel controlId="l_name" label="Last Name *" className="mb-3">
                  <Form.Control
                    placeholder="Last name *"
                    required
                    defaultValue={candidate?.last_name ?? ""}
                    isInvalid={dataValidated === false && !candidate?.last_name}
                    onChange={(e) => {
                      onChangeCandidate("last_name", e.target.value);
                    }}
                  />
                  <Form.Control.Feedback type="invalid">Last name is required</Form.Control.Feedback>
                </FloatingLabel>
              </Col>
              <Col lg={4}>
                <FloatingLabel controlId="l_role" label="Role *" className="mb-3">
                  <Form.Select aria-label="Role *" value={UserRoleEnum._1} disabled>
                    <option>Select role</option>
                    <option value={1}>Candidate</option>
                    <option value={2}>Flight Surgeon</option>
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">First name is required</Form.Control.Feedback>
                </FloatingLabel>
              </Col>
            </Row>
            <Row>
              <Form.Group as={Col} lg="7" controlId="g_mail">
                <FloatingLabel controlId="f_email" label="Email *" className="mb-3">
                  <Form.Control
                    type="email"
                    placeholder="Email *"
                    disabled={candidate?.user_id !== undefined}
                    required
                    defaultValue={candidate?.email}
                    isInvalid={dataValidated === false && !candidate?.email}
                    onChange={(e) => {
                      onChangeCandidate("email", e.target.value);
                    }}
                  />
                  <Form.Control.Feedback type="invalid">Phone is required</Form.Control.Feedback>
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} lg="5" controlId="g_phone">
                <FloatingLabel controlId="f_phone" label="Phone *" className="mb-3">
                  <Form.Control
                    placeholder="Phone *"
                    required
                    defaultValue={candidate?.phone ?? ""}
                    isInvalid={dataValidated === false && !candidate?.phone}
                    onChange={(e) => {
                      onChangeCandidate("phone", e.target.value);
                    }}
                  />
                  <Form.Control.Feedback type="invalid">Phone is required</Form.Control.Feedback>
                </FloatingLabel>
              </Form.Group>
            </Row>
            <Row>
              <Form.Group as={Col} lg="4" controlId="g_crew_type">
                <FloatingLabel controlId="f_crew_type" label="Crew Type" className="mb-3">
                  <Form.Control
                    placeholder="Crew Type"
                    required
                    defaultValue={candidate?.properties.crew_type ?? ""}
                    onChange={(e) => {
                      onChangeCandidateProperty("crew_type", e.target.value);
                    }}
                  />
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} lg="4" controlId="g_duties">
                <FloatingLabel controlId="f_duties" label="Duties" className="mb-3">
                  <Form.Control
                    placeholder="Duties"
                    required
                    defaultValue={candidate?.properties.duties ?? ""}
                    onChange={(e) => {
                      onChangeCandidateProperty("duties", e.target.value);
                    }}
                  />
                </FloatingLabel>
              </Form.Group>
            </Row>
          </Form>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        {status === ApiStatus.BUSY ? (
          <Spinner animation="border" />
        ) : (
          <>
            <Button variant="primary" onClick={() => setShow(false)}>
              Cancel
            </Button>
            <Button variant="success" onClick={onClickSubmit}>
              {candidate?.user_id ? <>Update</> : <>Add</>}
            </Button>
          </>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default CohortMemberEditModal;
