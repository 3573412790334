import React, { Fragment, useState } from "react";
import { v4 as uuid } from "uuid";
import { Button, Col, Form, Row, ListGroup, Alert, Spinner, Container } from "react-bootstrap";
import ProgressSteps, { application_step_list } from "../../component/ProgressSteps";
import QuestionHeader from "./QuestionHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faTrashCan } from "@fortawesome/free-regular-svg-icons";

import { useMemberContext } from "../../contexts/MemberContext";
import { ExerciseItemModel, ExerciseBasic } from "../../client";
import { useAuthContext } from "../../contexts/AuthContext";
import apiClient from "../../api/api_client";
import { ApiErrorModal } from "../../component/ApiErrorModal";
import { ApiStatus } from "../../model/constant_data";
import "./CandidatePage.css";

const StepExerciseSuitability: React.FC = () => {
  const {
    state: { user },
  } = useAuthContext();
  const {
    state: { step, general, exercise },
    dispatcher,
  } = useMemberContext();

  const [saveStatus, setSaveStatus] = useState<ApiStatus>(ApiStatus.IDEL);
  const [nextStatus, setNextStatus] = useState<ApiStatus>(ApiStatus.IDEL);
  const [updated, setUpdated] = useState<boolean>(false);
  const [apiFailedFlg, setApiFailedFlg] = useState<boolean>(false);

  const [index, setIndex] = useState<number | undefined>(undefined);
  const [current_exercise, setCurrentExercise] = useState<ExerciseItemModel | undefined>(undefined);

  const onUpdateCurrentExercise = (property: string, val: string | undefined) => {
    setCurrentExercise({ ...current_exercise, [property]: val });
  };

  const [errorFlg, setErrorFlg] = useState<boolean>(false);

  const onClickAdd = () => {
    if (current_exercise?.activity && current_exercise?.frequency && current_exercise?.duration) {
      setUpdated(true);
      setErrorFlg(false);
      let new_list = [];
      if (index === undefined) {
        new_list = [...(exercise?.exercise_list || []), current_exercise];
      } else {
        new_list = [...(exercise?.exercise_list || []).filter((_, i) => i !== index), current_exercise];
      }
      dispatcher({
        type: "SET_EXERCISE",
        payload: {
          ...exercise,
          exercise_list: new_list,
        } as ExerciseBasic,
      });
      setCurrentExercise(undefined);
      setIndex(undefined);
    } else {
      setErrorFlg(true);
    }
  };

  const onClickClear = () => {
    setCurrentExercise(undefined);
    setErrorFlg(false);
  };

  const onClickEdit = (idx: number) => {
    if (exercise && exercise.exercise_list) {
      setCurrentExercise(exercise.exercise_list[idx]);
      setIndex(idx);
    }
  };

  const onClickDelete = (idx: number) => {
    if (exercise && exercise.exercise_list) {
      setUpdated(true);
      dispatcher({
        type: "SET_EXERCISE",
        payload: {
          ...exercise,
          exercise_list: exercise.exercise_list.filter((_, i) => i !== idx),
        } as ExerciseBasic,
      });
    }
  };

  const onPrevious = (event: React.UIEvent<HTMLButtonElement>) => {
    event.preventDefault();
    dispatcher({
      type: "SET_STEP",
      payload: step - 1,
    });
  };

  const onCancel = (event: React.UIEvent<HTMLButtonElement>) => {
    event.preventDefault();
    dispatcher({
      type: "SET_STEP",
      payload: 0,
    });
  };

  const onSave = (event: React.UIEvent<HTMLButtonElement>) => {
    event.preventDefault();
    void (async (): Promise<void> => {
      try {
        setSaveStatus(ApiStatus.BUSY);
        await doSave();
        setSaveStatus(ApiStatus.IDEL);
      } catch (e) {
        setSaveStatus(ApiStatus.IDEL);
        setApiFailedFlg(true);
      }
    })();
  };

  const onNext = (event: React.UIEvent<HTMLButtonElement>) => {
    event.preventDefault();
    void (async (): Promise<void> => {
      try {
        setNextStatus(ApiStatus.BUSY);
        if (updated) {
          await doSave();
        }
        setNextStatus(ApiStatus.IDEL);
        dispatcher({
          type: "SET_STEP",
          payload: step + 1,
        });
      } catch (e) {
        setNextStatus(ApiStatus.IDEL);
        setApiFailedFlg(true);
      }
    })();
  };

  const doSave = async () => {
    if (user && general && general.application_id !== undefined && exercise !== undefined) {
      await apiClient.updateApplicationExercise(user.user_id, general.application_id, exercise);
    }
  };

  return (
    <>
      <Container className="mt-3 border shadow rounded bg-light" fluid>
        <Row className="py-2 ">
          <Col>
            <ProgressSteps step_list={application_step_list} current_step={step} />
            <div className="d-flex justify-content-between mt-3">
              <div>
                <h3>Exercise and Suitability</h3>
              </div>
              <div>
                <Button variant="dark" className="mx-2" onClick={onPrevious}>
                  Previous
                </Button>
                <Button variant="warning" className="mx-2" onClick={onCancel}>
                  Cancel
                </Button>
                <Button variant="success" className="mx-2" onClick={onSave}>
                  {saveStatus === ApiStatus.BUSY && <Spinner animation="border" size="sm" className="me-2" />}
                  Save
                </Button>
                <Button
                  variant="dark"
                  className="mx-2"
                  disabled={general?.category === undefined || nextStatus === ApiStatus.BUSY}
                  onClick={onNext}
                >
                  {nextStatus === ApiStatus.BUSY && <Spinner animation="border" size="sm" className="me-2" />}
                  Next
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </Container>

      <Form className="candidate_overflower_container mt-3 px-0">
        <ListGroup>
          <QuestionHeader index={35} header={"Exercise"} collapsed={false}>
            <small>
              <p>
                During a typical 7 day period, how many times and for how long on average do you exercise for more than
                15 minutes at a level that increases your heart rate? Please enter the times per week and duration for
                each activity that you perform.
              </p>
            </small>
          </QuestionHeader>

          <ListGroup.Item style={{ paddingLeft: "4em", paddingBottom: "2em" }} className="bg-light">
            <Form noValidate validated={errorFlg}>
              <Row className="mb-3">
                <Col lg={6}>
                  <Form.Label>
                    Exercise Activity<strong> *</strong>
                  </Form.Label>
                  <Form.Control
                    size="sm"
                    value={current_exercise?.activity || ""}
                    onChange={(e) => {
                      onUpdateCurrentExercise("activity", e.target.value);
                    }}
                    required
                  />
                </Col>
                <Col lg={3}>
                  <Form.Label>
                    Times per week<strong> *</strong>
                  </Form.Label>
                  <Form.Control
                    size="sm"
                    value={current_exercise?.frequency || ""}
                    onChange={(e) => {
                      onUpdateCurrentExercise("frequency", e.target.value);
                    }}
                    required
                  />
                </Col>
                <Col lg={3}>
                  <Form.Label>
                    Duration in minutes<strong> *</strong>
                  </Form.Label>
                  <Form.Control
                    size="sm"
                    value={current_exercise?.duration || ""}
                    onChange={(e) => {
                      onUpdateCurrentExercise("duration", e.target.value);
                    }}
                    required
                  />
                </Col>
              </Row>

              {errorFlg && (
                <Row>
                  <Col>
                    <Alert variant="danger">
                      <small>Please input required exercise data.</small>
                    </Alert>
                  </Col>
                </Row>
              )}
              <Row className="mb-3">
                <Col lg={4}>
                  <Button variant="primary" className="me-2 px-3" onClick={onClickAdd}>
                    {index !== undefined ? "Update" : "Add"}
                  </Button>
                  <Button variant="primary" onClick={onClickClear}>
                    {index !== undefined ? "Cancel" : "Reset"}
                  </Button>
                </Col>
              </Row>

              {exercise && exercise.exercise_list && exercise.exercise_list.length > 0 && (
                <>
                  <Row className="mt-4 py-2 bg-dark-subtle rounded">
                    <Col lg={5}>Exercite Activity</Col>
                    <Col lg={3}>Times per week</Col>
                    <Col lg={3}>Duration in minutes</Col>
                    <Col lg={1}>&nbsp;</Col>
                  </Row>
                  {exercise.exercise_list.map((item, idx) => {
                    return (
                      <Fragment key={uuid()}>
                        <Row className="py-2 border-bottom">
                          <Col lg={5}>{item.activity}</Col>
                          <Col lg={3}>{item.frequency}</Col>
                          <Col lg={3}>{item.duration}</Col>
                          <Col lg={1} className="text-end">
                            <FontAwesomeIcon
                              icon={faPenToSquare}
                              size="lg"
                              className="me-3"
                              onClick={() => {
                                onClickEdit(idx);
                              }}
                            />
                            <FontAwesomeIcon
                              icon={faTrashCan}
                              size={"lg"}
                              color="red"
                              onClick={() => {
                                onClickDelete(idx);
                              }}
                            />
                          </Col>
                        </Row>
                      </Fragment>
                    );
                  })}
                </>
              )}
            </Form>
          </ListGroup.Item>
        </ListGroup>
      </Form>

      <ApiErrorModal show={apiFailedFlg} setShow={setApiFailedFlg} />
    </>
  );
};

export default StepExerciseSuitability;
