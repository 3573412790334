export function showMMYYYY(val: string | undefined): string {
  if (val) {
    const date = new Date(val);
    return `${(date.getMonth() + 1)
      .toString()
      .padStart(2, "0")}/${date.getFullYear()}`;
  }
  return "-";
}

export function getAge(birthday: string | undefined): number | undefined {
  if (!birthday) {
    return undefined;
  }

  try {
    const dob = new Date(birthday);
    const today = new Date();

    const diff_y = today.getFullYear() - dob.getFullYear();
    const diff_m = today.getMonth() - dob.getMonth();
    const diff_d = today.getDay() - dob.getDay();

    if (diff_m < 0 || (diff_m === 0 && diff_d >= 0)) {
      return diff_y - 1;
    }
    return diff_y;
  } catch (e) {
    return undefined;
  }
}
