import React, { ReactNode } from "react";
import { Button, Modal } from "react-bootstrap";
import "./InfoModal.css";

export interface InfoModalProps {
  title: string;
  show: boolean;
  setShow: (val: boolean) => void;
  children: ReactNode;
}

const InfoModal: React.FC<InfoModalProps> = ({
  title,
  show,
  setShow,
  children,
}) => {
  return (
    <>
      <Modal
        centered
        show={show}
        className="info-modal"
        size="xl"
        style={{ overflow: "auto" }}
      >
        <Modal.Header closeButton onHide={() => setShow(false)}>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{children}</Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => setShow(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default InfoModal;
