import React, { useEffect, useState } from "react";
import { Alert, Badge, Button, Col, ListGroup, Row, Spinner } from "react-bootstrap";
import apiClient from "../../api/api_client";
import { getFormCategoryName, getApplicationStatusName } from "../../model/constant";
import { useAuthContext } from "../../contexts/AuthContext";
import { ApplicationGeneralModel, useMemberContext } from "../../contexts/MemberContext";
import { ApplicationStatusEnum, FollowUpStatusEnum } from "../../client";
import { ApiStatus } from "../../model/constant_data";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-regular-svg-icons";
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";

const CandidateDashboard: React.FC = () => {
  const {
    state: { user },
  } = useAuthContext();
  const {
    state: { application_list },
    dispatcher,
  } = useMemberContext();

  const [initStatus, setInitStatus] = useState<ApiStatus>(ApiStatus.IDEL);
  const [nextStatus, setNextStatus] = useState<ApiStatus>(ApiStatus.IDEL);
  const [currentId, setCurrentId] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (user !== undefined) {
      void (async (): Promise<void> => {
        setInitStatus(ApiStatus.BUSY);
        const res = await apiClient.getUserApplications(user.user_id);
        dispatcher({
          type: "SET_APPLICATION_LIST",
          payload: res,
        });
        setInitStatus(ApiStatus.IDEL);
      })();
    }
  }, []);

  const onCreateApplication = (event: React.UIEvent<HTMLButtonElement>) => {
    event.preventDefault();
    dispatcher({
      type: "SET_STEP",
      payload: 1,
    });
  };

  const onContinueAction = (event: React.UIEvent<HTMLButtonElement>, application_id: string) => {
    event.preventDefault();
    setCurrentId(application_id);

    if (user !== undefined) {
      void (async (): Promise<void> => {
        setNextStatus(ApiStatus.BUSY);
        const details = await apiClient.getUserApplicationDetails(user.user_id, application_id);
        dispatcher({
          type: "SET_APPLICATION_DETAILS",
          payload: details,
        });

        dispatcher({
          type: "SET_APPLICATION_GENERAL",
          payload: (application_list?.find((item) => item.application_id === application_id) ||
            {}) as ApplicationGeneralModel,
        });
        dispatcher({
          type: "SET_STEP",
          payload: 1,
        });
        setNextStatus(ApiStatus.IDEL);
      })();
    }
  };

  const onFollowUpAction = (event: React.UIEvent<HTMLButtonElement>, application_id: string) => {
    event.preventDefault();
    setCurrentId(application_id);
    setNextStatus(ApiStatus.BUSY);
    dispatcher({
      type: "SET_APPLICATION_GENERAL",
      payload: application_list?.find((item) => item.application_id === application_id),
    });
    dispatcher({
      type: "SET_STEP",
      payload: 21,
    });
    setNextStatus(ApiStatus.IDEL);
  };

  const onViewApplication = (event: React.UIEvent<HTMLButtonElement>, application_id: string) => {
    event.preventDefault();
    setCurrentId(application_id);
    setNextStatus(ApiStatus.BUSY);
    dispatcher({
      type: "SET_APPLICATION_GENERAL",
      payload: application_list?.find((item) => item.application_id === application_id),
    });
    dispatcher({
      type: "SET_STEP",
      payload: 31,
    });
    setNextStatus(ApiStatus.IDEL);
  };

  const getBGcolor = (status: ApplicationStatusEnum): string => {
    if (status === ApplicationStatusEnum._1) {
      return "warning";
    }
    if (status === ApplicationStatusEnum._90) {
      return "secondary";
    }
    if (status === ApplicationStatusEnum._3) {
      return "error";
    }
    return "success";
  };

  return (
    <>
      <Row>
        <Col className="mt-3">
          {application_list?.length === 0 ? (
            <Alert variant="warning" className="h5">
              <FontAwesomeIcon icon={faTriangleExclamation} size="xl" className="me-2" />
              You don&apos;t have any application yet.
            </Alert>
          ) : (
            <>
              <h4 className="mt-3 mb-0 pb-0 text-light">Your Application</h4>
              {initStatus === ApiStatus.BUSY && application_list === undefined && (
                <Spinner animation="border" className="mt-3" variant="warning" />
              )}
              {application_list && (
                <ListGroup as="ol" numbered={false} className="my-3">
                  <ListGroup.Item variant="secondary">
                    <Row>
                      <Col lg={2}>Category</Col>
                      <Col lg={2}>Status</Col>
                      <Col lg={2}>Submission</Col>
                      <Col lg={2}>Review </Col>
                      <Col lg={2}>Approval</Col>
                      <Col lg={2}>Action</Col>
                    </Row>
                  </ListGroup.Item>
                  {application_list?.map((data, idx) => {
                    return (
                      <ListGroup.Item key={idx}>
                        <Row className=" align-top" style={{ minHeight: "3.5rem" }}>
                          <Col lg={2} className="">
                            {getFormCategoryName(data.category)}
                          </Col>
                          <Col lg={2} className="">
                            <Badge pill bg={getBGcolor(data.status)}>
                              {getApplicationStatusName(data.status)}
                            </Badge>
                          </Col>
                          <Col lg={2} className="">
                            {data.properties.submit_date?.substring(0, 10) || "-"}
                          </Col>
                          <Col lg={2} className="">
                            {data.overview_review?.first_review?.user_id && (
                              <div>
                                <FontAwesomeIcon
                                  icon={faCircleCheck}
                                  className="fa-lg"
                                  cursor={"pointer"}
                                  color={"green"}
                                />
                                <span className="ms-1 me-3">First review</span>
                              </div>
                            )}
                            {data.overview_review?.first_review?.user_id && (
                              <div>
                                <FontAwesomeIcon
                                  icon={faCircleCheck}
                                  className="fa-lg"
                                  cursor={"pointer"}
                                  color={"green"}
                                />
                                <span className="ms-1 me-3">Second review</span>
                              </div>
                            )}
                            {data.overview_review?.amb_review?.user_id && (
                              <div>
                                <FontAwesomeIcon
                                  icon={faCircleCheck}
                                  className="fa-lg"
                                  cursor={"pointer"}
                                  color={"green"}
                                />
                                <span className="ms-1 me-3">AMB review</span>
                              </div>
                            )}
                            {!data.overview_review?.amb_review?.user_id &&
                              !data.overview_review?.second_review?.user_id &&
                              !data.overview_review?.first_review?.user_id && <>-</>}
                          </Col>
                          <Col lg={2} className="">
                            -
                          </Col>
                          <Col lg={2} className=" clearfix ">
                            {data.status === ApplicationStatusEnum._1 && (
                              <Button
                                variant="primary"
                                onClick={(e) => {
                                  onContinueAction(e, data.application_id);
                                }}
                                size="sm"
                              >
                                {nextStatus === ApiStatus.BUSY && data.application_id === currentId && (
                                  <Spinner animation="border" size="sm" className="me-2" />
                                )}
                                Continue
                              </Button>
                            )}
                            {data.status === ApplicationStatusEnum._2 && (
                              <>
                                {data.overview_status?.followup_status?.status === FollowUpStatusEnum.OPEN ? (
                                  <Button
                                    variant="primary"
                                    onClick={(e) => {
                                      onFollowUpAction(e, data.application_id);
                                    }}
                                    // size="sm"
                                  >
                                    {nextStatus === ApiStatus.BUSY && data.application_id === currentId && (
                                      <Spinner animation="border" size="sm" className="me-2" />
                                    )}
                                    <span>Follow Up</span>
                                  </Button>
                                ) : (
                                  <Button
                                    variant="primary"
                                    onClick={(e) => {
                                      onViewApplication(e, data.application_id);
                                    }}
                                    // size="sm"
                                  >
                                    {nextStatus === ApiStatus.BUSY && data.application_id === currentId && (
                                      <Spinner animation="border" size="sm" className="me-2" />
                                    )}
                                    View
                                  </Button>
                                )}
                              </>
                            )}
                            {/* {[0, 2].includes(data.status) ? (
                              <>
                                <Button
                                  variant="primary"
                                  onClick={(e) => {
                                    onFollowUpAction(e, data.application_id);
                                  }}
                                >
                                  Follow Up
                                </Button>
                              </>
                            ) : (
                              <Button
                                variant="primary"
                                onClick={(e) => {
                                  onViewApplication(e, data.application_id);
                                }}
                              >
                                View
                              </Button>
                            )} */}
                          </Col>
                        </Row>
                      </ListGroup.Item>
                    );
                  })}
                </ListGroup>
              )}
            </>
          )}
        </Col>
      </Row>
      {application_list?.filter((item) => item.status !== ApplicationStatusEnum._90).length === 0 && (
        <Row>
          <Col className="my-3">
            <Button variant="primary" onClick={onCreateApplication}>
              Create New Application
            </Button>
          </Col>
        </Row>
      )}
    </>
  );
};

export default CandidateDashboard;
