import { faCircleCheck } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Fragment } from "react";
import { Badge, Col, Row, Stack } from "react-bootstrap";

export interface StepData {
  label: string;
  step: number;
}

export const application_step_list: StepData[] = [
  {
    label: "General",
    step: 1,
  },
  {
    label: "Demographics",
    step: 2,
  },
  {
    label: "Medication",
    step: 3,
  },
  {
    label: "Medical History",
    step: 4,
  },
  {
    label: "Medical Visits",
    step: 5,
  },
  {
    label: "Exercise",
    step: 6,
  },
  {
    label: "Extreme Environment",
    step: 7,
  },
];

export const followup_step_list: StepData[] = [
  {
    label: "Submitted Application",
    step: 21,
  },
  {
    label: "Follow-up",
    step: 22,
  },
];

export interface ProgressStepsProps {
  step_list: StepData[];
  current_step: number;
}

const ProgressSteps: React.FC<ProgressStepsProps> = ({ step_list, current_step }) => {
  const getBgClass = (idx: number): string => {
    if (idx === current_step) {
      return "info";
    } else if (idx < current_step) {
      return "primary";
    }
    // return "secondary";
    return "primary";
  };

  return (
    <Row>
      <Col lg={{ span: 6, offset: 6 }} className="float-end">
        <Stack direction="horizontal" gap={0} className="float-end">
          {step_list.map((item) => {
            return (
              <Fragment key={item.step}>
                <Badge pill className="py-2 px-2" bg={getBgClass(item.step)}>
                  {item.step < current_step && (
                    <FontAwesomeIcon icon={faCircleCheck} size={"lg"} color="black" className="me-2" />
                  )}
                  <span className="bold">{item.label}</span>
                </Badge>
                {item.step < step_list[step_list.length - 1].step && (
                  <hr
                    style={{
                      // border: `2px solid ${item.step < current_step ? "green" : "lightgray"}`,
                      border: `2px solid ${item.step < current_step ? "black" : "black"}`,
                      width: "2em",
                      opacity: "1",
                    }}
                  />
                )}
              </Fragment>
            );
          })}
        </Stack>
      </Col>
    </Row>
  );
};

export default ProgressSteps;
