import authEventBus, { AuthEvent } from "./AuthEventBus";

async function checkResponse(response: Response) {
  if (!response.ok) {
    let niceBody: string;
    try {
      niceBody = await response.text();
    } catch (e) {
      niceBody = ``;
    }

    if (response.status === 401) {
      authEventBus.dispatch(AuthEvent.logout, response);
    }
    throw new Error(`API call failed with code: ${response.status}, body: ${niceBody}`);
  }
}

export async function get<T>(query: string, headers: Record<string, string> = {}): Promise<T> {
  const fetchResponse = await fetch(`${query}`, {
    method: "GET",
    headers: headers,
  });
  await checkResponse(fetchResponse);
  return fetchResponse.json();
}

export async function post<T, U>(query: string, body: T, headers: Record<string, string> = {}): Promise<U> {
  const fetchResponse = await fetch(`${query}`, {
    method: "POST",
    body: JSON.stringify(body),
    headers: {
      ...headers,
      "Content-Type": "application/json",
    },
  });
  await checkResponse(fetchResponse);
  return fetchResponse.json();
}

export async function put<T, U>(query: string, body: T, headers: Record<string, string> = {}): Promise<U> {
  const fetchResponse = await fetch(`${query}`, {
    method: "PUT",
    body: JSON.stringify(body),
    headers: {
      ...headers,
      "Content-Type": "application/json",
    },
  });
  await checkResponse(fetchResponse);
  return fetchResponse.json();
}

export async function del<U>(query: string, headers: Record<string, string> = {}): Promise<U> {
  const fetchResponse = await fetch(`${query}`, {
    method: "DELETE",
    headers,
  });
  await checkResponse(fetchResponse);
  return fetchResponse.json();
}
