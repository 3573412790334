import React, { Suspense, useEffect } from "react";
import { HelmetProvider, Helmet } from "react-helmet-async";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Amplify } from "aws-amplify";
import { CookieStorage } from "aws-amplify/utils";
import { cognitoUserPoolsTokenProvider } from "aws-amplify/auth/cognito";
import "@aws-amplify/ui-react/styles.css";
import "./App.css";
import "react-datepicker/dist/react-datepicker.css";
import "./theme/DatePicker.css";
import DashboardSwitchPage from "./view/DashboardSwitchPage";

import amplifyconfig from "./amplifyconfiguration.json";
import { AuthContextProvider, authReducer } from "./contexts/AuthContext";
import EventBus, { AuthEvent } from "./api/AuthEventBus";
import { signOut } from "aws-amplify/auth";

Amplify.configure(amplifyconfig);

cognitoUserPoolsTokenProvider.setKeyValueStorage(new CookieStorage());

const App: React.FC = () => {
  const [state, dispatcher] = React.useReducer(authReducer, {});

  // Attach listener for AuthEvent
  useEffect(() => {
    EventBus.on(AuthEvent.logout, () => {
      if (signOut) {
        void (async (): Promise<void> => {
          await signOut();
        })();
      }

      dispatcher({
        type: "SET_ERROR",
        payload: "something wrong",
      });

      window.onbeforeunload = null;
      // window.location.replace("/no_access");
      window.location.replace("/");
    });

    return () => {
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      EventBus.remove(AuthEvent.logout, () => {});
    };
  }, []);

  return (
    <AuthContextProvider state={state} dispatcher={dispatcher}>
      <HelmetProvider>
        <Helmet>
          <title>Ambient</title>
          <meta charSet="utf-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
        </Helmet>
        <Suspense fallback={<div>Loading...</div>}>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<DashboardSwitchPage />} />
              {/* <Route path="/dashboard" element={<FlightSurgeonContainer />} /> */}
              {/* <Route path="/no_access" element={<NoAccessPage />} /> */}
            </Routes>
          </BrowserRouter>
        </Suspense>
      </HelmetProvider>
    </AuthContextProvider>
  );
};

export default App;
