import React, { Fragment } from "react";
import { v4 as uuid } from "uuid";
import { Col, ListGroup, Row } from "react-bootstrap";
import {
  ExplanationDataModel,
  MedicalHistoryAnswerModel,
  MedicalHistoryBasic,
  QueryFormResponse,
  QuestionModel,
} from "../client";
import { showMMYYYY } from "../api/ui_util";

export interface ViewMedicalHistoryProps {
  question_from?: QueryFormResponse | undefined;
  medical_history?: MedicalHistoryBasic | undefined;
}

const ViewMedicalHistory: React.FC<ViewMedicalHistoryProps> = ({ question_from, medical_history }) => {
  const getExplanation = (item: ExplanationDataModel, answer: MedicalHistoryAnswerModel) => {
    const data = answer.explanation_answer_list?.find((op) => op.explanation_id === item.explanation_id);

    return (
      <ListGroup.Item variant="secondary" key={uuid()}>
        <Row>
          <Col lg={4}>
            <span>{item.question}</span>
          </Col>
          <Col lg={8}>
            {data !== undefined ? (
              <ListGroup>
                {/* text */}
                {item.category === 3 && (
                  <ListGroup.Item className="bg-light" key={uuid()}>
                    {data.comment}
                  </ListGroup.Item>
                )}
                {/* mm/yyyy */}
                {item.category === 4 && (
                  <ListGroup.Item className="bg-light" key={uuid()}>
                    {showMMYYYY(data.comment || "")}
                  </ListGroup.Item>
                )}
                {/* Checkbox, radio box */}
                {(item.category === 1 || item.category === 2) && (
                  <>
                    {data.option_list?.map((val) => {
                      return (
                        <ListGroup.Item className="bg-light" key={uuid()}>
                          {item.options?.option_list?.find((op) => op.value === val.toString())?.label}
                        </ListGroup.Item>
                      );
                    })}
                    {data.comment && <ListGroup.Item className="bg-light">{data.comment}</ListGroup.Item>}
                  </>
                )}
              </ListGroup>
            ) : (
              <>-</>
            )}
          </Col>
        </Row>
      </ListGroup.Item>
    );
  };

  const getAnswer = (question: QuestionModel) => {
    const answer = medical_history?.answer_list?.find((an) => an.question_id === question.question_id);
    if (answer === undefined) {
      return <>-</>;
    }

    return (
      <>
        {question.category === 1 && (
          <p>
            {answer.answer === true && "Yes"}
            {answer.answer === false && "No"}
            {answer.answer_comment && <span className="ms-2">({answer.answer_comment})</span>}
          </p>
        )}
        {question.category === 2 && (
          <>
            {answer.answer instanceof Array && (
              <ListGroup className="my-1">
                {answer.answer.map((an) => {
                  return (
                    <ListGroup.Item className="bg-light" key={uuid()}>
                      {question.options?.option_list?.find((op) => op.value === an.toString())?.label}
                    </ListGroup.Item>
                  );
                })}
              </ListGroup>
            )}
            {answer.answer_comment && <span className="ms-2">({answer.answer_comment})</span>}
          </>
        )}
        {answer.explanation_answer_list && (
          <ListGroup>
            {question.explanation_list?.map((exp) => {
              return getExplanation(exp, answer);
            })}
          </ListGroup>
        )}
      </>
    );
  };

  return (
    <ListGroup>
      <ListGroup.Item variant="secondary">
        <Row>
          <Col lg={1}>
            <strong>ID</strong>
          </Col>
          <Col lg={5}>
            <strong>Question</strong>
          </Col>
          <Col lg={6}>
            <strong>Answer</strong>
          </Col>
        </Row>
      </ListGroup.Item>
      {question_from?.group_list?.map((group) => {
        return (
          <Fragment key={uuid()}>
            <ListGroup.Item className="p-0" variant="dark" key={uuid()}>
              <Row className="mx-0">
                <Col lg={12} className="py-2">
                  <strong>{group.group_title || "-"}</strong>
                </Col>
              </Row>
            </ListGroup.Item>
            {group.question_list.map((item) => {
              return (
                <ListGroup.Item className="p-0" variant="secondary" key={uuid()}>
                  <Row className="mx-0">
                    <Col lg={1} className="py-2 text-center">
                      {item.display_index + 11}
                    </Col>
                    <Col lg={5} className="py-2">
                      {item.question}
                    </Col>
                    <Col lg={6} className="py-2 bg-light">
                      {getAnswer(item)}
                    </Col>
                  </Row>
                </ListGroup.Item>
              );
            })}
          </Fragment>
        );
      })}
    </ListGroup>
  );
};

export default ViewMedicalHistory;
