import React from "react";
// import { TokenUser } from "../model/api_model";
import { FetchUserAttributesOutput } from "aws-amplify/auth";
import { QueryUserResponse } from "../client";

export interface AuthContextState {
  token_sub?: string;
  user?: QueryUserResponse;
  cognito_token?: string;
  error?: string;
  user_attributes?: FetchUserAttributesOutput;
}

export type AuthActions =
  | {
      type: "SET_TOKEN_SUB";
      payload: string | undefined;
    }
  | {
      type: "SET_USER";
      payload: QueryUserResponse | undefined;
    }
  | {
      type: "SET_USER_ATTRIBUTES";
      payload: FetchUserAttributesOutput | undefined;
    }
  | {
      type: "SET_ERROR";
      payload: string | undefined;
    }
  | {
      type: "SET_TOKEN";
      payload: string | undefined;
    };

const authReducer: React.Reducer<AuthContextState, AuthActions> = (state, action) => {
  switch (action.type) {
    case "SET_TOKEN_SUB":
      return { ...state, token_sub: action.payload };
    case "SET_USER":
      return { ...state, user: action.payload };
    case "SET_USER_ATTRIBUTES":
      return { ...state, user_attributes: action.payload };
    case "SET_TOKEN":
      return { ...state, cognito_token: action.payload };
    case "SET_ERROR":
      return { ...state, error: action.payload };
  }
};

export type AuthContextType = {
  state: AuthContextState;
  dispatcher: React.Dispatch<React.ReducerAction<React.Reducer<AuthContextState, AuthActions>>>;
};
const AuthContext = React.createContext<AuthContextType | undefined>(undefined);

export interface AuthProviderProps {
  children: React.ReactNode;
}
const AuthContextProvider: React.FC<AuthProviderProps & AuthContextType> = ({ children, ...context }) => (
  <AuthContext.Provider value={context}>{children}</AuthContext.Provider>
);

const useAuthContext = (): AuthContextType => {
  const context = React.useContext(AuthContext) as AuthContextType;
  if (context == null) {
    throw new Error("useAuthContext must be used within AuthContextProvider");
  }
  return context;
};

export { AuthContextProvider, useAuthContext, authReducer };
