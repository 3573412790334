import React from "react";
import { Form } from "react-bootstrap";
import { StringConstantType } from "../model/constant";

export const country_list: StringConstantType[] = [
  {
    id: "1",
    name: "USA",
  },
  {
    id: "991",
    name: "APO",
  },
  {
    id: "105",
    name: "Aden",
  },
  {
    id: "110",
    name: "Afghanistan",
  },
  {
    id: "120",
    name: "Albania",
  },
  {
    id: "125",
    name: "Algeria",
  },
  {
    id: "140",
    name: "American Samoa",
  },
  {
    id: "142",
    name: "Andorra",
  },
  {
    id: "145",
    name: "Angola",
  },
  {
    id: "149",
    name: "Antigua",
  },
  {
    id: "144",
    name: "Arctic Ocean",
  },
  {
    id: "150",
    name: "Argentina",
  },
  {
    id: "152",
    name: "Armenia",
  },
  {
    id: "153",
    name: "Aruba",
  },
  {
    id: "154",
    name: "Ashmore Cartier Isl",
  },
  {
    id: "155",
    name: "Atlantic Isl",
  },
  {
    id: "160",
    name: "Australia",
  },
  {
    id: "165",
    name: "Austria",
  },
  {
    id: "170",
    name: "Azerbaijan",
  },
  {
    id: "175",
    name: "Azores",
  },
  {
    id: "180",
    name: "Bahamas",
  },
  {
    id: "182",
    name: "Bahrain",
  },
  {
    id: "185",
    name: "Baker Island",
  },
  {
    id: "183",
    name: "Bangladesh",
  },
  {
    id: "184",
    name: "Barbados",
  },
  {
    id: "187",
    name: "Bassas Da India",
  },
  {
    id: "253",
    name: "Belarus",
  },
  {
    id: "190",
    name: "Belgium",
  },
  {
    id: "193",
    name: "Belize",
  },
  {
    id: "194",
    name: "Benin",
  },
  {
    id: "195",
    name: "Bermuda",
  },
  {
    id: "200",
    name: "Bhutan",
  },
  {
    id: "205",
    name: "Bolivia",
  },
  {
    id: "207",
    name: "Bosnia &amp; Herzegovi",
  },
  {
    id: "210",
    name: "Botswana",
  },
  {
    id: "215",
    name: "Bouvet Island",
  },
  {
    id: "220",
    name: "Brazil",
  },
  {
    id: "230",
    name: "Brit Honduras",
  },
  {
    id: "231",
    name: "Brit Virgin Is",
  },
  {
    id: "229",
    name: "Brit W Indies",
  },
  {
    id: "225",
    name: "British Indian OCN",
  },
  {
    id: "232",
    name: "Brunei",
  },
  {
    id: "245",
    name: "Bulgaria",
  },
  {
    id: "927",
    name: "Burkina",
  },
  {
    id: "250",
    name: "Burma",
  },
  {
    id: "252",
    name: "Burundi",
  },
  {
    id: "255",
    name: "Cambodia",
  },
  {
    id: "257",
    name: "Cameroon",
  },
  {
    id: "260",
    name: "Canada",
  },
  {
    id: "265",
    name: "Canal Zone",
  },
  {
    id: "266",
    name: "Canary Islands",
  },
  {
    id: "267",
    name: "Canton-Enderbury",
  },
  {
    id: "264",
    name: "Cape Verde",
  },
  {
    id: "268",
    name: "Cayman Islands",
  },
  {
    id: "270",
    name: "Ceylon",
  },
  {
    id: "273",
    name: "Chad",
  },
  {
    id: "274",
    name: "Channel Isles",
  },
  {
    id: "275",
    name: "Chile",
  },
  {
    id: "280",
    name: "China",
  },
  {
    id: "281",
    name: "Christmas Island",
  },
  {
    id: "282",
    name: "Ciskei",
  },
  {
    id: "269",
    name: "Cntrl African Republic",
  },
  {
    id: "283",
    name: "Cocos (Keeling) Isl",
  },
  {
    id: "285",
    name: "Colombia",
  },
  {
    id: "287",
    name: "Comoros",
  },
  {
    id: "290",
    name: "Congo",
  },
  {
    id: "292",
    name: "Cook Islands",
  },
  {
    id: "293",
    name: "Coral Sea Islands",
  },
  {
    id: "295",
    name: "Costa Rica",
  },
  {
    id: "485",
    name: "Cote D&apos;Ivoire",
  },
  {
    id: "297",
    name: "Croatia",
  },
  {
    id: "300",
    name: "Cuba",
  },
  {
    id: "305",
    name: "Cyprus",
  },
  {
    id: "309",
    name: "Czech Republic",
  },
  {
    id: "310",
    name: "Czechoslovakia",
  },
  {
    id: "311",
    name: "Dahomey",
  },
  {
    id: "313",
    name: "Dem Rep Congo",
  },
  {
    id: "315",
    name: "Denmark",
  },
  {
    id: "317",
    name: "Djibouti",
  },
  {
    id: "318",
    name: "Dominica",
  },
  {
    id: "320",
    name: "Dominican Republic",
  },
  {
    id: "323",
    name: "East Germany",
  },
  {
    id: "325",
    name: "Ecuador",
  },
  {
    id: "327",
    name: "Egypt",
  },
  {
    id: "330",
    name: "El Salvador",
  },
  {
    id: "332",
    name: "Equatorial Guinea",
  },
  {
    id: "328",
    name: "Eritrea",
  },
  {
    id: "334",
    name: "Estonia",
  },
  {
    id: "335",
    name: "Ethiopia",
  },
  {
    id: "333",
    name: "Europa Island",
  },
  {
    id: "992",
    name: "FPO",
  },
  {
    id: "337",
    name: "Falkland Isl",
  },
  {
    id: "336",
    name: "Faroe Islands",
  },
  {
    id: "338",
    name: "Fiji",
  },
  {
    id: "340",
    name: "Finland",
  },
  {
    id: "350",
    name: "France",
  },
  {
    id: "370",
    name: "French Afars-Issas",
  },
  {
    id: "355",
    name: "French Guiana",
  },
  {
    id: "367",
    name: "French Polynesia",
  },
  {
    id: "375",
    name: "French So &amp; Antarctic",
  },
  {
    id: "368",
    name: "French Somaliland",
  },
  {
    id: "351",
    name: "French W. Ind",
  },
  {
    id: "388",
    name: "Gabon",
  },
  {
    id: "389",
    name: "Gambia, The",
  },
  {
    id: "393",
    name: "Gaza Strip",
  },
  {
    id: "395",
    name: "Georgia",
  },
  {
    id: "390",
    name: "Germany",
  },
  {
    id: "385",
    name: "Germany, Berlin",
  },
  {
    id: "391",
    name: "Ghana",
  },
  {
    id: "392",
    name: "Gibraltar",
  },
  {
    id: "400",
    name: "Greece",
  },
  {
    id: "405",
    name: "Greenland",
  },
  {
    id: "406",
    name: "Grenada",
  },
  {
    id: "407",
    name: "Guadeloupe",
  },
  {
    id: "410",
    name: "Guam",
  },
  {
    id: "415",
    name: "Guatemala",
  },
  {
    id: "417",
    name: "Guinea",
  },
  {
    id: "416",
    name: "Guinea-Bissau",
  },
  {
    id: "418",
    name: "Guyana",
  },
  {
    id: "420",
    name: "Haiti",
  },
  {
    id: "425",
    name: "Heard McDonald Is",
  },
  {
    id: "430",
    name: "Honduras",
  },
  {
    id: "435",
    name: "Hong Kong",
  },
  {
    id: "445",
    name: "Hungary",
  },
  {
    id: "450",
    name: "Iceland",
  },
  {
    id: "455",
    name: "India",
  },
  {
    id: "458",
    name: "Indonesia",
  },
  {
    id: "460",
    name: "Iran",
  },
  {
    id: "465",
    name: "Iraq",
  },
  {
    id: "467",
    name: "Iraq-Saudi Arabia",
  },
  {
    id: "470",
    name: "Ireland",
  },
  {
    id: "475",
    name: "Israel",
  },
  {
    id: "480",
    name: "Italy",
  },
  {
    id: "487",
    name: "Jamaica",
  },
  {
    id: "490",
    name: "Japan",
  },
  {
    id: "495",
    name: "Jarvis Island",
  },
  {
    id: "496",
    name: "Jersey",
  },
  {
    id: "497",
    name: "Johnston Atoll",
  },
  {
    id: "500",
    name: "Jordan",
  },
  {
    id: "503",
    name: "Kazakhstan",
  },
  {
    id: "505",
    name: "Kenya",
  },
  {
    id: "510",
    name: "Kiribati",
  },
  {
    id: "517",
    name: "Korea, North",
  },
  {
    id: "515",
    name: "Korea, Republic of",
  },
  {
    id: "520",
    name: "Kuwait",
  },
  {
    id: "522",
    name: "Kyrgyzstan",
  },
  {
    id: "530",
    name: "Laos",
  },
  {
    id: "525",
    name: "Latvia",
  },
  {
    id: "540",
    name: "Lebanon",
  },
  {
    id: "544",
    name: "Leeward Isles",
  },
  {
    id: "543",
    name: "Lesotho",
  },
  {
    id: "545",
    name: "Liberia",
  },
  {
    id: "550",
    name: "Libya",
  },
  {
    id: "553",
    name: "Liechtenstein",
  },
  {
    id: "560",
    name: "Lithuania",
  },
  {
    id: "570",
    name: "Luxembourg",
  },
  {
    id: "571",
    name: "Macau",
  },
  {
    id: "572",
    name: "Macedonia",
  },
  {
    id: "575",
    name: "Madagascar",
  },
  {
    id: "577",
    name: "Malawi",
  },
  {
    id: "581",
    name: "Malaysia (A-A)",
  },
  {
    id: "580",
    name: "Malaysia(Asia)",
  },
  {
    id: "582",
    name: "Maldives",
  },
  {
    id: "585",
    name: "Mali",
  },
  {
    id: "590",
    name: "Malta",
  },
  {
    id: "589",
    name: "Marianas Isles",
  },
  {
    id: "586",
    name: "Marshall Islands",
  },
  {
    id: "591",
    name: "Martinique",
  },
  {
    id: "592",
    name: "Mauritania",
  },
  {
    id: "593",
    name: "Mauritius",
  },
  {
    id: "595",
    name: "Mexico",
  },
  {
    id: "606",
    name: "Micronesia",
  },
  {
    id: "604",
    name: "Midway Islands",
  },
  {
    id: "605",
    name: "Moldova",
  },
  {
    id: "607",
    name: "Monaco",
  },
  {
    id: "608",
    name: "Mongolia",
  },
  {
    id: "611",
    name: "Montenegro",
  },
  {
    id: "609",
    name: "Montserrat",
  },
  {
    id: "610",
    name: "Morocco",
  },
  {
    id: "615",
    name: "Mozambique",
  },
  {
    id: "616",
    name: "Muscat-Oman",
  },
  {
    id: "613",
    name: "Myanmar",
  },
  {
    id: "617",
    name: "Namibia",
  },
  {
    id: "618",
    name: "Nampo-Shoto",
  },
  {
    id: "621",
    name: "Nauru",
  },
  {
    id: "620",
    name: "Navassa Island",
  },
  {
    id: "625",
    name: "Nepal",
  },
  {
    id: "630",
    name: "Netherlands",
  },
  {
    id: "640",
    name: "Netherlands Antilles",
  },
  {
    id: "645",
    name: "New Caledonia",
  },
  {
    id: "650",
    name: "New Guinea",
  },
  {
    id: "655",
    name: "New Hebrides",
  },
  {
    id: "660",
    name: "New Zealand",
  },
  {
    id: "665",
    name: "Nicaragua",
  },
  {
    id: "667",
    name: "Niger",
  },
  {
    id: "670",
    name: "Nigeria",
  },
  {
    id: "675",
    name: "Niue",
  },
  {
    id: "680",
    name: "Norfolk Island",
  },
  {
    id: "685",
    name: "Norway",
  },
  {
    id: "690",
    name: "Oman",
  },
  {
    id: "695",
    name: "Org of E Carib Sts",
  },
  {
    id: "999",
    name: "Other (Unknown)",
  },
  {
    id: "700",
    name: "Pakistan",
  },
  {
    id: "705",
    name: "Palau",
  },
  {
    id: "711",
    name: "Palestine",
  },
  {
    id: "710",
    name: "Panama",
  },
  {
    id: "712",
    name: "Papua New Guinea",
  },
  {
    id: "713",
    name: "Paracel Islands",
  },
  {
    id: "715",
    name: "Paraguay",
  },
  {
    id: "720",
    name: "Peru",
  },
  {
    id: "725",
    name: "Philippines",
  },
  {
    id: "727",
    name: "Pitcairn Islands",
  },
  {
    id: "730",
    name: "Poland",
  },
  {
    id: "735",
    name: "Portugal",
  },
  {
    id: "737",
    name: "Portuguese Guinea",
  },
  {
    id: "745",
    name: "Puerto Rico",
  },
  {
    id: "747",
    name: "Qatar",
  },
  {
    id: "750",
    name: "Reunion Island",
  },
  {
    id: "755",
    name: "Romania",
  },
  {
    id: "757",
    name: "Russia",
  },
  {
    id: "758",
    name: "Rwanda Rep",
  },
  {
    id: "760",
    name: "Ryukyu Islands",
  },
  {
    id: "777",
    name: "Saipan",
  },
  {
    id: "778",
    name: "Samalia",
  },
  {
    id: "963",
    name: "Samoa",
  },
  {
    id: "782",
    name: "San Marino",
  },
  {
    id: "761",
    name: "Sao-Tome Principe",
  },
  {
    id: "785",
    name: "Saudi Arabia",
  },
  {
    id: "787",
    name: "Senegal",
  },
  {
    id: "786",
    name: "Serbia",
  },
  {
    id: "789",
    name: "Serbia and Montenegro",
  },
  {
    id: "788",
    name: "Seychelles",
  },
  {
    id: "790",
    name: "Sierra Leone",
  },
  {
    id: "795",
    name: "Singapore",
  },
  {
    id: "797",
    name: "Slovakia",
  },
  {
    id: "798",
    name: "Slovenia",
  },
  {
    id: "799",
    name: "Solomon Islands",
  },
  {
    id: "800",
    name: "Somalia",
  },
  {
    id: "801",
    name: "South Africa",
  },
  {
    id: "803",
    name: "South Rhodesia",
  },
  {
    id: "834",
    name: "South Sudan",
  },
  {
    id: "804",
    name: "Southwest Africa",
  },
  {
    id: "805",
    name: "Soviet Union",
  },
  {
    id: "830",
    name: "Spain",
  },
  {
    id: "832",
    name: "Spanish Sahara",
  },
  {
    id: "272",
    name: "Sri Lanka",
  },
  {
    id: "763",
    name: "St Chris and Nevis",
  },
  {
    id: "765",
    name: "St Helena",
  },
  {
    id: "767",
    name: "St Kitts and Nevis",
  },
  {
    id: "770",
    name: "St Lucia",
  },
  {
    id: "772",
    name: "St Pierre Miquelon",
  },
  {
    id: "775",
    name: "St Vincent and Grena",
  },
  {
    id: "995",
    name: "Stateless",
  },
  {
    id: "835",
    name: "Sudan",
  },
  {
    id: "840",
    name: "Suriname",
  },
  {
    id: "842",
    name: "Svalbard-Jan Mayen",
  },
  {
    id: "845",
    name: "Swan Islands",
  },
  {
    id: "847",
    name: "Swaziland",
  },
  {
    id: "850",
    name: "Sweden",
  },
  {
    id: "855",
    name: "Switzerland",
  },
  {
    id: "858",
    name: "Syria",
  },
  {
    id: "862",
    name: "Taiwan",
  },
  {
    id: "863",
    name: "Taiwan, Roc",
  },
  {
    id: "864",
    name: "Tajikistan",
  },
  {
    id: "865",
    name: "Tanzania",
  },
  {
    id: "875",
    name: "Thailand",
  },
  {
    id: "885",
    name: "Togo",
  },
  {
    id: "886",
    name: "Tokelau",
  },
  {
    id: "888",
    name: "Tonga",
  },
  {
    id: "887",
    name: "Trinidad And Tobago",
  },
  {
    id: "900",
    name: "Trust Terr Pac Isl",
  },
  {
    id: "890",
    name: "Tunisia",
  },
  {
    id: "905",
    name: "Turkey",
  },
  {
    id: "895",
    name: "Turkmenistan",
  },
  {
    id: "907",
    name: "Turks &amp; Caicos Isl",
  },
  {
    id: "922",
    name: "UAR",
  },
  {
    id: "910",
    name: "Uganda",
  },
  {
    id: "915",
    name: "Ukraine",
  },
  {
    id: "920",
    name: "United Arab Emirat",
  },
  {
    id: "925",
    name: "United Kingdom",
  },
  {
    id: "930",
    name: "Uruguay",
  },
  {
    id: "926",
    name: "Uzbekistan",
  },
  {
    id: "651",
    name: "Vanuata",
  },
  {
    id: "935",
    name: "Vatican City",
  },
  {
    id: "940",
    name: "Venezuela",
  },
  {
    id: "945",
    name: "Vietnam",
  },
  {
    id: "950",
    name: "Virgin Islands",
  },
  {
    id: "960",
    name: "Wake Island",
  },
  {
    id: "955",
    name: "Wallis and Futuna",
  },
  {
    id: "964",
    name: "West Germany",
  },
  {
    id: "962",
    name: "West Indies",
  },
  {
    id: "961",
    name: "Western Sahara",
  },
  {
    id: "965",
    name: "Yemen",
  },
  {
    id: "966",
    name: "Yemen (ADEN)",
  },
  {
    id: "967",
    name: "Yemen (SANAA)",
  },
  {
    id: "970",
    name: "Yugoslavia",
  },
  {
    id: "291",
    name: "Zaire",
  },
  {
    id: "990",
    name: "Zambia",
  },
  {
    id: "998",
    name: "Zimbabwe",
  },
];

export const getCountryName = (id: string | null | undefined): string => {
  const data = country_list.find((item) => item.id === id);
  return data?.name || "";
};

export interface CountrySelectorProps {
  label: string;
  val: string | undefined;
  property: string;
  onChanged: (n: string, v: string | undefined) => void;
}

const CountrySelector: React.FC<CountrySelectorProps> = ({ label, val, property, onChanged }) => {
  return (
    <>
      <Form.Label>{label}</Form.Label>
      <Form.Select
        size="sm"
        value={val}
        onChange={(e) => {
          onChanged(property, e.target.value);
        }}
      >
        <option value={""}>&nbsp;</option>
        {country_list.map((item) => {
          return (
            <option value={item.id} key={item.id}>
              {item.name}
            </option>
          );
        })}
      </Form.Select>
    </>
  );
};

export default CountrySelector;
