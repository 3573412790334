import React, { useState } from "react";
import {
  Button,
  Col,
  Container,
  FloatingLabel,
  Form,
  Modal,
  Row,
  Spinner,
} from "react-bootstrap";
import { useManagerContext } from "../../contexts/ManagerContext";
import apiClient from "../../api/api_client";
import { InviteUserRequest, UpdateUserRequest } from "../../client";
import { ApiStatus } from "../../model/constant_data";

export interface FlifghtSurgeonEditModalProps {
  show: boolean;
  setShow: (val: boolean) => void;
}

const FlifghtSurgeonEditModal: React.FC<FlifghtSurgeonEditModalProps> = ({
  show,
  setShow,
}) => {
  const {
    state: { flight_surgeon },
    dispatcher,
  } = useManagerContext();

  const [status, setStatus] = useState<ApiStatus>(ApiStatus.IDEL);

  const [dataValidated, setDataValidated] = useState<boolean | undefined>(
    undefined
  );

  const onUpdateSurgeonData = (
    key: string,
    value: string | number | undefined
  ) => {
    if (flight_surgeon) {
      dispatcher({
        type: "SET_FLIGHT_SURGEON",
        payload: { ...flight_surgeon, [key]: value },
      });
    }
  };

  const onClickSubmit = () => {
    if (flight_surgeon) {
      if (
        flight_surgeon?.first_name &&
        flight_surgeon?.last_name &&
        flight_surgeon?.role &&
        flight_surgeon?.email &&
        flight_surgeon?.phone
      ) {
        setStatus(ApiStatus.BUSY);
        setDataValidated(true);

        void (async (): Promise<void> => {
          if (flight_surgeon.user_id !== undefined) {
            await apiClient.updateTokenUser({
              user_id: flight_surgeon.user_id,
              email: flight_surgeon.email,
              role: flight_surgeon.role,
              first_name: flight_surgeon.first_name || "",
              last_name: flight_surgeon.last_name || "",
              phone: flight_surgeon.phone || "",
            } as UpdateUserRequest);
          } else {
            await apiClient.createTokenUser({
              email: flight_surgeon.email,
              role: flight_surgeon.role,
              first_name: flight_surgeon.first_name || "",
              last_name: flight_surgeon.last_name || "",
              phone: flight_surgeon.phone || "",
            } as InviteUserRequest);
          }

          const new_list = await apiClient.getAllFlightSurgeons();
          dispatcher({
            type: "SET_SURGEON_LIST",
            payload: new_list,
          });

          dispatcher({
            type: "SET_FLIGHT_SURGEON",
            payload: undefined,
          });

          setStatus(ApiStatus.IDEL);
          setShow(false);
        })();
      } else {
        setDataValidated(false);
      }
    }
  };

  return (
    <Modal size="xl" centered show={show}>
      <Modal.Header closeButton onHide={() => setShow(false)}>
        <Modal.Title>
          {flight_surgeon?.user_id ? <>Edit Member</> : <>Add Member</>}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container className="mt-3 border rounded bg-light">
          <Form className="b">
            <Row className="mt-3">
              <Col lg={4}>
                <FloatingLabel
                  controlId="f_name"
                  label="First Name"
                  className="mb-3"
                >
                  <Form.Control
                    placeholder="First name"
                    required
                    defaultValue={flight_surgeon?.first_name ?? ""}
                    isInvalid={
                      dataValidated === false && !flight_surgeon?.first_name
                    }
                    onChange={(e) => {
                      onUpdateSurgeonData("first_name", e.target.value);
                    }}
                  />
                  <Form.Control.Feedback type="invalid">
                    First name is required
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Col>
              <Col lg={4}>
                <FloatingLabel
                  controlId="l_name"
                  label="Last Name"
                  className="mb-3"
                >
                  <Form.Control
                    placeholder="Last name"
                    required
                    defaultValue={flight_surgeon?.last_name ?? ""}
                    isInvalid={
                      dataValidated === false && !flight_surgeon?.last_name
                    }
                    onChange={(e) => {
                      onUpdateSurgeonData("last_name", e.target.value);
                    }}
                  />
                  <Form.Control.Feedback type="invalid">
                    Last name is required
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Col>
              <Col lg={4}>
                <FloatingLabel controlId="l_role" label="Role" className="mb-3">
                  <Form.Select
                    aria-label="Role"
                    value={flight_surgeon?.role}
                    disabled
                  >
                    <option>Select role</option>
                    <option value={1}>Candidate</option>
                    <option value={2}>Clinician</option>
                    <option value={3}>Flight Surgeon</option>
                    <option value={9}>Admin</option>
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    First name is required
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Col>
            </Row>
            <Row>
              <Form.Group as={Col} lg="7" controlId="validation_phone">
                <FloatingLabel
                  controlId="f_email"
                  label="Email"
                  className="mb-3"
                >
                  <Form.Control
                    type="email"
                    placeholder="Email"
                    required
                    defaultValue={flight_surgeon?.email ?? ""}
                    disabled={flight_surgeon?.user_id !== undefined}
                    isInvalid={
                      dataValidated === false && !flight_surgeon?.email
                    }
                    onChange={(e) => {
                      onUpdateSurgeonData("email", e.target.value);
                    }}
                  />
                  <Form.Control.Feedback type="invalid">
                    Email is required
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} lg="5" controlId="validation_phone">
                <FloatingLabel
                  controlId="f_phone"
                  label="Phone"
                  className="mb-3"
                >
                  <Form.Control
                    placeholder="Phone"
                    required
                    defaultValue={flight_surgeon?.phone ?? ""}
                    isInvalid={
                      dataValidated === false && !flight_surgeon?.phone
                    }
                    onChange={(e) => {
                      onUpdateSurgeonData("phone", e.target.value);
                    }}
                  />
                  <Form.Control.Feedback type="invalid">
                    Phone is required
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Form.Group>
            </Row>
          </Form>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        {status === ApiStatus.BUSY ? (
          <>
            <Spinner animation="border" />
          </>
        ) : (
          <>
            <Button variant="primary" onClick={() => setShow(false)}>
              Cancel
            </Button>
            <Button variant="success" onClick={() => onClickSubmit()}>
              {flight_surgeon?.user_id ? <>Update</> : <>Add</>}
            </Button>
          </>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default FlifghtSurgeonEditModal;
