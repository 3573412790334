import React from "react";
import { Container } from "react-bootstrap";
import CandidateDashboard from "./CandidateDashboard";
import StepGeneral from "./StepGeneral";
import { MemberContextProvider, memberReducer } from "../../contexts/MemberContext";
import StepDemographics from "./StepDemographics";
import StepMedication from "./StepMedication";
import StepMedicalHistory from "./StepMedicalHIstory";
import SubmitApplication from "./SubmitApplication";
import StepMedicalVisits from "./StepMedicalVisits";
import StepViewApplication from "./StepViewApplication";
import StepExtremeEnvironment from "./StepExtremeEnvironment";
import StepFollowUp from "./StepFollowUp";
import StepExerciseSuitability from "./StepExerciseSuitability";

const CandidatePage: React.FC = () => {
  const [state, dispatcher] = React.useReducer(memberReducer, {
    step: 0,
  });

  return (
    <MemberContextProvider state={state} dispatcher={dispatcher}>
      <Container fluid>
        {state.step === 0 && <CandidateDashboard />}
        {state.step === 1 && <StepGeneral />}
        {state.step === 2 && <StepDemographics />}
        {state.step === 3 && <StepMedication />}
        {state.step === 4 && <StepMedicalHistory />}
        {state.step === 5 && <StepMedicalVisits />}
        {state.step === 6 && <StepExerciseSuitability />}
        {state.step === 7 && <StepExtremeEnvironment />}
        {state.step === 8 && <SubmitApplication />}

        {state.step === 31 && <StepViewApplication />}

        {state.step === 21 && <StepFollowUp />}
      </Container>
    </MemberContextProvider>
  );
};

export default CandidatePage;
