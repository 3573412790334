import React, { useState } from "react";
import { v4 as uuid } from "uuid";
import { Button, Col, Container, Row } from "react-bootstrap";
import { ApiStatus } from "../../model/constant_data";
import {
  AIStatusEnum,
  AIStatusItem,
  AssessmentResult,
  CandidateAnswer,
  FollowUpQAResponse,
  FollowUpQAStatusEnum,
  MedeicalCategoryEnum,
  OfwodItem,
  OvewviewCostItem,
  OvewviewSuitabilityItem,
  ReviewFollowupActionResponse,
  ReviewOfwodResponse,
  ReviewSummaryResponse,
  ReviewWaiverResponse,
  UpdateAIStatusRequest,
  WaiverItem,
} from "../../client";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowsSpin, faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import { Tooltip } from "react-tooltip";
import { faCircleCheck } from "@fortawesome/free-regular-svg-icons";
import { useFlightSurgeonContext } from "../../contexts/FlightSurgeonContext";
import ConfirmModal from "../../component/ConfirmModal";
import { useAuthContext } from "../../contexts/AuthContext";
import apiClient, { aiClient } from "../../api/api_client";
import { AIMedicalReportResponse, AIOFWODModel, convertApplicationDetailsToAIRequest } from "../../model/ai_model";
import { ASSESSMENT_CATEGORY_LIST } from "../../model/constant";
import InfoModal from "../../component/InfoModal";

export interface AIMenuSectionProps {
  aiStatus: ApiStatus;
  setAiStatus: (status: ApiStatus) => void;
}

export const AIMenuSection: React.FC<AIMenuSectionProps> = ({ aiStatus, setAiStatus }) => {
  const {
    state: { user },
  } = useAuthContext();

  const {
    state: { candidate, question_from, medical_report, assessment_result, application_details, followup_qa_list },
    dispatcher,
  } = useFlightSurgeonContext();

  const [showAIConfirm, setShowAIConfirm] = useState<boolean>(false);
  const [useReportFlg, setUseReportFlg] = useState<boolean>(false);
  const [showAIResult, setShowAIResult] = useState<boolean>(false);

  const sleep = (ms: number) =>
    new Promise((resolve) => {
      setTimeout(resolve, ms);
    });

  const getReportCategory = (category: string | null | undefined): string => {
    const cat = category && ASSESSMENT_CATEGORY_LIST.includes(category) ? category : MedeicalCategoryEnum.UNCLASSIFIED;
    return cat;
  };

  const onTriggerAI = () => {
    void (async () => {
      setAiStatus(ApiStatus.BUSY);
      dispatcher({
        type: "SET_MEDICAL_REPORT",
        payload: undefined,
      });

      if (user && question_from && candidate?.application_id) {
        await apiClient.deleteMedicalReportViaAI(candidate.user_id, candidate.application_id);

        // const all_questions = flatFormQuestions();
        const request_data = convertApplicationDetailsToAIRequest(
          candidate.application_id,
          false,
          question_from,
          application_details?.demographics,
          application_details?.medication,
          application_details?.medical_visit,
          application_details?.medical_history,
          application_details?.extreme_qa_list,
          followup_qa_list
        );
        aiClient.setAuthToken(apiClient.getAuthToken());
        await aiClient.generateMedicalReportViaAI(request_data);

        // Update AI running status
        await apiClient.updateApplicationAIStatus(candidate.user_id, candidate.application_id, {
          ai_status: {
            status: AIStatusEnum.PROCESSING,
            author: user.user_id,
            timestamp: new Date().toISOString(),
          } as AIStatusItem,
        } as UpdateAIStatusRequest);

        // Query medical report every 1 second
        await sleep(20000);
        let hasReport = false;
        while (hasReport === false) {
          await sleep(1000);
          const res = await apiClient.getMedicalReportViaAI(candidate.user_id, candidate.application_id);
          if (res) {
            hasReport = true;
            const ai_report = res.medical_report as AIMedicalReportResponse;
            dispatcher({
              type: "SET_MEDICAL_REPORT",
              payload: ai_report,
            });

            // Update AI running status
            await apiClient.updateApplicationAIStatus(candidate.user_id, candidate.application_id, {
              ai_status: {
                status: AIStatusEnum.DONE,
                author: user.user_id,
                timestamp: new Date().toISOString(),
              } as AIStatusItem,
            } as UpdateAIStatusRequest);
          }
        }
      }
      setAiStatus(ApiStatus.IDEL);
    })();
  };

  const onUseAIResult = () => {
    if (medical_report && candidate) {
      const notes = medical_report.medical_report.follow_up_questions.map((val) => {
        return {
          uid: uuid(),
          application_id: candidate.application_id,
          qa_status: FollowUpQAStatusEnum.DRAFT,
          doctor_question: {
            question: val.question,
            context_to_candidate: val.context,
          },
          doctor_notes: val.clinical_notes,
          candidate_answer: {
            answer: "",
          } as CandidateAnswer,
        } as FollowUpQAResponse;
      });
      dispatcher({
        type: "SET_FOLLOWUP_QA_LIST",
        payload: notes,
      });

      const waivers = medical_report.medical_report.waivers.map((val) => {
        const medications = val.medications?.map((item) => `${item.name}, ${item.dosage}.`);
        if (medications) {
          const m_waivers = medications.map((item) => {
            return {
              uid: uuid(),
              application_id: candidate.application_id,
              waiver: {
                section: val.section,
                category: getReportCategory(val.category),
                title: val.title,
                contents: [val.contents, val.opinion, item].filter((item) => item).join(",\n"),
              } as WaiverItem,
            } as ReviewWaiverResponse;
          });
          return m_waivers;
        }
        return {
          uid: uuid(),
          application_id: candidate.application_id,
          waiver: {
            section: val.section,
            category: getReportCategory(val.category),
            title: val.title,
            contents: [val.contents, val.opinion].filter((item) => item).join(",\n"),
          } as WaiverItem,
        } as ReviewWaiverResponse;
      });
      dispatcher({
        type: "SET_WAIVERS_LIST",
        payload: waivers.flat(),
      });

      const ofwods = medical_report.medical_report.OFWODs.map((val) => {
        const ofwod = val as AIOFWODModel;
        return {
          uid: uuid(),
          application_id: candidate.application_id,
          ofwod: {
            section: ofwod.section || "",
            category: getReportCategory(ofwod.category),
            title: ofwod.title || "",
            contents: val instanceof Object ? [ofwod.contents, ofwod.opinion].filter((item) => item).join(",\n") : val,
          } as OfwodItem,
        } as ReviewOfwodResponse;
      });
      dispatcher({
        type: "SET_OFWOD_LIST",
        payload: ofwods,
      });

      const summary = {
        uid: uuid(),
        application_id: candidate.application_id,
        summary: {
          contents: medical_report?.medical_report.summary.map((v, i) => `${i + 1}. ${v}`).join("\n") || "",
        },
      } as ReviewSummaryResponse;
      dispatcher({
        type: "SET_REVIEW_SUMMARY",
        payload: summary,
      });

      const actions = medical_report.medical_report.follow_up_actions.map((item) => {
        return {
          uid: uuid(),
          application_id: candidate.application_id,
          action: {
            contents: item,
          },
        } as ReviewFollowupActionResponse;
      });
      dispatcher({
        type: "SET_FOLLOWUP_ACTIONS",
        payload: actions,
      });

      const assessment = {
        ...assessment_result,
        suitability: {
          value: medical_report?.medical_report.suitability
            ? Number(medical_report?.medical_report.suitability?.value)
            : null,
          comment: medical_report?.medical_report.suitability?.comment || "",
        } as OvewviewSuitabilityItem,
        quality: medical_report?.medical_report.quality ? Number(medical_report?.medical_report.quality?.value) : null,
        cost: {
          hours: medical_report?.medical_report.cost ? Number(medical_report?.medical_report.cost?.time) : null,
          usd: medical_report?.medical_report.cost ? Number(medical_report?.medical_report.cost?.USD) : null,
          comment: medical_report?.medical_report.cost?.comment || "",
        } as OvewviewCostItem,
      } as AssessmentResult;
      dispatcher({
        type: "SET_ASSESSEMENT_RESULT",
        payload: assessment,
      });
    }
  };

  return (
    <Container className="mt-4 border rounded shadow bg-light" fluid>
      <Row className="py-3">
        <Col></Col>
        <Col lg="auto">
          <Button
            variant="success"
            size="sm"
            className="shadow"
            style={{ minWidth: "12rem" }}
            onClick={(e) => {
              e.stopPropagation();
              setShowAIConfirm(true);
            }}
            disabled={aiStatus === ApiStatus.BUSY}
          >
            {aiStatus === ApiStatus.BUSY ||
              (candidate?.overview_status?.ai_status?.status === AIStatusEnum.PROCESSING && (
                <>
                  <FontAwesomeIcon
                    icon={faArrowsSpin}
                    className="me-1 fa-lg"
                    cursor={"pointer"}
                    color={"darkorange"}
                    spin
                    id="ai_in_process"
                  />
                  <Tooltip anchorSelect="#ai_in_process" variant="warning" style={{ borderRadius: "6px" }}>
                    AI revierw in process.
                  </Tooltip>
                </>
              ))}
            {candidate?.overview_status?.ai_status?.status === AIStatusEnum.DONE && (
              <>
                <FontAwesomeIcon
                  icon={faCircleCheck}
                  className="me-1 fa-lg"
                  cursor={"pointer"}
                  color={"white"}
                  id="ai_in_done"
                />
                <Tooltip anchorSelect="#ai_in_done" variant="success" style={{ borderRadius: "6px" }}>
                  AI revierw has been processed.
                </Tooltip>
              </>
            )}
            {candidate?.overview_status?.ai_status?.status === AIStatusEnum.ERROR && (
              <>
                <FontAwesomeIcon
                  icon={faTriangleExclamation}
                  className="me-1 fa-lg"
                  cursor={"pointer"}
                  color={"red"}
                  id="ai_in_error"
                />
                <Tooltip anchorSelect="#ai_in_error" variant="error" style={{ borderRadius: "6px" }}>
                  Unexpectted error occurred during AI review. Please re-run the AI again. If the problem persists,
                  please contact the support.
                </Tooltip>
              </>
            )}
            Run AI for medical report
          </Button>
        </Col>
        <Col lg="auto">
          <Button
            variant={medical_report === undefined || aiStatus === ApiStatus.BUSY ? "secondary" : "success"}
            size="sm"
            className="shadow"
            style={{ minWidth: "12rem" }}
            onClick={(e) => {
              e.stopPropagation();
              setShowAIResult(true);
            }}
            disabled={medical_report === undefined || aiStatus === ApiStatus.BUSY}
          >
            Preview AI generated result
          </Button>
        </Col>
        <Col lg="auto">
          <Button
            variant={medical_report === undefined || aiStatus === ApiStatus.BUSY ? "secondary" : "success"}
            size="sm"
            className="shadow"
            style={{ minWidth: "12rem" }}
            onClick={(e) => {
              e.stopPropagation();
              setUseReportFlg(true);
            }}
            disabled={medical_report === undefined || aiStatus === ApiStatus.BUSY}
          >
            Use AI generated result
          </Button>
        </Col>
      </Row>

      <ConfirmModal
        show={showAIConfirm}
        setShow={setShowAIConfirm}
        doContinue={() => {
          onTriggerAI();
        }}
      >
        <span>AI may take few minutes to generate medical report. Are you sure to continue?</span>
      </ConfirmModal>

      <ConfirmModal
        show={useReportFlg}
        setShow={setUseReportFlg}
        doContinue={() => {
          onUseAIResult();
        }}
      >
        <p>
          AI generated medical report will overwrite existing candidate notes, waivers and OFWODs. Are you sure to
          Continue?
        </p>
      </ConfirmModal>

      <InfoModal title="AI Generated Medical Report" show={showAIResult} setShow={setShowAIResult}>
        <div className="overflow-y-auto" style={{ maxHeight: "70vh" }}>
          <pre>{JSON.stringify(medical_report, null, 2)}</pre>
        </div>
      </InfoModal>
    </Container>
  );
};
