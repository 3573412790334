import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { FileUploader } from "react-drag-drop-files";

export interface UploadMemberModalProps {
  cohortId: number;
  show: boolean;
  setShow: (val: boolean) => void;
}

const UploadMemberModal: React.FC<UploadMemberModalProps> = ({
  cohortId,
  show,
  setShow,
}) => {
  const [file, setFile] = useState<File | undefined>(undefined);

  const onHandleUpload = (f: File) => {
    setFile(f);
    if (file) {
      console.log("==>", cohortId, file.name);
    }
  };

  return (
    <Modal size="xl" centered show={show}>
      <Modal.Header closeButton onHide={() => setShow(false)}>
        <Modal.Title>Upload Cohort Member File</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FileUploader
          multiple={false}
          handleChange={onHandleUpload}
          name="file"
          types={["csv"]}
          classes="uploader"
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={() => setShow(false)} disabled>
          Upload
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default UploadMemberModal;
