import React, { useEffect, useState } from "react";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import "./CohortCandidateReivew.css";
import { useFlightSurgeonContext } from "../../contexts/FlightSurgeonContext";
import { useAuthContext } from "../../contexts/AuthContext";
import apiClient from "../../api/api_client";
import ViewCandidateGeneral from "./ViewCandidateGeneral";
import { AIMedicalReportResponse } from "../../model/ai_model";
import { FollowupActionSection } from "./FollowupActionSection";
import { ReviewSummarySection } from "./ReviewSummarySection";
import ViewAssessmentSummary from "./ViewAssessmentSummary";
import { ApiStatus } from "../../model/constant_data";
import { WaiversSection } from "./WaiversSection";
import { OfwodsSection } from "./OfwodsSection";
import { TopMenuSection } from "./TopMenuSection";
import { ApplicationTabs } from "./ApplicationTabs";
import { AIMenuSection } from "./AIMenuSection";
import { SurgeonNotesSection } from "./SurgeonNotesSection";

const CohortCandidateReivew: React.FC = () => {
  const {
    state: { user },
  } = useAuthContext();

  const {
    state: { candidate },
    dispatcher,
  } = useFlightSurgeonContext();

  const [initStatus, setInitStatus] = useState<ApiStatus>(ApiStatus.IDEL);
  const [aiStatus, setAiStatus] = useState<ApiStatus>(ApiStatus.IDEL);

  const queryFollowUpQAList = async () => {
    if (user && candidate && candidate.application_id) {
      const qa_list = await apiClient.getApplicationFollowUpQA(candidate.user_id, candidate.application_id);
      dispatcher({
        type: "SET_FOLLOWUP_QA_LIST",
        payload: qa_list,
      });
    }
  };

  const queryWaivers = async () => {
    if (user && candidate && candidate.application_id) {
      const waivers = await apiClient.getReviewWaivers(candidate.user_id, candidate.application_id);
      dispatcher({
        type: "SET_WAIVERS_LIST",
        payload: waivers,
      });
    }
  };

  const queryOfwods = async () => {
    if (user && candidate && candidate.application_id) {
      const ofwods = await apiClient.getReviewOfwods(candidate.user_id, candidate.application_id);
      dispatcher({
        type: "SET_OFWOD_LIST",
        payload: ofwods,
      });
    }
  };

  const queryReviewSummary = async () => {
    if (user && candidate && candidate.application_id) {
      const data = await apiClient.getMedicalReviewSummary(candidate.user_id, candidate.application_id);
      dispatcher({
        type: "SET_REVIEW_SUMMARY",
        payload: data || undefined,
      });
    }
  };

  const queryAIMedicalReport = async () => {
    if (user && candidate && candidate.application_id) {
      const res = await apiClient.getMedicalReportViaAI(candidate.user_id, candidate.application_id);
      if (res) {
        const ai_report = res.medical_report as AIMedicalReportResponse;
        dispatcher({
          type: "SET_MEDICAL_REPORT",
          payload: ai_report,
        });
      }
    }
  };

  const queryFollowupActions = async () => {
    if (user && candidate && candidate.application_id) {
      const actions = await apiClient.getMedicalReviewFollowupAction(candidate.user_id, candidate.application_id);
      dispatcher({
        type: "SET_FOLLOWUP_ACTIONS",
        payload: actions,
      });
    }
  };

  const querySurgeonNotes = async () => {
    if (user && candidate && candidate.application_id) {
      const notes = await apiClient.queryApplicationSurgeonNotes(candidate.user_id, candidate.application_id);
      dispatcher({
        type: "SET_SURGEON_NOTES",
        payload: notes || undefined,
      });
    }
  };

  // Query candidate details at the first time
  useEffect(() => {
    void (async (): Promise<void> => {
      setInitStatus(ApiStatus.BUSY);
      if (user && candidate && candidate.application_id) {
        const details = await apiClient.getUserApplicationDetails(candidate.user_id, candidate.application_id);
        dispatcher({
          type: "SET_APPLICATION_DETAILS",
          payload: details,
        });

        if (candidate.form_id) {
          const res = await apiClient.getQuestionForm(candidate.form_id);
          dispatcher({
            type: "SET_QUESTION_FORM",
            payload: res,
          });
        }

        dispatcher({
          type: "SET_ASSESSEMENT_RESULT",
          payload: candidate.assessment || undefined,
        });

        await queryFollowUpQAList();
        await queryWaivers();
        await queryOfwods();
        await queryReviewSummary();
        await queryAIMedicalReport();
        await queryFollowupActions();
        await querySurgeonNotes();
      }
      setInitStatus(ApiStatus.IDEL);
    })();
  }, [user]);

  return (
    <Container className="cohort_review_page pb-2" fluid>
      {initStatus === ApiStatus.BUSY ? (
        <Spinner animation="border" className="mt-3" variant="warning" />
      ) : (
        <>
          <Container className="cohort_review_menu" fluid>
            <TopMenuSection />
          </Container>

          <Container className="cohort_review_body rounded mt-3 mb-2" fluid>
            <Row className="cohort_review_panels">
              <Col lg={8} className="cohort_review_panle_left border-end">
                <ViewCandidateGeneral />
                <ApplicationTabs />
                <AIMenuSection aiStatus={aiStatus} setAiStatus={setAiStatus} />

                {aiStatus === ApiStatus.BUSY ? (
                  <Spinner animation="border" className="mt-3" variant="warning" />
                ) : (
                  <>
                    <ViewAssessmentSummary />
                    <ReviewSummarySection />
                    <FollowupActionSection />
                    <WaiversSection />
                    <OfwodsSection />
                  </>
                )}
              </Col>
              <Col lg={4} className="cohort_review_panel_right">
                <SurgeonNotesSection />
              </Col>
            </Row>
          </Container>
        </>
      )}
    </Container>
  );
};

export default CohortCandidateReivew;
