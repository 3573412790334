import Papa from "papaparse";
import React, { useState } from "react";
import { Alert, Col, Container, Row, Spinner } from "react-bootstrap";
import { FileUploader } from "react-drag-drop-files";
// import {
//   CreateExplanationRequest,
//   CreateFormRequest,
//   CreateGroupRequest,
//   CreateQuestionRequest,
// } from "../../model/api_model";
// import { OptionItem } from "../../model/question";
import { ApiStatus, getExplanationCategory, getQuestionCategory } from "../../model/constant_data";
import apiClient from "../../api/api_client";

import "./SystemAdminPage.css";
import {
  CreateExplanationRequest,
  CreateFormRequest,
  CreateGroupRequest,
  CreateQuestionRequest,
  OptionItem,
} from "../../client";

interface CsvRow {
  group_name: string | undefined;
  question_id: number;
  question_category: string;
  question: string;
  question_option_label: string;
  question_option_value: number;
  question_option_comment: boolean;
  explanation_trigger: boolean;
  explanation_category: string;
  explanation_question: string;
  explanation_option_label: string;
  explanation_option_value: number;
  explanation_option_comment: boolean;
}

function convertToQuestion(csvData: CsvRow[], group_name: string | undefined): CreateQuestionRequest[] {
  const questions = csvData.filter((item) => item.group_name === group_name);
  const question_names = [...new Set(questions.map((item) => item.question))];

  return question_names.map((q_name) => {
    const q_1 = questions.find((item) => item.question === q_name);

    const q_options = questions.filter((item) => item.question === q_name);
    let question_option: OptionItem[] = [];
    let explanations: CreateExplanationRequest[] = [];

    if (q_options.length > 1) {
      if (q_options[0].question_option_label !== null) {
        // Question option
        question_option = q_options.map((opt) => {
          return {
            label: opt.question_option_label,
            value: opt.question_option_value.toString(),
            comment: opt.question_option_comment,
          } as OptionItem;
        });
      }
      if (q_options[0].explanation_question !== null) {
        // Explanation option
        const exp_questions = [...new Set(q_options.map((item) => item.explanation_question))];
        explanations = exp_questions.map((exp_question) => {
          const explanation = q_options.find((item) => item.explanation_question === exp_question);
          const exp_options = q_options.filter(
            (item) => item.explanation_question === exp_question && item.explanation_option_value !== null
          );

          return {
            category: getExplanationCategory(explanation?.explanation_category),
            question: exp_question,
            trigger_value: explanation?.explanation_trigger,
            option_list: exp_options.map((opt) => {
              return {
                label: opt.explanation_option_label,
                value: opt.explanation_option_value.toString(),
                comment: opt.explanation_option_comment,
              } as OptionItem;
            }),
          } as CreateExplanationRequest;
        });
      }
    }

    return {
      display_index: q_1?.question_id,
      category: getQuestionCategory(q_1?.question_category || ""),
      question: q_1?.question,
      description: "",
      option_list: question_option,
      explanation_list: explanations,
    } as CreateQuestionRequest;
  });
}

function coverRowToRequest(csvData: CsvRow[]): CreateFormRequest {
  const group_names = [...new Set(csvData.map((item) => item.group_name))];

  return {
    group_list: group_names.map((g_name, g_idx) => {
      return {
        display_index: g_idx,
        group_title: g_name,
        group_description: "",
        question_list: convertToQuestion(csvData, g_name),
      } as CreateGroupRequest;
    }),
  } as CreateFormRequest;
}

export const SystemAdminPage: React.FC = () => {
  const [file, setFile] = useState<File | undefined>(undefined);
  const [status, setStatus] = useState<ApiStatus>(ApiStatus.IDEL);

  const handleChange = (file: File) => {
    setFile(file);

    if (file) {
      Papa.parse<CsvRow, File>(file, {
        header: true,
        skipEmptyLines: true,
        transformHeader: (h: string) => {
          return h.replaceAll(" ", "_").toLocaleLowerCase();
        },
        dynamicTyping: true,
        complete: function (results) {
          const reqData = coverRowToRequest(results.data);
          void (async () => {
            setStatus(ApiStatus.BUSY);
            await apiClient.postQuestionForm(reqData);

            setStatus(ApiStatus.DONE);
            setFile(undefined);
          })();
        },
      });
    }
  };

  return (
    <Container style={{ backgroundColor: "white", minHeight: "100vh" }}>
      <Row>
        <Col className="m-3">
          <h2>Upload Question Form:</h2>
          <FileUploader
            multiple={false}
            handleChange={handleChange}
            name="file"
            types={["CSV"]}
            disabled={file !== undefined}
            classes="uploader w-100 d-flex align-content-center "
          >
            <Container className="d-flex align-content-center">
              <Row className="d-flex align-content-center">
                <Col lg={12}>
                  {status === ApiStatus.BUSY ? (
                    <>
                      <Spinner animation="border" className="mt-3" variant="warning" />
                      <span className="m-3">Uploading...</span>
                    </>
                  ) : (
                    <>
                      {status === ApiStatus.DONE && (
                        <Alert variant="success">New question form has been uploaded.</Alert>
                      )}
                      <p>Please drop or upload question form CSV file here</p>
                    </>
                  )}
                </Col>
              </Row>
            </Container>
          </FileUploader>
        </Col>
      </Row>
    </Container>
  );
};

export default SystemAdminPage;
