import React from "react";
import { v4 as uuid } from "uuid";
import { Accordion, Col, Container, ListGroup, Row } from "react-bootstrap";
import { useFlightSurgeonContext } from "../../contexts/FlightSurgeonContext";
import { ReviewWaiverResponse } from "../../client";
import WaiverEditor from "./WaiverEditor";
import medVolList from "../../model/all_med_vol.json";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";

export const WaiversSection: React.FC = () => {
  const {
    state: { candidate, waiver_list },
    dispatcher,
  } = useFlightSurgeonContext();

  const onClickAddWaiver = () => {
    if (candidate?.application_id) {
      dispatcher({
        type: "ADD_WAIVER",
        payload: {
          uid: uuid(),
          application_id: candidate?.application_id,
          waiver: { section: "", title: "", contents: "" },
        } as ReviewWaiverResponse,
      });
    }
  };

  return (
    <>
      <Accordion defaultActiveKey={"followup_action"} className="shadow mt-4">
        <Accordion.Item eventKey={"followup_action"}>
          <Accordion.Header>
            <Container fluid>
              <Row>
                <Col lg={12} className="d-flex justify-content-between">
                  <h5 className="my-0">Waivers</h5>
                  <FontAwesomeIcon
                    className="float-end my-0 me-3"
                    size="lg"
                    color="red"
                    onClick={(e) => {
                      e.stopPropagation();
                      onClickAddWaiver();
                    }}
                    icon={faPenToSquare}
                    title="Add Waiver"
                  />
                </Col>
              </Row>
            </Container>
          </Accordion.Header>
          <Accordion.Body className="p-0">
            <ListGroup as="ol">
              {waiver_list?.map((item, idx) => {
                return <WaiverEditor idx={idx} data={item} key={item.uid} medVolList={medVolList} />;
              })}
            </ListGroup>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </>
  );
};
