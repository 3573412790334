import React, { useEffect, useState } from "react";
import { Col, Container, Form, Row, Spinner } from "react-bootstrap";

import apiClient from "../../api/api_client";
import { useFlightSurgeonContext } from "../../contexts/FlightSurgeonContext";
import CohortMembers from "./CohortMembers";
import { ApiStatus } from "../../model/constant_data";

const FlightSurgeonDashboard: React.FC = () => {
  const {
    state: { cohort_list, cohort, candidate_list },
    dispatcher,
  } = useFlightSurgeonContext();

  const [initStatus, setInitStatus] = useState<ApiStatus>(ApiStatus.IDEL);
  const [tblStatus, setTblStatus] = useState<ApiStatus>(ApiStatus.IDEL);

  useEffect(() => {
    void (async (): Promise<void> => {
      if (cohort_list === undefined) {
        setInitStatus(ApiStatus.BUSY);
        const res = await apiClient.getALLCohorts();
        dispatcher({
          type: "SET_COHORT_LIST",
          payload: res,
        });
        setInitStatus(ApiStatus.IDEL);
      }

      // Fresh list when back the dashboard
      if (cohort) {
        const res = await apiClient.getCohortCandidateApplications(cohort.cohort_id);
        dispatcher({
          type: "SET_CANDIDATE_LIST",
          payload: res,
        });
      }
    })();
  }, []);

  const onChangeCohort = (event: React.ChangeEvent<HTMLSelectElement>) => {
    void (async (): Promise<void> => {
      setTblStatus(ApiStatus.BUSY);
      dispatcher({
        type: "SET_CANDIDATE_LIST",
        payload: undefined,
      });
      const target = cohort_list?.find((item) => item.cohort_id === event.target.value);
      if (target) {
        dispatcher({
          type: "SET_COHORT",
          payload: target,
        });

        const res = await apiClient.getCohortCandidateApplications(target.cohort_id);
        dispatcher({
          type: "SET_CANDIDATE_LIST",
          payload: res,
        });
      }
      setTblStatus(ApiStatus.IDEL);
    })();
  };

  return (
    <Container fluid>
      {initStatus === ApiStatus.BUSY && cohort_list === undefined && (
        <Spinner animation="border" className="mt-3" variant="warning" />
      )}
      <Container fluid className="border shadow rounded mt-3 bg-light">
        {cohort_list && (
          <Row>
            <Form.Group as={Col} lg={4} className="my-3" controlId="cohortName">
              <Form.Label>Available Cohorts:</Form.Label>
              <Form.Select onChange={onChangeCohort} defaultValue={cohort?.cohort_id} aria-label="Select target cohort">
                <option>Please select cohort</option>
                {cohort_list?.map((item) => {
                  return (
                    <option value={item.cohort_id} key={item.cohort_id}>
                      {item.cohort_name}
                    </option>
                  );
                })}
              </Form.Select>
            </Form.Group>
            <Form.Group as={Col} lg={4} className="my-3" controlId="cohortMission">
              <Form.Label>Mission Type:</Form.Label>
              <Form.Control type="text" value={cohort?.properties.mission_type || "-"} readOnly disabled />
            </Form.Group>
          </Row>
        )}
      </Container>

      {cohort && (
        <>
          {tblStatus === ApiStatus.BUSY && <Spinner animation="border" className="mt-3" variant="warning" />}
          {candidate_list && <CohortMembers />}
        </>
      )}
    </Container>
  );
};

export default FlightSurgeonDashboard;
