import React, { useState } from "react";
import { v4 as uuid } from "uuid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock, faTrashCan } from "@fortawesome/free-regular-svg-icons";
import { Badge, Button, Col, Container, FloatingLabel, Form, ListGroup, Modal, Row, Spinner } from "react-bootstrap";
import ConfirmModal from "../../component/ConfirmModal";
import { useFlightSurgeonContext } from "../../contexts/FlightSurgeonContext";
import { useAuthContext } from "../../contexts/AuthContext";
import { DoctorQuestion, FollowUpQAResponse, FollowUpQARequest, FollowUpQAStatusEnum } from "../../client";
import { ApiStatus } from "../../model/constant_data";
import { faLock } from "@fortawesome/free-solid-svg-icons";
import "../../theme/amplify.scss";

const FollowupQaSection: React.FC = () => {
  const {
    state: { user },
  } = useAuthContext();
  const {
    state: { candidate, followup_qa_list },
    dispatcher,
  } = useFlightSurgeonContext();

  const [showNewDlg, setShowNewDlg] = useState<boolean>(false);
  const [showConfirm, setShowConfirm] = useState<boolean>(false);
  const [targetUid, setTargetUid] = useState<string | undefined>(undefined);
  const [validated, setValidated] = useState<boolean | undefined>(undefined);

  const [qaItem, setQaItem] = useState<FollowUpQARequest | undefined>(undefined);
  const [addStatus, setAddStatus] = useState<ApiStatus>(ApiStatus.IDEL);

  const onClickAddNotes = () => {
    if (candidate?.application_id) {
      setQaItem({
        uid: uuid(),
        application_id: candidate?.application_id,
        qa_status: FollowUpQAStatusEnum.DRAFT,
        doctor_question: {} as DoctorQuestion,
      } as FollowUpQARequest);
      setShowNewDlg(true);
    }
  };

  const onAddCandidateNote = () => {
    if (qaItem) {
      if (qaItem.doctor_question?.question && qaItem.doctor_question?.context_to_candidate) {
        void (async (): Promise<void> => {
          setValidated(true);
          setAddStatus(ApiStatus.BUSY);

          if (user?.user_id && candidate?.application_id) {
            dispatcher({
              type: "ADD_FOLLOWUP_QA",
              payload: {
                ...qaItem,
              } as FollowUpQAResponse,
            });
          }
          setShowNewDlg(false);
          setAddStatus(ApiStatus.IDEL);
        })();
      } else {
        setValidated(false);
      }
    }
  };

  return (
    <>
      <ListGroup>
        <ListGroup.Item variant="secondary">
          <Row>
            <Col lg={1}>
              <strong>ID</strong>
            </Col>
            <Col lg={3}>
              <strong>Question</strong>
            </Col>
            <Col lg={2}>
              <strong>Context to Candidate</strong>
            </Col>
            <Col lg={3}>
              <strong>Answer</strong>
            </Col>
            <Col lg={2}>
              <strong>Notes (Doc only)</strong>
            </Col>
            <Col lg={1}>
              <strong>Status</strong>
            </Col>
          </Row>
        </ListGroup.Item>
        {followup_qa_list?.length === 0 && (
          <ListGroup.Item className="p-0">
            <Row className="p-2">
              <Col lg={12}>Followup questions are not available.</Col>
            </Row>
          </ListGroup.Item>
        )}
        {followup_qa_list?.map((data, idx) => {
          return (
            <ListGroup.Item className="p-0 bg-light" key={idx}>
              <Row className="mx-0">
                <Col lg={1} className="py-2">
                  {idx + 1}
                </Col>
                <Col lg={3} className="py-2">
                  {data.doctor_question?.question || ""}
                </Col>
                <Col lg={2} className="py-2">
                  {data.doctor_question?.context_to_candidate || ""}
                </Col>
                <Col lg={3} className="py-2">
                  {data.qa_status === FollowUpQAStatusEnum.ANSWERED ? data.candidate_answer?.answer || "" : "-"}
                </Col>
                <Col lg={2} className="py-2">
                  {data.doctor_notes || ""}
                </Col>
                <Col lg={1} className="py-2 align-middle">
                  {data.qa_status === FollowUpQAStatusEnum.DRAFT && (
                    <>
                      <FontAwesomeIcon
                        icon={faTrashCan}
                        cursor={"pointer"}
                        size={"lg"}
                        color="red"
                        title="Delete follow-up question"
                        className="me-2"
                        onClick={() => {
                          setTargetUid(data.uid);
                          setShowConfirm(true);
                        }}
                      />
                      <Badge bg="info">Draft</Badge>
                    </>
                  )}
                  {data.qa_status === FollowUpQAStatusEnum.ASKED && (
                    <>
                      <FontAwesomeIcon
                        cursor={"pointer"}
                        size={"lg"}
                        icon={faClock}
                        color="orange"
                        title="Waiting for candidate's answer"
                        className="me-2"
                      />
                      <Badge bg="info">Open</Badge>
                    </>
                  )}
                  {data.qa_status === FollowUpQAStatusEnum.ANSWERED && (
                    <>
                      <FontAwesomeIcon
                        cursor={"pointer"}
                        size={"lg"}
                        icon={faLock}
                        color="darkgray"
                        title="This question has been closed"
                        className="me-2"
                      />
                      <Badge bg="info">Closed</Badge>
                    </>
                  )}
                </Col>
              </Row>
            </ListGroup.Item>
          );
        })}
        <ListGroup.Item className="p-0 bg-light">
          <Row className="mx-0">
            <Col lg={12} className="text-end my-3">
              <Button
                variant="outline-primary"
                className="float-end my-0 me-3"
                size="sm"
                onClick={(e) => {
                  e.stopPropagation();
                  onClickAddNotes();
                }}
                style={{ minWidth: "12rem" }}
              >
                Add New Question
              </Button>
            </Col>
          </Row>
        </ListGroup.Item>
      </ListGroup>

      <Modal size="xl" centered show={showNewDlg}>
        <Modal.Header closeButton onHide={() => setShowNewDlg(false)}>
          <Modal.Title>Add Question Notes</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container className="mt-3 border rounded bg-light">
            <Form>
              <Row className="mt-3">
                <Col lg={6}>
                  <FloatingLabel label="Question" className="mb-3">
                    <Form.Control
                      as="textarea"
                      style={{ height: "100px" }}
                      required
                      isInvalid={validated === false && !qaItem?.doctor_question?.question}
                      onChange={(e) => {
                        setQaItem({
                          ...qaItem,
                          doctor_question: {
                            ...qaItem?.doctor_question,
                            question: e.target.value,
                          },
                        } as FollowUpQARequest);
                      }}
                    />
                    <Form.Control.Feedback type="invalid">Question is required</Form.Control.Feedback>
                  </FloatingLabel>
                </Col>
                <Col lg={6}>
                  <FloatingLabel label="Context to Candidate" className="mb-3">
                    <Form.Control
                      as="textarea"
                      style={{ height: "100px" }}
                      required
                      isInvalid={validated === false && !qaItem?.doctor_question?.context_to_candidate}
                      onChange={(e) => {
                        setQaItem({
                          ...qaItem,
                          doctor_question: {
                            ...qaItem?.doctor_question,
                            context_to_candidate: e.target.value,
                          },
                        } as FollowUpQARequest);
                      }}
                    />
                    <Form.Control.Feedback type="invalid">Context to Candidate is required</Form.Control.Feedback>
                  </FloatingLabel>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FloatingLabel label="Doctor Notes" className="mb-3">
                    <Form.Control
                      as="textarea"
                      style={{ height: "100px" }}
                      onChange={(e) => {
                        setQaItem({
                          ...qaItem,
                          doctor_notes: e.target.value,
                        } as FollowUpQARequest);
                      }}
                    />
                  </FloatingLabel>
                </Col>
              </Row>
            </Form>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => setShowNewDlg(false)}>
            Cancel
          </Button>
          <Button variant="success" onClick={onAddCandidateNote} disabled={addStatus === ApiStatus.BUSY}>
            {addStatus === ApiStatus.BUSY && <Spinner animation="border" size="sm" className="me-2" />}
            Add
          </Button>
        </Modal.Footer>
      </Modal>

      <ConfirmModal
        show={showConfirm}
        setShow={setShowConfirm}
        doContinue={() => {
          if (targetUid) {
            dispatcher({
              type: "DELETE_FOLLOWUP_QA",
              payload: targetUid,
            });
          }
        }}
      >
        <span>Are you sure to delete the question notes?</span>
      </ConfirmModal>
      {/* </Container> */}
    </>
  );
};

export default FollowupQaSection;
