import React, { useEffect } from "react";

import { useManagerContext } from "../../contexts/ManagerContext";
import apiClient from "../../api/api_client";
import CohortSection from "./CohortSection";
import FlightSurgeonSection from "./FlightSurgeonSection";

const ManagerDashboard: React.FC = () => {
  const {
    state: { surgeon_list },
    dispatcher,
  } = useManagerContext();

  useEffect(() => {
    if (surgeon_list === undefined) {
      void (async (): Promise<void> => {
        const surgeons = await apiClient.getAllFlightSurgeons();
        dispatcher({
          type: "SET_SURGEON_LIST",
          payload: surgeons,
        });
      })();
    }
  }, [dispatcher, surgeon_list]);

  useEffect(() => {
    void (async (): Promise<void> => {
      const cohorts = await apiClient.getCohortListForAdmin();
      dispatcher({
        type: "SET_COHORT_LIST",
        payload: cohorts,
      });
    })();
  }, [dispatcher]);

  return (
    <>
      <FlightSurgeonSection />
      <CohortSection />
    </>
  );
};

export default ManagerDashboard;
