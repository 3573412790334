import React from "react";
import AuthContainer from "./AuthContainer";
import CandidatePage from "./candidate/CandidatePage";
import ManagerPages from "./manager/ManagerPages";
import { useAuthContext } from "../contexts/AuthContext";
import { getUserRoleName } from "../model/constant";
import { NoAccessPage } from "./NoAccessPage";
import SystemAdminPage from "./system/SystemAdminPage";
import FlightSurgeonPage from "./flight_surgeon/FlightSurgeonPage";
import { UserRoleEnum } from "../client";

const DashboardSwitchPage: React.FC = () => {
  const {
    state: { user, error },
  } = useAuthContext();

  return (
    <>
      {error !== undefined ? (
        <NoAccessPage />
      ) : (
        <AuthContainer>
          {user?.role === UserRoleEnum._1 && <CandidatePage />}
          {user?.role === UserRoleEnum._2 && <FlightSurgeonPage />}
          {user?.role === UserRoleEnum._8 && <ManagerPages />}
          {user?.role === UserRoleEnum._9 && <SystemAdminPage />}

          {user?.role !== undefined && getUserRoleName(user.role) === "Unknown" && <NoAccessPage />}
        </AuthContainer>
      )}
    </>
  );
};

export default DashboardSwitchPage;
