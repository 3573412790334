import React, { Fragment, useState } from "react";
import { faSquareMinus, faSquarePlus } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Form, ListGroup, Row } from "react-bootstrap";
import { yes_no_list } from "../../model/constant";
import { v4 as uuid } from "uuid";
import { ExplanationAnswerModel, MedicalHistoryAnswerModel, QuestionTypeEnum } from "../../client";
import { useMemberContext } from "../../contexts/MemberContext";
import ExplanationItem from "./ExplanationItem";

export interface QuestionProps {
  group_id: number;
  question_id: number;
  collapsed?: boolean;
  updateHistoryAnswer: (data: MedicalHistoryAnswerModel) => void;
}

const QuestionItem: React.FC<QuestionProps> = ({
  group_id,
  question_id,
  collapsed = true,
  updateHistoryAnswer,
}: QuestionProps) => {
  const {
    state: { medical_history, question_from },
  } = useMemberContext();

  const question = question_from?.group_list
    ?.find((item) => item.group_id === group_id)
    ?.question_list?.find((q) => q.question_id === question_id);

  const init_answer = medical_history?.answer_list?.find((an) => an.question_id === question_id);
  // Use local state to avoid rending when editting comment
  const [answer, setAnswer] = useState<MedicalHistoryAnswerModel | undefined>(init_answer);

  const [collapseStatus, setCollapseStatus] = useState<boolean>(collapsed);

  const onClickCollapse = () => {
    setCollapseStatus(!collapseStatus);
  };

  const updateExplantionAnswer = (question_id: number, data: ExplanationAnswerModel): void => {
    const answer =
      (medical_history?.answer_list || []).find((item) => item.question_id === question_id) ||
      ({
        question_id: question_id,
        explanation_answer_list: [],
      } as MedicalHistoryAnswerModel);

    const new_answer = {
      ...answer,
      explanation_answer_list: [
        ...(answer.explanation_answer_list || []).filter((item) => item.explanation_id !== data.explanation_id),
        data,
      ],
    } as MedicalHistoryAnswerModel;

    updateHistoryAnswer(new_answer);
  };

  const onChangeRadioAnswer = (question_id: number, val: boolean) => {
    updateHistoryAnswer({
      ...(answer ||
        ({
          question_id: question_id,
        } as MedicalHistoryAnswerModel)),
      answer: val,
    } as MedicalHistoryAnswerModel);
  };

  const onChangeCheckOptions = (val: number, checked: boolean) => {
    const new_answer = answer || ({ question_id: question_id, answer: [] } as MedicalHistoryAnswerModel);

    if (checked) {
      if (new_answer.answer instanceof Array) {
        new_answer.answer.push(val);
      } else {
        new_answer.answer = [val];
      }
    } else {
      if (new_answer.answer instanceof Array) {
        new_answer.answer = new_answer.answer.filter((opt) => opt !== val);
      }
    }
    updateHistoryAnswer(new_answer);
  };

  const onChangeComment = (val: string) => {
    updateHistoryAnswer({
      ...answer,
      answer_comment: val,
    } as MedicalHistoryAnswerModel);
  };

  return (
    <Fragment key={question_id}>
      {question && (
        <ListGroup.Item className="bg-light">
          <Row className="py-2">
            <Col lg={5}>
              <div className="d-flex">
                <div className="me-3">
                  {question.description && (
                    <FontAwesomeIcon icon={collapseStatus ? faSquarePlus : faSquareMinus} onClick={onClickCollapse} />
                  )}
                </div>
                <div className="me-2">
                  <strong>{question.display_index}</strong>
                </div>
                <div className="flex-grow-1">
                  <strong>{question.question}</strong>
                  <div id="q_1" className={`collapse ${!collapseStatus && "show"}`}>
                    {<small>{question.description}</small>}
                  </div>
                </div>
              </div>
            </Col>
            {question.category === QuestionTypeEnum._2 && (
              <Col lg={7} className="mx-0 px-0">
                {question.options?.option_list?.map((item, idx) => {
                  return (
                    <Form.Check
                      inline
                      className="d-inline-flex"
                      type="checkbox"
                      label={item.label}
                      name={`chk_${question.question_id}`}
                      id={`chk-${question.question_id}-${idx}`}
                      key={`chk-${question.question_id}-${idx}`}
                      checked={
                        answer?.answer instanceof Array &&
                        item.value !== null &&
                        answer?.answer.includes(parseInt(item.value.toString()))
                      }
                      onChange={(e) => {
                        onChangeCheckOptions(parseInt((item.value || "-1").toString()), e.target.checked);
                      }}
                    />
                  );
                })}
              </Col>
            )}
            {question.category === QuestionTypeEnum._1 && (
              <>
                <Col className="mx-0 px-0 col-lg-auto">
                  {yes_no_list.map((item, idx) => {
                    return (
                      <Fragment key={`rdo-${question.question_id}-${idx}`}>
                        <Form.Check
                          inline
                          type="radio"
                          label={item.name}
                          name={`rdo_${question.question_id}`}
                          id={`rdo-${question.question_id}-${idx}`}
                          checked={answer?.answer === item.val}
                          onChange={() => {
                            onChangeRadioAnswer(question.question_id, item.val);
                          }}
                        />
                      </Fragment>
                    );
                  })}
                </Col>
                <Col>
                  {question.explanation_list && question.explanation_list.length > 0 ? (
                    <>
                      {question.explanation_list.map((explanation) => {
                        return (
                          <Fragment key={uuid()}>
                            <ExplanationItem
                              group_id={group_id}
                              question_id={question.question_id}
                              explanation_id={explanation.explanation_id}
                              updateExplantionAnswer={updateExplantionAnswer}
                            />
                          </Fragment>
                        );
                      })}
                    </>
                  ) : (
                    <Form.Control
                      type="input"
                      size="sm"
                      placeholder=""
                      value={answer?.answer_comment || ""}
                      onChange={(e) => {
                        setAnswer({
                          ...answer,
                          answer_comment: e.target.value,
                        } as MedicalHistoryAnswerModel);
                      }}
                      onBlur={(e) => {
                        onChangeComment(e.target.value);
                      }}
                    />
                  )}
                </Col>
              </>
            )}
          </Row>
        </ListGroup.Item>
      )}
    </Fragment>
  );
};

export default QuestionItem;
