import React from "react";
import { Container } from "react-bootstrap";
import ManagerDashboard from "./ManagerDashboard";
import { ManagerContextProvider, managerReducer } from "../../contexts/ManagerContext";

const ManagerPages: React.FC = () => {
  const [state, dispatcher] = React.useReducer(managerReducer, {
    cohort_list: [],
  });

  return (
    <ManagerContextProvider state={state} dispatcher={dispatcher}>
      {/* <Container style={{ backgroundColor: "white" }}> */}
      <Container style={{ backgroundColor: "white", minHeight: "100vh" }}>
        <ManagerDashboard />
      </Container>
    </ManagerContextProvider>
  );
};

export default ManagerPages;
