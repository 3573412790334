import React, { useEffect, useState } from "react";
import { Col, Row, Tabs, Tab, Button, Spinner, Container } from "react-bootstrap";
import { useMemberContext } from "../../contexts/MemberContext";
import { useAuthContext } from "../../contexts/AuthContext";
import apiClient from "../../api/api_client";
import ViewMedicationVisit from "../../component/ViewMedicationVisit";
import ViewDemographics from "../../component/ViewDemographics";
import ViewMedication from "../../component/ViewMedication";
import ViewMedicalHistory from "../../component/ViewMedicalHistory";
import ViewExtremeEnvQA from "../../component/ViewExtremeEnvQA";
import { ApiStatus } from "../../model/constant_data";
import CandidateFollowUpSection from "./CandidateFollowUpSection";
import ViewExerciseSuitability from "../../component/ViewExerciseSuitability";
import { FollowUpQAStatusEnum } from "../../client";
import "./StepViewApplication.css";

const StepViewApplication: React.FC = () => {
  const {
    state: { user },
  } = useAuthContext();
  const {
    state: {
      general,
      demographics,
      medication,
      question_from,
      medical_history,
      medical_visit,
      extreme_qa_list,
      exercise,
    },
    dispatcher,
  } = useMemberContext();

  const [initStatus, setInitStatus] = useState<ApiStatus>(ApiStatus.IDEL);

  useEffect(() => {
    void (async (): Promise<void> => {
      setInitStatus(ApiStatus.BUSY);
      if (user && general && general.application_id !== undefined && general.form_id !== undefined) {
        const details = await apiClient.getUserApplicationDetails(user.user_id, general.application_id);
        dispatcher({
          type: "SET_APPLICATION_DETAILS",
          payload: details,
        });
        const res = await apiClient.getQuestionForm(general.form_id);
        dispatcher({
          type: "SET_QUESTION_FORM",
          payload: res,
        });

        await queryFollowUpQuestions();
      }
      setInitStatus(ApiStatus.IDEL);
    })();
  }, [user]);

  const queryFollowUpQuestions = async () => {
    if (user && general && general.application_id) {
      const qa_list = await apiClient.getApplicationFollowUpQA(user.user_id, general.application_id);
      const view_qa_list = qa_list.filter((qa) => qa.qa_status === FollowUpQAStatusEnum.ANSWERED);
      dispatcher({
        type: "SET_FOLLOWUP_QA_LIST",
        payload: view_qa_list,
      });
    }
  };

  const onClickBack = (event: React.UIEvent<HTMLButtonElement>) => {
    event.preventDefault();
    dispatcher({
      type: "SET_STEP",
      payload: 0,
    });
  };

  return (
    <>
      <Container className="mt-3 border shadow rounded bg-light" fluid>
        <Row className="my-3">
          <Col lg={10}>
            <h4 className="my-2">Application Details</h4>
          </Col>
          <Col lg={2} className="d-flex justify-content-end">
            <Button variant="primary" className=" py-1 mx-2" onClick={onClickBack}>
              Back
            </Button>
          </Col>
        </Row>
      </Container>

      {initStatus === ApiStatus.BUSY && demographics === undefined && (
        <Spinner animation="border" className="mt-3" variant="warning" />
      )}

      {demographics !== undefined && (
        <Tabs defaultActiveKey="demographics" variant="pills" className="mt-3 rounded bg-light">
          <Tab eventKey="demographics" title="Demographics" className="application_view_tab">
            <ViewDemographics category={general?.category} demographics={demographics} />
          </Tab>
          <Tab eventKey="medication" title="Medication" className="application_view_tab">
            <ViewMedication medication={medication} />
          </Tab>
          <Tab eventKey="history" title="Medical History" className="application_view_tab">
            <ViewMedicalHistory question_from={question_from} medical_history={medical_history} />
          </Tab>
          <Tab eventKey="visit" title="Medical Visits" className="application_view_tab">
            <ViewMedicationVisit medical_visit={medical_visit} />
          </Tab>
          <Tab eventKey="exercise" title="Exercise & Suitability" className="application_view_tab">
            <ViewExerciseSuitability exercise={exercise} />
          </Tab>
          <Tab eventKey="extreme" title="Extreme Environment" className="application_view_tab">
            <ViewExtremeEnvQA extreme_qa_list={extreme_qa_list} />
          </Tab>
          <Tab eventKey="followup_qa" title="Follow-Up Questions" className="application_view_tab">
            <CandidateFollowUpSection followup_mode={false} />
          </Tab>
        </Tabs>
      )}
    </>
  );
};

export default StepViewApplication;
