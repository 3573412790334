import React, { Fragment, useEffect, useState } from "react";
import { Col, Container, Form, ListGroup, Row } from "react-bootstrap";
// import ReactQuill from "react-quill";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-regular-svg-icons";
import ConfirmModal from "../../component/ConfirmModal";
import { MedeicalCategoryEnum, ReviewOfwodResponse } from "../../client";
import { useFlightSurgeonContext } from "../../contexts/FlightSurgeonContext";
import { MedVolBasicModel, MedVolGroupModel } from "../../model/api_model";
import medVolList from "../../model/all_med_vol.json";
import { ASSESSMENT_CATEGORY_DICT } from "../../model/constant";
import "./OfwodEditor.css";

export interface OfwodEditorProps {
  idx: number;
  data: ReviewOfwodResponse;
  medVolList: MedVolGroupModel[];
}

const OfwodEditor: React.FC<OfwodEditorProps> = ({ idx, data }) => {
  const { dispatcher } = useFlightSurgeonContext();
  const [showConfirm, setShowConfirm] = useState<boolean>(false);

  const [medGroupVol, setGroupVol] = useState<string | undefined>(undefined);
  const [medSubVol, setSubVol] = useState<string | undefined>(undefined);
  const [medGroupList, setMedGroupList] = useState<MedVolGroupModel[]>([]);
  const [medSubList, setMedSubList] = useState<MedVolBasicModel[]>([]);

  useEffect(() => {
    setMedGroupList(medVolList as MedVolGroupModel[]);
  }, []);

  useEffect(() => {
    if (data.ofwod.section) {
      const group_v = data.ofwod.section.split(".").slice(0, 2).join(".");

      const group = medVolList.find((item) => item.vol === group_v);
      setGroupVol(group?.vol);

      const sub = group?.options.find((sub) => sub.vol === data.ofwod.section);
      setSubVol(sub?.vol || undefined);
    }
  }, []);

  useEffect(() => {
    if (medGroupVol) {
      const group = medVolList.find((item) => item.vol === medGroupVol);
      setMedSubList(group?.options || []);

      const sub = group?.options.find((sub) => sub.vol === medSubVol);
      if (!sub) {
        setSubVol(undefined);
      }
    } else {
      setSubVol(undefined);
    }
  }, [medGroupVol]);

  const onChangeVol = (vol: string): void => {
    setGroupVol(vol);
    dispatcher({
      type: "UPDATE_OFWOD",
      payload: {
        ...data,
        ofwod: {
          ...data.ofwod,
          section: vol,
          category: (ASSESSMENT_CATEGORY_DICT[vol] || MedeicalCategoryEnum.UNCLASSIFIED) as MedeicalCategoryEnum,
        },
      },
    });
  };

  const onChangeSubVol = (vol: string): void => {
    setSubVol(vol);
    dispatcher({
      type: "UPDATE_OFWOD",
      payload: {
        ...data,
        ofwod: {
          ...data.ofwod,
          section: vol,
        },
      },
    });
  };

  const onChangeOfwod = (value: string) => {
    dispatcher({
      type: "UPDATE_OFWOD",
      payload: {
        ...data,
        ofwod: {
          ...data.ofwod,
          contents: value,
        },
      },
    });
  };

  return (
    <Fragment key={data.uid}>
      <ListGroup.Item as="li" key={data.uid} className="d-flex justify-content-between align-items-start bg-light">
        <span className="m-2">{idx + 1}</span>
        <Container fluid>
          <Row>
            <Col lg={4} className="d-flex justify-content-between my-1">
              <Form.Select
                value={medGroupVol || "-"}
                onChange={(e) => {
                  onChangeVol(e.target.value);
                }}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <option value={""}>Select main OFWOD option</option>
                {medGroupList.map((opt, idx) => {
                  return (
                    <option value={opt.vol} key={idx}>
                      {opt.vol} - {opt.title}
                    </option>
                  );
                })}
              </Form.Select>
            </Col>
            <Col lg={8} className="my-1">
              {medSubList.length > 0 && (
                <Form.Select
                  value={data.ofwod.section || "-"}
                  onChange={(e) => {
                    onChangeSubVol(e.target.value);
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <option value={""}>Select sub OFWOD option</option>
                  {medSubList.map((opt, idx) => {
                    return (
                      <option value={opt.vol} key={idx}>
                        {opt.vol} - {opt.title}
                      </option>
                    );
                  })}
                </Form.Select>
              )}
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Control
                as="textarea"
                rows={4}
                value={data.ofwod.contents || ""}
                onChange={(e) => {
                  onChangeOfwod(e.target.value);
                }}
              />
            </Col>
          </Row>
        </Container>
        <FontAwesomeIcon
          className="m-2"
          icon={faTrashCan}
          color="red"
          size={"lg"}
          cursor={"pointer"}
          onClick={(e) => {
            e.stopPropagation();
            setShowConfirm(true);
          }}
        />
      </ListGroup.Item>

      <ConfirmModal
        show={showConfirm}
        setShow={setShowConfirm}
        doContinue={() => {
          dispatcher({
            type: "DELETE_OFWOD",
            payload: data.uid,
          });
        }}
      >
        <span>Are you sure to delete the OFWOD: {data.ofwod.section || "-"}?</span>
      </ConfirmModal>
    </Fragment>
  );
};

export default OfwodEditor;
