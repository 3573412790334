import React from "react";
import { Col, ListGroup, Row } from "react-bootstrap";
import { useAuthContext } from "../contexts/AuthContext";
import { getCountryName } from "./CountrySelector";
import { getFormCategoryName } from "../model/constant";
import { DemographicsBasic, FormCategoryEnum } from "../client";

export interface ViewDemographicsProps {
  category?: FormCategoryEnum | undefined;
  demographics?: DemographicsBasic | undefined;
}

const ViewDemographics: React.FC<ViewDemographicsProps> = ({ category, demographics }) => {
  const {
    state: { user },
  } = useAuthContext();

  return (
    <ListGroup>
      <ListGroup.Item variant="secondary">
        <Row>
          <Col lg={1}>
            <strong>ID</strong>
          </Col>
          <Col lg={5}>
            <strong>Question</strong>
          </Col>
          <Col lg={6}>
            <strong>Answer</strong>
          </Col>
        </Row>
      </ListGroup.Item>
      <ListGroup.Item className="p-0" variant="secondary">
        <Row className="mx-0">
          <Col lg={1} className="py-2">
            1
          </Col>
          <Col lg={5} className="py-2">
            Application For
          </Col>
          <Col lg={6} className="py-2 bg-light">
            {category ? getFormCategoryName(category) : "-"}
            {/* {getFormCategoryName(parseInt(general?.category?.toString() || ""))} */}
          </Col>
        </Row>
      </ListGroup.Item>
      <ListGroup.Item className="p-0" variant="secondary">
        <Row className="mx-0">
          <Col lg={1} className="py-2">
            2
          </Col>
          <Col lg={5} className="py-2">
            Full Name
          </Col>
          <Col lg={6} className="py-2 bg-light">
            {demographics?.suffix} {user?.first_name} {demographics?.middle_name} {user?.last_name}
          </Col>
        </Row>
      </ListGroup.Item>
      <ListGroup.Item className="p-0" variant="secondary">
        <Row className="mx-0">
          <Col lg={1} className="py-2">
            3
          </Col>
          <Col lg={5} className="py-2">
            Address
          </Col>
          <Col lg={6} className="py-2 bg-light">
            {[demographics?.address, demographics?.city, demographics?.state, demographics?.zip_code]
              .filter((item) => item)
              .join(", ") || "-"}
            <br />
            {getCountryName(demographics?.country)}
            <br />
            {demographics?.telephone || "-"}
          </Col>
        </Row>
      </ListGroup.Item>
      <ListGroup.Item className="p-0" variant="secondary">
        <Row className="mx-0">
          <Col lg={1} className="py-2">
            4
          </Col>
          <Col lg={5} className="py-2">
            Date of Birth
          </Col>
          <Col lg={6} className="py-2 bg-light">
            {new Date(demographics?.birthday || "").toLocaleDateString()} {getCountryName(demographics?.citizenship)}
          </Col>
        </Row>
      </ListGroup.Item>
      <ListGroup.Item className="p-0" variant="secondary">
        <Row className="mx-0">
          <Col lg={1} className="py-2">
            5
          </Col>
          <Col lg={5} className="py-2">
            Color of Hair
          </Col>
          <Col lg={6} className="py-2 bg-light">
            {demographics?.hair_color || "-"}
          </Col>
        </Row>
      </ListGroup.Item>
      <ListGroup.Item className="p-0" variant="secondary">
        <Row className="mx-0">
          <Col lg={1} className="py-2">
            6
          </Col>
          <Col lg={5} className="py-2">
            Color of Eyes
          </Col>
          <Col lg={6} className="py-2 bg-light">
            {demographics?.eyes_color || "-"}
          </Col>
        </Row>
      </ListGroup.Item>
      <ListGroup.Item className="p-0" variant="secondary">
        <Row className="mx-0">
          <Col lg={1} className="py-2">
            7
          </Col>
          <Col lg={5} className="py-2">
            Sex
          </Col>
          <Col lg={6} className="py-2 bg-light">
            {demographics?.sex || "-"}
          </Col>
        </Row>
      </ListGroup.Item>
      <ListGroup.Item className="p-0" variant="secondary">
        <Row className="mx-0">
          <Col lg={1} className="py-2">
            8
          </Col>
          <Col lg={5} className="py-2">
            Height
          </Col>
          <Col lg={6} className="py-2 bg-light">
            {demographics?.height || "-"} <sub>{demographics?.height_unit || "cm"}</sub>
          </Col>
        </Row>
      </ListGroup.Item>
      <ListGroup.Item className="p-0" variant="secondary">
        <Row className="mx-0">
          <Col lg={1} className="py-2">
            9
          </Col>
          <Col lg={5} className="py-2">
            Weight
          </Col>
          <Col lg={6} className="py-2 bg-light">
            {demographics?.weight || "-"} <sub>{demographics?.weight_unit || "kg"}</sub>
          </Col>
        </Row>
      </ListGroup.Item>
    </ListGroup>
  );
};

export default ViewDemographics;
