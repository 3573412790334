import React from "react";
import { Alert, Button, Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router";
import { useAuthContext } from "../contexts/AuthContext";
import { signOut } from "aws-amplify/auth";
// import Kakapo from "../theme/images/branding/birds/kakapo.png";
// import { ErrorPageContainer } from './Container';

export const NoAccessPage: React.FC = () => {
  const { dispatcher } = useAuthContext();
  const navigate = useNavigate();

  const onSignOut = () => {
    if (signOut) {
      void (async (): Promise<void> => {
        await signOut();
      })();
    }
    dispatcher({
      type: "SET_TOKEN_SUB",
      payload: undefined,
    });
    dispatcher({
      type: "SET_USER",
      payload: undefined,
    });
    dispatcher({
      type: "SET_USER_ATTRIBUTES",
      payload: undefined,
    });
    navigate(`/`);
  };

  return (
    <Container>
      <Row className="my-5">
        <Col className="text-center">
          <Alert variant="danger">
            <h2>Uh oh, this gate is closed!</h2>
            <p>There’s either nothing here, or you need additional access to view this page.</p>
          </Alert>
        </Col>
      </Row>

      {/* <Row className="mb-3">
        <Col className="d-flex justify-content-center">
          <Image src={Kakapo} style={{ width: '160px' }} />
        </Col>
      </Row>
      <Row className="mb-3">
        <Col className="text-center">
          There’s either nothing here, or you need additional access to view this page. You’re
          currently logged in as <strong>{userMe?.email}</strong>, is this the correct account?
        </Col>
      </Row> */}
      <Row>
        <Col className="text-center">
          <Button
            variant="primary"
            onClick={() => {
              onSignOut();
            }}
          >
            Sign Out
          </Button>
        </Col>
      </Row>
    </Container>
  );
};
