import React, { ReactNode } from "react";
import { Button, Modal } from "react-bootstrap";
import "./ConfirmModal.css";

export interface ConfirmModalProps {
  show: boolean;
  setShow: (val: boolean) => void;
  doContinue: () => void;
  children: ReactNode;
}

const ConfirmModal: React.FC<ConfirmModalProps> = ({
  show,
  setShow,
  doContinue,
  children,
}) => {
  return (
    <>
      <Modal centered show={show} className="confirm-modal">
        <Modal.Header closeButton onHide={() => setShow(false)}>
          <Modal.Title>Notice</Modal.Title>
        </Modal.Header>
        <Modal.Body>{children}</Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => setShow(false)}>
            No
          </Button>
          <Button
            variant="success"
            onClick={() => {
              doContinue();
              setShow(false);
            }}
          >
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ConfirmModal;
