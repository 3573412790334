import React from "react";
import { Col, Form, ListGroup, Row } from "react-bootstrap";
import { useMemberContext } from "../../contexts/MemberContext";
import { FollowUpQAStatusEnum } from "../../client";

export interface CandidateFollowUpSectionProps {
  followup_mode: boolean;
}

const CandidateFollowUpSection: React.FC<CandidateFollowUpSectionProps> = ({ followup_mode = false }) => {
  const {
    state: { followup_qa_list },
    dispatcher,
  } = useMemberContext();

  const onChangeAnswer = (uid: string, value: string) => {
    if (!followup_qa_list) {
      return;
    }

    dispatcher({
      type: "SET_FOLLOWUP_QA_LIST",
      payload: followup_qa_list.map((data) => {
        if (data.uid === uid) {
          return {
            ...data,
            candidate_answer: {
              ...data.candidate_answer,
              answer: value,
            },
          };
        }
        return data;
      }),
    });
  };

  return (
    <>
      <ListGroup>
        <ListGroup.Item variant="secondary">
          <Row>
            <Col lg={1}>
              <strong>ID</strong>
            </Col>
            <Col lg={3}>
              <strong>Question</strong>
            </Col>
            <Col lg={4}>
              <strong>Context to Candidate</strong>
            </Col>
            <Col lg={4}>
              <strong>Answer</strong>
            </Col>
          </Row>
        </ListGroup.Item>
        {(!followup_qa_list || followup_qa_list.length === 0) && (
          <ListGroup.Item className="p-0">
            <Row className="p-2">
              <Col lg={12}>Followup questions are not available.</Col>
            </Row>
          </ListGroup.Item>
        )}
        {followup_qa_list?.map((qa, idx) => {
          return (
            <ListGroup.Item className="p-0" variant="secondary" key={idx}>
              <Row className="mx-0">
                <Col lg={1} className="py-2">
                  {idx + 1}
                </Col>
                <Col lg={3} className="py-2">
                  {qa.doctor_question?.question || ""}
                </Col>
                <Col lg={4} className="py-2">
                  {qa.doctor_question?.context_to_candidate || ""}
                </Col>
                <Col lg={4} className="py-2 bg-light">
                  {followup_mode && qa.qa_status !== FollowUpQAStatusEnum.ANSWERED ? (
                    <Form.Control
                      as="textarea"
                      rows={3}
                      defaultValue={qa.candidate_answer?.answer || ""}
                      onChange={(e) => {
                        onChangeAnswer(qa.uid, e.target.value);
                      }}
                    />
                  ) : (
                    <>{qa.candidate_answer?.answer || ""}</>
                  )}{" "}
                </Col>
              </Row>
            </ListGroup.Item>
          );
        })}
      </ListGroup>
    </>
  );
};

export default CandidateFollowUpSection;
