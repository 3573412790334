import React, { useState } from "react";
import { v4 as uuid } from "uuid";
import { useManagerContext } from "../../contexts/ManagerContext";
import { Button, Col, Container, Row, Spinner } from "react-bootstrap";
import { ApiStatus } from "../../model/constant_data";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import ConfirmModal from "../../component/ConfirmModal";
import CohortMemberEditModal from "./CohortMemberEditModal";
import UploadMemberModal from "./UploadMemberModal";
import { QuerryCohortUserResponse, UserRoleEnum } from "../../client";
import apiClient from "../../api/api_client";
import { getUserRoleName } from "../../model/constant";

const CohortMemberTable: React.FC = () => {
  const {
    state: { cohort, cohort_user_list, candidate },
    dispatcher,
  } = useManagerContext();

  const [showMemberModal, setShowMemberModal] = useState<boolean>(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState<boolean>(false);
  const [showUpload, setShowUpload] = useState<boolean>(false);
  const [status, setStatus] = useState<ApiStatus>(ApiStatus.IDEL);

  const onClickEditMember = (candidateId: string) => {
    if (candidateId !== undefined && cohort_user_list) {
      const target = cohort_user_list.find((item) => item.user_id === candidateId);
      if (target) {
        dispatcher({
          type: "SET_CANDIDATE",
          payload: target,
        });
        setShowMemberModal(true);
      }
    }
  };

  const onClickDeleteMember = (candidateId: string) => {
    if (candidateId !== undefined && cohort_user_list) {
      const target = cohort_user_list.find((item) => item.user_id === candidateId);
      if (target) {
        dispatcher({
          type: "SET_CANDIDATE",
          payload: target,
        });
        setShowDeleteConfirm(true);
      }
    }
  };

  const doDeleteMember = () => {
    if (cohort && candidate) {
      setStatus(ApiStatus.BUSY);

      void (async (): Promise<void> => {
        await apiClient.deleteCohortMember(candidate.cohort_id, candidate.user_id);

        const users = await apiClient.getCohortUsers(cohort.cohort_id);
        dispatcher({
          type: "SET_COHORT_USER_LIST",
          payload: users,
        });
        dispatcher({
          type: "SET_CANDIDATE",
          payload: undefined,
        });
        setStatus(ApiStatus.IDEL);
      })();
    }
  };

  const onClickNewMember = () => {
    if (cohort) {
      dispatcher({
        type: "SET_CANDIDATE",
        payload: {
          cohort_id: cohort.cohort_id,
          role: UserRoleEnum._1,
          properties: {},
        } as QuerryCohortUserResponse,
      });
      setShowMemberModal(true);
    }
  };

  return (
    <>
      <Container className="px-3">
        <Row className="mt-0 mb-2 p-0">
          <Col className="m-0 p-0 d-flex justify-content-end">
            {status === ApiStatus.BUSY ? (
              <>
                <Spinner animation="border" />
              </>
            ) : (
              <>
                <Button variant="primary" className="me-3" onClick={() => onClickNewMember()}>
                  Add New Member
                </Button>
                <Button variant="secondary" onClick={() => setShowUpload(true)} disabled>
                  Upload Member File
                </Button>
              </>
            )}
          </Col>
        </Row>

        <Row className="bg-light border border-secondary rounded-top">
          <Col lg={3} className="py-2 px-3">
            Name
          </Col>
          <Col lg={2} className="py-2 px-3">
            Role
          </Col>
          <Col lg={2} className="py-2 px-3">
            Email
          </Col>
          <Col lg={1} className="py-2 px-3">
            Crew Type
          </Col>
          <Col lg={1} className="py-2 px-3">
            Duties
          </Col>
          <Col lg={2} className="py-2 px-3">
            Phone
          </Col>
          <Col lg={1} className="py-2 px-3">
            &nbsp;
          </Col>
        </Row>
        {(cohort_user_list || [])
          .sort((a, b) => ((a.first_name || "") > (b.first_name || "") ? 1 : -1))
          .map((member) => {
            return (
              <Row className="border border-secondary border-top-0" key={member.user_id || uuid()}>
                <Col lg={3} className="p-3">
                  {member.first_name} {member.last_name}
                </Col>
                <Col lg={2} className="p-3">
                  {member.role !== undefined ? getUserRoleName(member.role) : ""}
                </Col>
                <Col lg={2} className="p-3">
                  {member.email}
                </Col>
                <Col lg={1} className="p-3">
                  {member.properties.crew_type}
                </Col>
                <Col lg={1} className="p-3">
                  {member.properties.duties}
                </Col>
                <Col lg={2} className="p-3">
                  {member.phone}
                </Col>
                <Col lg={1} className="p-3 text-end">
                  <FontAwesomeIcon
                    icon={faPenToSquare}
                    size={"lg"}
                    className="px-1"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      onClickEditMember(member.user_id);
                    }}
                  />
                  <FontAwesomeIcon
                    icon={faTrashCan}
                    size={"lg"}
                    color="red"
                    className="px-1"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      onClickDeleteMember(member.user_id);
                    }}
                  />
                </Col>
              </Row>
            );
          })}
      </Container>

      <ConfirmModal show={showDeleteConfirm} setShow={setShowDeleteConfirm} doContinue={doDeleteMember}>
        <span>
          Are you sure to delete the member
          {candidate && (
            <>
              {" "}
              ({candidate.first_name} {candidate.last_name})
            </>
          )}
          ?
        </span>
      </ConfirmModal>

      <CohortMemberEditModal show={showMemberModal} setShow={setShowMemberModal} />

      <UploadMemberModal show={showUpload} setShow={setShowUpload} cohortId={1} />
    </>
  );
};

export default CohortMemberTable;
