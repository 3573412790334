import React from "react";
import { v4 as uuid } from "uuid";
import { Accordion, Form } from "react-bootstrap";
import { useFlightSurgeonContext } from "../../contexts/FlightSurgeonContext";
import { ReviewSummaryResponse } from "../../client";

export const ReviewSummarySection: React.FC = () => {
  const {
    state: { candidate, review_summary },
    dispatcher,
  } = useFlightSurgeonContext();

  const onChangeReviewSummary = (val: string) => {
    if (candidate) {
      dispatcher({
        type: "SET_REVIEW_SUMMARY",
        payload: {
          ...(review_summary || {
            application_id: candidate.application_id,
            uid: uuid(),
          }),
          summary: {
            ...(review_summary?.summary || {}),
            contents: val,
          },
        } as ReviewSummaryResponse,
      });
    }
  };

  return (
    <>
      <Accordion defaultActiveKey={"review_summary"} className="rounded shadow mt-4">
        <Accordion.Item eventKey={"review_summary"}>
          <Accordion.Header>
            <h5 className="my-0 mx-3">Summary</h5>
          </Accordion.Header>
          <Accordion.Body className="p-0">
            <Form.Control
              as="textarea"
              rows={8}
              placeholder="Summary"
              value={review_summary?.summary.contents || ""}
              onChange={(e) => {
                onChangeReviewSummary(e.target.value);
              }}
            />
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </>
  );
};
