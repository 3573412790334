import { ApplicationStatusEnum, FormCategoryEnum, UserRoleEnum } from "../client";

// ============================================================================
//  General Types
// ============================================================================
export type StringConstantType = {
  id: string;
  name: string;
};

export type NumberConstantType = {
  id: number;
  name: string;
};

// ============================================================================
//  Yes No list
// ============================================================================
export const yes_no_list = [
  {
    val: true,
    name: "Yes",
  },
  {
    val: false,
    name: "No",
  },
];

// ============================================================================
//  User Role
// ============================================================================
export enum RoleName {
  candidate = "Candidate",
  surgeon = "Flight Surgeon",
  manager = "Admin",
  admin = "System Admin",
}

export type UserRole = {
  role_id: UserRoleEnum;
  role_name: RoleName;
};

export const user_role_list: UserRole[] = [
  {
    role_id: 1,
    role_name: RoleName.candidate,
  },
  {
    role_id: 2,
    role_name: RoleName.surgeon,
  },
  {
    role_id: 8,
    role_name: RoleName.manager,
  },
  {
    role_id: 9,
    role_name: RoleName.admin,
  },
];

export const getUserRoleName = (role_id: number): string => {
  const data = user_role_list.find((item) => item.role_id === role_id);
  return data ? data.role_name : "Unknown";
};

// ============================================================================
//  Application Status
// ============================================================================
// export enum ApplicationStatus {
//   draft = "Draft",
//   submitted = "Submitted",
//   following_up = "Following-Up",
//   reviewed = "Reviewed",
//   approved = "Approved",
//   archived = "Archived",
// }

// export enum ApplicationStatusId {
//   draft = 1,
//   submitted = 2,
//   following_up = 3,
//   reviewed = 4,
//   approved = 9,
//   archived = 90,
// }

export type ApplicationStatusType = {
  status_id: ApplicationStatusEnum;
  status_name: string;
};

export const application_status_list: ApplicationStatusType[] = [
  {
    status_id: ApplicationStatusEnum._1,
    status_name: "Draft",
  },
  {
    status_id: ApplicationStatusEnum._2,
    status_name: "Submitted",
  },
  {
    status_id: ApplicationStatusEnum._3,
    status_name: "Following-Up",
  },
  {
    status_id: ApplicationStatusEnum._4,
    status_name: "Reviewed",
  },
  {
    status_id: ApplicationStatusEnum._9,
    status_name: "Approved",
  },
  {
    status_id: ApplicationStatusEnum._90,
    status_name: "Archived",
  },
];

export const getApplicationStatusName = (status_id: ApplicationStatusEnum): string => {
  const data = application_status_list.find((item) => item.status_id === status_id);
  return data ? data.status_name : "Unknown";
};

// ============================================================================
//  Form Category
// ============================================================================
export enum FormCategoryName {
  AMC = "Astronaut - candidate",
  AMSPC = "Astronaut - recertify",
  OTHER = "Other TBC",
}

export type FormCategory = {
  category_id: FormCategoryEnum;
  category_name: FormCategoryName;
  enabled: boolean;
};

export const FORM_CATEGORY_LIST: FormCategory[] = [
  {
    category_id: 1,
    category_name: FormCategoryName.AMC,
    enabled: true,
  },
  {
    category_id: 2,
    category_name: FormCategoryName.AMSPC,
    enabled: false,
  },
  {
    category_id: 3,
    category_name: FormCategoryName.OTHER,
    enabled: false,
  },
];

export const getFormCategoryName = (category_id: number): string => {
  const data = FORM_CATEGORY_LIST.find((item) => item.category_id === category_id);
  return data ? data.category_name : "Unknown";
};

// ============================================================================
//
// ============================================================================
export const ASSESSMENT_CATEGORY_LIST = ["neuro", "cardio", "psych"];
export const ASSESSMENT_CATEGORY_DICT: { [key: string]: string } = {
  "4.14": "neuro",
  "4.7": "cardio",
  "4.15": "psych",
};
