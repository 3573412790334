import { ExplanationTypeEnum } from "../client";

export enum ApiStatus {
  IDEL = "idel",
  BUSY = "busy",
  ERROR = "error",
  DONE = "done",
}

// export enum RoleIdEnum {
//   candidate = 1,
//   surgeon = 2,
//   manager = 8,
//   admin = 9,
// }

// // export const tag_list = ["customer", "admin", "accountant", "doctor"] as const;
// // export type Tag = (typeof tag_list)[number];
// export enum OnHoldCertificateID {
//   NONE = 0,
//   AT = 1,
//   C = 2,
//   FI = 3,
//   P = 4,
//   S = 5,
//   AS = 6,
//   FE = 7,
//   FN = 8,
//   R = 9,
//   O = 10,
// }
// export enum OnHoldCertificateName {
//   NONE = "None",
//   AT = "Airline Transport",
//   AS = "ATC Specialist",
//   C = "Commercial",
//   FI = "Flight Instructor",
//   FE = "Flight Engineer",
//   P = "Private",
//   S = "Student",
//   FN = "Flight Navigator",
//   R = "Recreational",
//   O = "Other",
// }

// US States
export const us_status_list = [
  "AK",
  "AL",
  "AR",
  "AS",
  "AZ",
  "CA",
  "CO",
  "CT",
  "DC",
  "DE",
  "FL",
  "GA",
  "GU",
  "HI",
  "IA",
  "ID",
  "IL",
  "IN",
  "KS",
  "KY",
  "LA",
  "MA",
  "MD",
  "ME",
  "MI",
  "MN",
  "MO",
  "MS",
  "MT",
  "NC",
  "ND",
  "NE",
  "NH",
  "NJ",
  "NM",
  "NV",
  "NY",
  "OH",
  "OK",
  "OR",
  "PA",
  "PR",
  "RI",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VA",
  "VI",
  "VT",
  "WA",
  "WI",
  "WV",
  "WY",
];
export type UsStateType = (typeof us_status_list)[number];

const suffix_list: string[] = ["JR", "SR", "I", "VII", "II", "III", "IV", "V", "VI"];
export type SuffixType = (typeof suffix_list)[number];

const state_list: string[] = [
  "AE",
  "AK",
  "AL",
  "AP",
  "AR",
  "AS",
  "AZ",
  "CA",
  "CO",
  "CT",
  "DC",
  "DE",
  "FL",
  "FM",
  "GA",
  "GU",
  "HI",
  "IA",
  "ID",
  "IL",
  "IN",
  "KS",
  "KY",
  "LA",
  "MA",
  "MD",
  "ME",
  "MH",
  "MI",
  "MN",
  "MO",
  "MP",
  "MS",
  "MT",
  "NA",
  "NC",
  "ND",
  "NE",
  "NH",
  "NJ",
  "NM",
  "NV",
  "NY",
  "OH",
  "OK",
  "OR",
  "PA",
  "PR",
  "PW",
  "RI",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VA",
  "VI",
  "VT",
  "WA",
  "WI",
  "WV",
  "WY",
];
export type StateType = (typeof state_list)[number];

export type StringConstantType = {
  id: string;
  name: string;
};

export type NumberConstantType = {
  id: number;
  name: string;
};

export const frequency_list: StringConstantType[] = [
  {
    id: "13",
    name: "As Needed",
  },
  {
    id: "9",
    name: "Daily",
  },
  {
    id: "8",
    name: "Every 12 Hours",
  },
  {
    id: "5",
    name: "Every 4 Hours",
  },
  {
    id: "6",
    name: "Every 6 Hours",
  },
  {
    id: "7",
    name: "Every 8 Hours",
  },
  {
    id: "10",
    name: "Every Other Day",
  },
  {
    id: "4",
    name: "Four Times Daily",
  },
  {
    id: "1",
    name: "Once Daily",
  },
  {
    id: "11",
    name: "Once Weekly",
  },
  {
    id: "3",
    name: "Three Times Daily",
  },
  {
    id: "2",
    name: "Twice Daily",
  },
  {
    id: "12",
    name: "Twice Weekly",
  },
];

export const dosage_unit_list: StringConstantType[] = [
  {
    id: "11",
    name: "capsule(s)",
  },
  {
    id: "8",
    name: "cc",
  },
  {
    id: "3",
    name: "drop(s)",
  },
  {
    id: "12",
    name: "gm",
  },
  {
    id: "6",
    name: "mcg",
  },
  {
    id: "1",
    name: "mg",
  },
  {
    id: "7",
    name: "ml",
  },
  {
    id: "14",
    name: "ounce(s)",
  },
  {
    id: "10",
    name: "pill(s)",
  },
  {
    id: "2",
    name: "puff(s)",
  },
  {
    id: "9",
    name: "tablet(s)",
  },
  {
    id: "5",
    name: "tbs",
  },
  {
    id: "4",
    name: "tsp",
  },
  {
    id: "13",
    name: "units",
  },
];

export const getDisplayName = (data_list: StringConstantType[], val: string): string => {
  const data = data_list.find((item) => item.id === val);
  return data ? data.name : "Unknown";
};

// export const yes_no_list = [
//   {
//     val: true,
//     name: "Yes",
//   },
//   {
//     val: false,
//     name: "No",
//   },
// ];

export const question_category_dict: { [name: string]: number } = {
  YesNoRadio: 1,
  CheckBox: 2,
};
export const getQuestionCategory = (name: string): number => {
  return question_category_dict[name];
};

// export enum QuestionTypeEnum {
//   Radio = 1,
//   CheckBox = 2,
//   Number = 3,
//   MMYY = 4,
// }

export const explanation_category_dict: { [name: string]: number } = {
  YesNoRadio: ExplanationTypeEnum._1,
  CheckBox: ExplanationTypeEnum._2,
  Number: ExplanationTypeEnum._3,
  MMYY: ExplanationTypeEnum._4,
};

export const getExplanationCategory = (name: string | undefined): number => {
  if (name !== undefined) {
    return explanation_category_dict[name];
  }
  return 0;
};

// export const waiver_option_list: WaiverOption[] = [
//   {
//     name: "Familial Hyperlipidemias",
//     med_vol_a: "4.10.7.d",
//   },
//   {
//     name: "Non-Immune Status",
//     med_vol_a: "4.19.14",
//   },
//   {
//     name: "XXX",
//     med_vol_a: "xx.yy.zz",
//   },
// ];

// export const getWaiverOptionName = (val: string | undefined | null): string => {
//   if (!val) {
//     return "-";
//   }
//   const data = waiver_option_list.find((item) => item.med_vol_a === val);
//   return data ? data.name : "Unknown";
// };
