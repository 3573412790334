import React from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { useFlightSurgeonContext } from "../../contexts/FlightSurgeonContext";
import { getAge } from "../../api/ui_util";

const ViewCandidateGeneral: React.FC = () => {
  const {
    state: { candidate, application_details },
  } = useFlightSurgeonContext();

  const age = getAge(application_details?.demographics?.birthday || undefined);

  return (
    <Container className="border shadow rounded py-3 bg-light" fluid>
      <Row className="mb-3">
        <Form.Group as={Col} lg={4}>
          <Form.Label>Candidate</Form.Label>
          <Form.Control type="text" value={`${candidate?.first_name} ${candidate?.last_name}`} disabled />
        </Form.Group>
        <Form.Group as={Col} lg={2}>
          <Form.Label>Age</Form.Label>
          <Form.Control defaultValue={age} disabled />
        </Form.Group>
        <Form.Group as={Col} lg={2}>
          <Form.Label>Sex</Form.Label>
          <Form.Control type="text" value={application_details?.demographics?.sex || "-"} disabled />
        </Form.Group>
      </Row>
      <Row className="mb-3">
        <Form.Group as={Col} lg={4}>
          <Form.Label>Seeking</Form.Label>
          <Form.Control type="text" value={candidate?.properties?.seeking || "-"} disabled />
        </Form.Group>
        <Form.Group as={Col} lg={4}>
          <Form.Label>Crew Type</Form.Label>
          <Form.Control type="text" value={candidate?.properties?.crew_type || "-"} disabled />
        </Form.Group>
        <Form.Group as={Col} lg={4}>
          <Form.Label>Duties</Form.Label>
          <Form.Control type="text" value={candidate?.properties?.duties || "-"} disabled />
        </Form.Group>
      </Row>
      <Row>
        <Form.Group as={Col} lg={4}>
          <Form.Label>Default Flight Surgeon (Primary)</Form.Label>
          <Form.Control
            type="text"
            value={
              "-"
              // candidate?.review?.risk_assessment?.primary?.filght_surgeon || "-"
            }
            disabled
          />
        </Form.Group>
        <Form.Group as={Col} lg={4}>
          <Form.Label>Default Flight Surgeon (Reviewer)</Form.Label>
          <Form.Control
            type="text"
            value={
              "-"
              // candidate?.review?.risk_assessment?.review?.filght_surgeon || "-"
            }
            disabled
          />
        </Form.Group>
      </Row>
    </Container>
  );
};

export default ViewCandidateGeneral;
