/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * Question Category.
 */
export enum ExplanationTypeEnum {
    '_1' = 1,
    '_2' = 2,
    '_3' = 3,
    '_4' = 4,
}
