import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import { View, WithAuthenticatorOptions, useTheme, withAuthenticator } from "@aws-amplify/ui-react";
import { fetchUserAttributes } from "aws-amplify/auth";
import { fetchAuthSession } from "aws-amplify/auth";
import Menu from "../component/Menu";
import apiClient from "../api/api_client";
import { useAuthContext } from "../contexts/AuthContext";

interface AuthContainerProps {
  children: React.ReactNode;
}

const AuthContainer: React.FC<AuthContainerProps> = ({ children }: AuthContainerProps) => {
  const { state: authState, dispatcher: authDispatcher } = useAuthContext();

  useEffect(() => {
    void (async (): Promise<void> => {
      // The fetchAuthSession method will automatically refresh the accessToken and idToken if tokens are expired and a valid refreshToken presented.
      const idToken = (await fetchAuthSession()).tokens?.idToken?.toString();
      apiClient.setAuthToken(idToken);

      const userAttributes = await fetchUserAttributes();
      authDispatcher({
        type: "SET_TOKEN_SUB",
        payload: userAttributes.sub,
      });

      const token_user = await apiClient.getTokenUser(userAttributes.sub || "");
      authDispatcher({
        type: "SET_USER",
        payload: token_user,
      });
      authDispatcher({
        type: "SET_USER_ATTRIBUTES",
        payload: userAttributes,
      });

      authDispatcher({
        type: "SET_ERROR",
        payload: undefined,
      });
    })();
  }, []);

  return (
    <Container className="p-0" style={{ height: "100vh", backgroundColor: "#0d3e7e" }} fluid>
      {authState.user && (
        <>
          <Menu />
          {children}
        </>
      )}
    </Container>
  );
};
const components = {
  Header() {
    const { tokens } = useTheme();

    return (
      <View textAlign="center" padding={tokens.space.large}>
        <img alt="" src="/logo-website.png" width="96" height="96" />
        <img alt="" src="/logo-name.png" height="48" className="ms-3" />
      </View>
    );
  },

  Footer() {
    const { tokens } = useTheme();
    return (
      <View textAlign="center" padding={tokens.space.large}>
        <p className="text-light">© 2024 All rights reserved.</p>
      </View>
    );
  },
};

export default withAuthenticator(AuthContainer, {
  hideSignUp: true,
  components: components,
} as WithAuthenticatorOptions);
