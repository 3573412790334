import React, { useEffect, useState } from "react";
import { Col, Row, Tabs, Tab, Button, Spinner, Stack, Alert, Container } from "react-bootstrap";
import { useMemberContext } from "../../contexts/MemberContext";
import { useAuthContext } from "../../contexts/AuthContext";
import apiClient from "../../api/api_client";
import ViewMedicationVisit from "../../component/ViewMedicationVisit";
import ViewDemographics from "../../component/ViewDemographics";
import ViewMedication from "../../component/ViewMedication";
import ViewMedicalHistory from "../../component/ViewMedicalHistory";
import ViewExtremeEnvQA from "../../component/ViewExtremeEnvQA";
import { ApiStatus } from "../../model/constant_data";
import CandidateFollowUpSection from "./CandidateFollowUpSection";
import { ApiErrorModal } from "../../component/ApiErrorModal";
import {
  FollowUpQARequest,
  FollowUpQAStatusEnum,
  FollowUpStatusEnum,
  FollowUpStatusItem,
  UpdateFollowUpStatusRequest,
} from "../../client";
import InfoModal from "../../component/InfoModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import "./StepFollowUp.css";
import ViewExerciseSuitability from "../../component/ViewExerciseSuitability";

const StepFollowUp: React.FC = () => {
  const {
    state: { user },
  } = useAuthContext();
  const {
    state: {
      general,
      demographics,
      medication,
      question_from,
      medical_history,
      medical_visit,
      extreme_qa_list,
      followup_qa_list,
      exercise,
    },
    dispatcher,
  } = useMemberContext();

  const [initStatus, setInitStatus] = useState<ApiStatus>(ApiStatus.IDEL);
  const [saveStatus, setSaveStatus] = useState<ApiStatus>(ApiStatus.IDEL);
  const [submitStatus, setSubmitStatus] = useState<ApiStatus>(ApiStatus.IDEL);
  const [apiFailedFlg, setApiFailedFlg] = useState<boolean>(false);
  const [showUnanswer, setShowUnanswer] = useState<boolean>(false);

  useEffect(() => {
    void (async (): Promise<void> => {
      setInitStatus(ApiStatus.BUSY);
      if (user && general && general.application_id !== undefined && general.form_id !== undefined) {
        await queryApplicationDetails();
        await queryQuestionForm();
        await queryFollowUpQuestions();
      }
      setInitStatus(ApiStatus.IDEL);
    })();
  }, [user]);

  const queryApplicationDetails = async () => {
    if (user && general && general.application_id !== undefined && general.form_id !== undefined) {
      const details = await apiClient.getUserApplicationDetails(user.user_id, general.application_id);
      dispatcher({
        type: "SET_APPLICATION_DETAILS",
        payload: details,
      });
    }
  };

  const queryQuestionForm = async () => {
    if (user && general && general.application_id !== undefined && general.form_id !== undefined) {
      const form = await apiClient.getQuestionForm(general.form_id);
      dispatcher({
        type: "SET_QUESTION_FORM",
        payload: form,
      });
    }
  };

  const queryFollowUpQuestions = async () => {
    if (user && general && general.application_id) {
      const notes = await apiClient.getApplicationFollowUpQA(user.user_id, general.application_id);
      dispatcher({
        type: "SET_FOLLOWUP_QA_LIST",
        payload: notes,
      });
    }
  };

  const onClickCancel = (event: React.UIEvent<HTMLButtonElement>) => {
    event.preventDefault();
    dispatcher({
      type: "SET_STEP",
      payload: 0,
    });
    dispatcher({
      type: "SET_APPLICATION_GENERAL",
      payload: undefined,
    });
  };

  const onClickSave = (event: React.UIEvent<HTMLButtonElement>) => {
    event.preventDefault();
    void (async (): Promise<void> => {
      try {
        setSaveStatus(ApiStatus.BUSY);
        if (user && general && general.application_id !== undefined && medical_visit !== undefined) {
          await apiClient.setApplicationFollowUpQA(
            user.user_id,
            general.application_id,
            followup_qa_list?.map((item) => item as FollowUpQARequest) || []
          );
        }
        setSaveStatus(ApiStatus.IDEL);
      } catch (e) {
        setSaveStatus(ApiStatus.IDEL);
        setApiFailedFlg(true);
      }
    })();
  };

  const onClickSubmit = (event: React.UIEvent<HTMLButtonElement>) => {
    event.preventDefault();

    const unanswered = followup_qa_list?.find(
      (item) => !item.candidate_answer || item.candidate_answer.answer.trim() === ""
    );
    if (unanswered) {
      setShowUnanswer(true);
      return;
    }

    void (async (): Promise<void> => {
      try {
        setSubmitStatus(ApiStatus.BUSY);
        if (user && general && general.application_id !== undefined && medical_visit !== undefined) {
          // Update follow-up questions status
          await apiClient.setApplicationFollowUpQA(
            user.user_id,
            general.application_id,
            followup_qa_list?.map((item) => {
              return { ...item, qa_status: FollowUpQAStatusEnum.ANSWERED } as FollowUpQARequest;
            }) || []
          );
          // Update status to finish the follow-up
          await apiClient.updateApplicationFollowUpStatus(user.user_id, general.application_id, {
            followup_status: {
              status: FollowUpStatusEnum.CLOSED,
              author: user.user_id,
              timestamp: new Date().toISOString(),
            } as FollowUpStatusItem,
          } as UpdateFollowUpStatusRequest);

          dispatcher({
            type: "SET_STEP",
            payload: 0,
          });
          dispatcher({
            type: "SET_APPLICATION_GENERAL",
            payload: undefined,
          });
        }
        setSubmitStatus(ApiStatus.IDEL);
      } catch (e) {
        setSubmitStatus(ApiStatus.IDEL);
        setApiFailedFlg(true);
      }
    })();
  };

  return (
    <>
      <Container className="mt-3 border shadow rounded bg-light" fluid>
        <Row className="my-3">
          <Col lg={10}>
            <h4>Application Details</h4>
          </Col>
          <Col lg={2} className="d-flex justify-content-end">
            <Button variant="warning" className=" py-1 mx-2" onClick={onClickCancel}>
              Cancel
            </Button>
            {followup_qa_list && followup_qa_list.length > 0 && (
              <>
                <Button variant="success" className="mx-2" onClick={onClickSave}>
                  <Stack direction="horizontal" gap={1}>
                    {saveStatus === ApiStatus.BUSY && <Spinner animation="border" size="sm" className="me-2" />}
                    <span>Save</span>
                  </Stack>
                </Button>
                <Button
                  variant="dark"
                  className="mx-2"
                  onClick={onClickSubmit}
                  disabled={submitStatus === ApiStatus.BUSY}
                >
                  <Stack direction="horizontal" gap={1}>
                    {submitStatus === ApiStatus.BUSY && <Spinner animation="border" size="sm" className="me-2" />}
                    Submit
                  </Stack>
                </Button>
              </>
            )}
          </Col>
        </Row>
      </Container>

      {initStatus === ApiStatus.BUSY && (demographics === undefined || followup_qa_list === undefined) && (
        <Spinner animation="border" className="mt-3" variant="warning" />
      )}

      {demographics !== undefined && (
        <Tabs defaultActiveKey="followup_qa" variant="pills" className="mt-3 rounded bg-light">
          <Tab eventKey="demographics" title="Demographics" className="application_view_tab">
            <ViewDemographics category={general?.category} demographics={demographics} />
          </Tab>
          <Tab eventKey="medication" title="Medication" className="application_view_tab">
            <ViewMedication medication={medication} />
          </Tab>
          <Tab eventKey="history" title="Medical History" className="application_view_tab">
            <ViewMedicalHistory question_from={question_from} medical_history={medical_history} />
          </Tab>
          <Tab eventKey="visit" title="Medical Visits" className="application_view_tab">
            <ViewMedicationVisit medical_visit={medical_visit} />
          </Tab>
          <Tab eventKey="exercise" title="Exercise & Suitability" className="application_view_tab">
            <ViewExerciseSuitability exercise={exercise} />
          </Tab>
          <Tab eventKey="extreme" title="Extreme Environment" className="application_view_tab">
            <ViewExtremeEnvQA extreme_qa_list={extreme_qa_list} />
          </Tab>
          <Tab eventKey="followup_qa" title="Follow-Up Questions" className="application_view_tab">
            <CandidateFollowUpSection followup_mode={true} />
          </Tab>
        </Tabs>
      )}

      <InfoModal title="Hint" show={showUnanswer} setShow={setShowUnanswer}>
        <Alert variant="warning">
          <Stack direction="horizontal" gap={4}>
            <FontAwesomeIcon icon={faTriangleExclamation} size="2xl" style={{ color: "red" }} />
            <p className="mt-32">
              Please answer all the follow up questions. Otherwise you can save the current input for the current
              submission.
            </p>
          </Stack>
        </Alert>
      </InfoModal>

      <ApiErrorModal show={apiFailedFlg} setShow={setApiFailedFlg} />
    </>
  );
};

export default StepFollowUp;
