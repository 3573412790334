import React from "react";
import InfoModal from "./InfoModal";
import { Alert } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";

export interface ApiErrorModalProps {
  show: boolean;
  setShow: (val: boolean) => void;
}

export const ApiErrorModal: React.FC<ApiErrorModalProps> = ({ show = false, setShow }) => {
  return (
    <InfoModal title="Error" show={show} setShow={setShow}>
      <Alert variant="danger">
        <FontAwesomeIcon icon={faTriangleExclamation} size="2xl" style={{ color: "red" }} />
        <p className="mt-32">
          Unexpectted error occurred during submitting. Please check the internet connection and try again. If the
          problem persists, please contact the support.
        </p>
      </Alert>
    </InfoModal>
  );
};
