import React from "react";
import { Container } from "react-bootstrap";
import { FlightSurgeonContextProvider, flightSurgeonReducer } from "../../contexts/FlightSurgeonContext";
import FlightSurgeonDashboard from "./FlightSurgeonDashboard";
import CohortCandidateReivew from "./CohortCandidateReivew";
import styles from "./FlightSurgeonPage.module.scss";

const FlightSurgeonPage: React.FC = () => {
  const [state, dispatcher] = React.useReducer(flightSurgeonReducer, {});

  return (
    <FlightSurgeonContextProvider state={state} dispatcher={dispatcher}>
      <Container className={`${styles.cohort_body}`} fluid>
        {state.candidate === undefined ? <FlightSurgeonDashboard /> : <CohortCandidateReivew />}
      </Container>
    </FlightSurgeonContextProvider>
  );
};

export default FlightSurgeonPage;
