import React from "react";
import { Alert, Button, Col, Row } from "react-bootstrap";

import { useMemberContext } from "../../contexts/MemberContext";

const SubmitApplication: React.FC = () => {
  const {
    state: { general },
    dispatcher,
  } = useMemberContext();

  const onBack = (event: React.UIEvent<HTMLButtonElement>) => {
    event.preventDefault();
    dispatcher({
      type: "CLEAR_DETAILS",
      payload: undefined,
    });
    dispatcher({
      type: "SET_STEP",
      payload: 0,
    });
  };

  return (
    <>
      <Row>
        <Col>
          <Alert variant="success" className="my-3 mx-0">
            <Alert.Heading>Application submitted</Alert.Heading>
            <p className="mt-4">
              Your application has been successfully submitted. Doctor will
              review the application soon.
              {general?.application_id && (
                <>
                  Your application ID is:{" "}
                  <span className="fs-5">{general?.application_id}</span>.
                </>
              )}
            </p>
          </Alert>
        </Col>
      </Row>
      <Row>
        <Col className="text-end">
          <Button variant="primary" onClick={onBack}>
            Your Applications
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default SubmitApplication;
