import React, { useState } from "react";
import { Col, Form, Row, ListGroup, Button, Spinner, Container } from "react-bootstrap";
import { FORM_CATEGORY_LIST } from "../../model/constant";
import ProgressSteps, { application_step_list } from "../../component/ProgressSteps";
import QuestionHeader from "./QuestionHeader";
import { ApplicationGeneralModel, useMemberContext } from "../../contexts/MemberContext";
import { FormCategoryEnum } from "../../client";
import apiClient from "../../api/api_client";
import { useAuthContext } from "../../contexts/AuthContext";
import { ApiStatus } from "../../model/constant_data";

const StepGeneral: React.FC = () => {
  const {
    state: { user },
  } = useAuthContext();
  const {
    state: { step, general },
    dispatcher,
  } = useMemberContext();

  const [nextStatus, setNextStatus] = useState<ApiStatus>(ApiStatus.IDEL);
  const [validated, setValidated] = useState<boolean | undefined>(undefined);

  const onChangeCategory = (val: FormCategoryEnum) => {
    dispatcher({
      type: "SET_APPLICATION_GENERAL",
      payload: { ...general, category: val } as ApplicationGeneralModel,
    });
    validateData();
  };

  const validateData = (): void => {
    if (general?.category !== undefined) {
      setValidated(false);
    }
    setValidated(true);
  };

  const onCancel = (event: React.UIEvent<HTMLButtonElement>) => {
    event.preventDefault();
    dispatcher({
      type: "SET_APPLICATION_GENERAL",
      payload: undefined,
    });
    dispatcher({
      type: "SET_STEP",
      payload: step - 1,
    });
  };

  const onNext = (event: React.UIEvent<HTMLButtonElement>) => {
    event.preventDefault();
    void (async (): Promise<void> => {
      setNextStatus(ApiStatus.BUSY);
      if (user && general && general.application_id === undefined && general.category !== undefined) {
        const res = await apiClient.createApplication(user.user_id, general.category);
        dispatcher({
          type: "SET_APPLICATION_GENERAL",
          payload: res as ApplicationGeneralModel,
        });
      }
      dispatcher({
        type: "SET_STEP",
        payload: step + 1,
      });
      setNextStatus(ApiStatus.IDEL);
    })();
  };

  return (
    <>
      <Container className="mt-3 border shadow rounded bg-light" fluid>
        <Row className="py-2">
          <Col>
            <ProgressSteps step_list={application_step_list} current_step={step} />
            <div className="d-flex justify-content-between mt-3">
              <div>
                <h3>General</h3>
              </div>
              <div>
                <Button variant="secondary" className="mx-2" disabled>
                  Previous
                </Button>
                <Button variant="warning" className="mx-2" onClick={onCancel}>
                  Cancel
                </Button>
                <Button variant="secondary" className="mx-2" disabled>
                  Save
                </Button>
                <Button
                  variant="dark"
                  className="mx-2"
                  disabled={general?.category === undefined || nextStatus === ApiStatus.BUSY}
                  onClick={onNext}
                >
                  {nextStatus === ApiStatus.BUSY && <Spinner animation="border" size="sm" className="me-2" />}
                  Next
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </Container>

      <Form noValidate validated={validated} className="mt-3">
        <Row>
          <Col className="mb-3">
            <ListGroup>
              <QuestionHeader index={1} header="Application For *" collapsed={false}>
                <small>
                  Select Airman Medical Certificate. (Effective April 1, 2016, this office is no longer authorized to
                  issue Student Certificates).
                </small>
              </QuestionHeader>
              <ListGroup.Item style={{ paddingLeft: "4em" }}>
                <Row>
                  <Col lg="auto">
                    <Form.Group>
                      {FORM_CATEGORY_LIST.map((category) => {
                        return (
                          <Form.Check
                            inline
                            type="radio"
                            className="me-5"
                            name="application_category"
                            id={`rdo_${category.category_id}`}
                            label={category.category_name}
                            key={category.category_id}
                            disabled={!category.enabled}
                            checked={general?.category === category.category_id}
                            onChange={() => {
                              onChangeCategory(category.category_id);
                            }}
                            isInvalid={validated === false}
                          />
                        );
                      })}
                      <Form.Control.Feedback
                        type="invalid"
                        style={{
                          display: validated === false ? "block" : "none",
                        }}
                      >
                        Application category is required.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
              </ListGroup.Item>
            </ListGroup>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default StepGeneral;
