import {
  CreateApplicationRequest,
  CreateCohortRequest,
  CreateCohortResponse,
  CreateCohortUserRequest,
  FormCategoryEnum,
  InviteUserRequest,
  InviteUserResponse,
  QuerryCohortResponse,
  QuerryCohortUserResponse,
  ApplicationResponse,
  QueryUserResponse,
  UpdateCohortRequest,
  UpdateCohortUserRequest,
  UpdateUserRequest,
  ApplicationDetailResponse,
  UpdateDemographicsRequest,
  DemographicsBasic,
  MedicationBasic,
  UpdateMedicationRequest,
  QueryFormResponse,
  MedicalHistoryBasic,
  UpdateMedicalHistoryRequest,
  MedicalVisitBasic,
  UpdateMedicalVisitRequest,
  UpdateApplicationStatusRequest,
  ApplicationStatusEnum,
  CohortApplicationResponse,
  QueryMedicalReportResponse,
  InsertMedicalReportRequest,
  ReviewWaiverResponse,
  ReviewWaiverRequest,
  ReviewOfwodResponse,
  ReviewOfwodRequest,
  UpdateAppOverviewAssessmentRequest,
  ReviewSummaryResponse,
  ReviewSummaryRequest,
  ReviewFollowupActionResponse,
  ReviewFollowupActionRequest,
  ApplicationProperties,
  ExtremeQuestionModel,
  ExtremeQAModel,
  CreateFormRequest,
  UpdateAIStatusRequest,
  UpdateFollowUpStatusRequest,
  ApplicationOverviewStatus,
  ApplicationReviewResult,
  ExerciseBasic,
  UpdateExerciseRequest,
  SurgeonNotesModelResponse,
  SurgeonNotesModelRequest,
  FollowUpQAResponse,
  FollowUpQARequest,
} from "../client";
import { AIMedicalReportRequest } from "../model/ai_model";
import { CreateFormResponse } from "../model/api_model";
import { del, get, post, put } from "./request_util";
import { Tracer } from "./tracer";

export class ApiClient {
  private token: string | undefined;
  private tracer: Tracer;

  constructor(private baseUrl: string) {
    this.baseUrl = baseUrl;
    this.tracer = new Tracer();
  }

  public setAuthToken(token: string | undefined): void {
    this.token = token;
  }

  public getAuthToken(): string | undefined {
    return this.token;
  }

  public getHeader(): { [key: string]: string } {
    return {
      Authorization: `Bearer ${this.token}`,
      traceparent: this.tracer.getTraceParent(),
      tracestate: this.tracer.getTraceState(),
    };
  }

  public async get<T>(query: string): Promise<T> {
    return get(`${this.baseUrl}${query}`, this.getHeader());
  }

  public async post<T, U>(query: string, body: T): Promise<U> {
    return post(`${this.baseUrl}${query}`, body, this.getHeader());
  }

  public async put<T, U>(query: string, body: T): Promise<U> {
    return put(`${this.baseUrl}${query}`, body, this.getHeader());
  }

  public async delete<U>(query: string): Promise<U> {
    return del(`${this.baseUrl}${query}`, this.getHeader());
  }

  async getAllFlightSurgeons(): Promise<QueryUserResponse[]> {
    return this.get(`/v1/surgeons`);
  }

  async createTokenUser(data: InviteUserRequest): Promise<InviteUserResponse> {
    return this.post(`/v1/token_users`, data);
  }

  async updateTokenUser(data: UpdateUserRequest): Promise<void> {
    return this.put(`/v1/token_users`, data);
  }

  async deleteTokenUser(user_id: string): Promise<void> {
    return this.delete(`/v1/token_users/${user_id}`);
  }

  async getCohortListForAdmin(): Promise<QuerryCohortResponse[]> {
    return this.get(`/v1/cohorts`);
  }

  async createCohort(data: CreateCohortRequest): Promise<CreateCohortResponse> {
    return this.post(`/v1/cohorts`, data);
  }

  async updateCohort(data: UpdateCohortRequest): Promise<void> {
    return this.put(`/v1/cohorts`, data);
  }

  async deleteCohort(cohort_id: string): Promise<void> {
    return this.delete(`/v1/cohorts/${cohort_id}`);
  }

  async getCohortUsers(cohort_id: string): Promise<QuerryCohortUserResponse[]> {
    return this.get(`/v1/cohorts/${cohort_id}/users`);
  }

  async createCohortUser(cohort_id: string, data: CreateCohortUserRequest): Promise<void> {
    return this.post(`/v1/cohorts/${cohort_id}/users`, data);
  }

  async updateCohortMember(cohort_id: string, data: UpdateCohortUserRequest): Promise<void> {
    return this.put(`/v1/cohorts/${cohort_id}/users`, data);
  }

  async deleteCohortMember(cohort_id: string, user_id: string): Promise<void> {
    return this.delete(`/v1/cohorts/${cohort_id}/users/${user_id}`);
  }

  async getUserApplications(user_id: string): Promise<ApplicationResponse[]> {
    return this.get(`/v1/users/${user_id}/applications`);
  }

  async getUserApplicationDetails(user_id: string, application_id: string): Promise<ApplicationDetailResponse> {
    return this.get(`/v1/users/${user_id}/applications/${application_id}/details`);
  }

  async createApplication(user_id: string, form_category: FormCategoryEnum): Promise<ApplicationResponse> {
    return this.post(`/v1/users/${user_id}/applications`, {
      form_category: form_category,
    } as CreateApplicationRequest);
  }

  async updateApplicationStatus(
    user_id: string,
    application_id: string,
    status: ApplicationStatusEnum,
    properties: ApplicationProperties
  ): Promise<void> {
    return this.put(`/v1/users/${user_id}/applications/${application_id}/status`, {
      application_id: application_id,
      status: status,
      properties: properties,
    } as UpdateApplicationStatusRequest);
  }

  async updateApplicationDemographics(user_id: string, application_id: string, data: DemographicsBasic): Promise<void> {
    return this.put(`/v1/users/${user_id}/applications/${application_id}/demographics`, {
      demographics: data,
    } as UpdateDemographicsRequest);
  }

  async updateApplicationMedication(user_id: string, application_id: string, data: MedicationBasic): Promise<void> {
    return this.put(`/v1/users/${user_id}/applications/${application_id}/medication`, {
      medication: data,
    } as UpdateMedicationRequest);
  }

  async getLatestQuestionFormByCategory(category_id: FormCategoryEnum): Promise<QueryFormResponse> {
    return this.get(`/v1/categories/${category_id}/latest_form`);
  }

  async postQuestionForm(data: CreateFormRequest): Promise<CreateFormResponse> {
    return this.post("/v1/forms", data);
  }

  async getQuestionForm(form_id: number): Promise<QueryFormResponse> {
    return this.get(`/v1/forms/${form_id}`);
  }

  async updateApplicationMedicalHistory(
    user_id: string,
    application_id: string,
    data: MedicalHistoryBasic
  ): Promise<void> {
    return this.put(`/v1/users/${user_id}/applications/${application_id}/history`, {
      ...data,
    } as UpdateMedicalHistoryRequest);
  }

  async updateApplicationMedicalVisit(user_id: string, application_id: string, data: MedicalVisitBasic): Promise<void> {
    return this.put(`/v1/users/${user_id}/applications/${application_id}/visit`, {
      ...data,
    } as UpdateMedicalVisitRequest);
  }

  async updateApplicationExercise(user_id: string, application_id: string, data: ExerciseBasic): Promise<void> {
    return this.put(`/v1/users/${user_id}/applications/${application_id}/exercise`, {
      ...data,
    } as UpdateExerciseRequest);
  }

  async getALLCohorts(): Promise<Array<QuerryCohortResponse>> {
    return this.get(`/v1/cohorts`);
  }

  async getCohortCandidateApplications(cohort_id: string): Promise<Array<CohortApplicationResponse>> {
    return this.get(`/v1/cohorts/${cohort_id}/applications`);
  }

  async getApplicationFollowUpQA(user_id: string, application_id: string): Promise<Array<FollowUpQAResponse>> {
    return this.get(`/v1/users/${user_id}/applications/${application_id}/followup_qas`);
  }

  async setApplicationFollowUpQA(user_id: string, application_id: string, data: FollowUpQARequest[]): Promise<void> {
    return this.put(`/v1/users/${user_id}/applications/${application_id}/followup_qas`, data);
  }

  async getReviewWaivers(user_id: string, application_id: string): Promise<Array<ReviewWaiverResponse>> {
    return this.get(`/v1/users/${user_id}/applications/${application_id}/review_waivers`);
  }

  async overwriteReviewWaivers(user_id: string, application_id: string, data: ReviewWaiverRequest[]): Promise<void> {
    return this.post(`/v1/users/${user_id}/applications/${application_id}/review_waivers`, data);
  }

  async getReviewOfwods(user_id: string, application_id: string): Promise<Array<ReviewOfwodResponse>> {
    return this.get(`/v1/users/${user_id}/applications/${application_id}/review_ofwods`);
  }

  async overwriteReviewOfwods(user_id: string, application_id: string, data: ReviewOfwodRequest[]): Promise<void> {
    return this.post(`/v1/users/${user_id}/applications/${application_id}/review_ofwods`, data);
  }

  async getTokenUser(user_sub: string): Promise<QueryUserResponse> {
    return this.get(`/v1/token_users/${user_sub}`);
  }

  async postMedicalReportViaAI(user_id: string, data: InsertMedicalReportRequest): Promise<void> {
    return this.post(`/v1/users/${user_id}/applications/${data.application_id}/ai_report`, data);
  }

  async getMedicalReportViaAI(user_id: string, application_id: string): Promise<QueryMedicalReportResponse | null> {
    return this.get(`/v1/users/${user_id}/applications/${application_id}/ai_report`);
  }

  async deleteMedicalReportViaAI(user_id: string, application_id: string): Promise<void> {
    return this.delete(`/v1/users/${user_id}/applications/${application_id}/ai_report`);
  }

  async getApplicationOverviewStatus(
    user_id: string,
    application_id: string
  ): Promise<ApplicationOverviewStatus | null> {
    return this.get(`/v1/users/${user_id}/applications/${application_id}/overview_status`);
  }

  async updateApplicationOverviewAssessment(
    user_id: string,
    application_id: string,
    data: UpdateAppOverviewAssessmentRequest
  ): Promise<void> {
    return this.put(`/v1/users/${user_id}/applications/${application_id}/overview_assessment`, data);
  }

  async updateApplicationOverviewReviewResultAssessment(
    user_id: string,
    application_id: string,
    data: ApplicationReviewResult
  ): Promise<void> {
    return this.put(`/v1/users/${user_id}/applications/${application_id}/review_result`, data);
  }

  async updateApplicationAIStatus(user_id: string, application_id: string, data: UpdateAIStatusRequest): Promise<void> {
    return this.put(`/v1/users/${user_id}/applications/${application_id}/ai_status`, data);
  }

  async updateApplicationFollowUpStatus(
    user_id: string,
    application_id: string,
    data: UpdateFollowUpStatusRequest
  ): Promise<void> {
    return this.put(`/v1/users/${user_id}/applications/${application_id}/followup_status`, data);
  }

  async updateMedicalReviewSummary(
    user_id: string,
    application_id: string,
    data: ReviewSummaryRequest
  ): Promise<ReviewSummaryResponse | null> {
    return this.put(`/v1/users/${user_id}/applications/${application_id}/review_summary`, data);
  }

  async getMedicalReviewSummary(user_id: string, application_id: string): Promise<ReviewSummaryResponse | null> {
    return this.get(`/v1/users/${user_id}/applications/${application_id}/review_summary`);
  }

  async getMedicalReviewFollowupAction(
    user_id: string,
    application_id: string
  ): Promise<Array<ReviewFollowupActionResponse>> {
    return this.get(`/v1/users/${user_id}/applications/${application_id}/review_followup_actions`);
  }

  async updateMedicalReviewFollowupActions(
    user_id: string,
    application_id: string,
    data: ReviewFollowupActionRequest[]
  ): Promise<void> {
    return this.post(`/v1/users/${user_id}/applications/${application_id}/review_followup_actions`, data);
  }

  async queryApplicationSurgeonNotes(
    user_id: string,
    application_id: string
  ): Promise<SurgeonNotesModelResponse | null> {
    return this.get(`/v1/users/${user_id}/applications/${application_id}/surgeon_notes`);
  }

  async updateApplicationSurgeonNotes(
    user_id: string,
    application_id: string,
    data: SurgeonNotesModelRequest
  ): Promise<void> {
    return this.put(`/v1/users/${user_id}/applications/${application_id}/surgeon_notes`, data);
  }

  async getExtremeEnvironmentQuestions(): Promise<Array<ExtremeQuestionModel>> {
    return this.get(`/v1/extreme_questions`);
  }

  async getExtremeEnvironmentQAList(user_id: string, application_id: string): Promise<Array<ExtremeQAModel>> {
    return this.get(`/v1/users/${user_id}/applications/${application_id}/extreme_answers`);
  }

  async postExtremeEnvironmentAnswers(
    user_id: string,
    application_id: string,
    data: Array<ExtremeQAModel>
  ): Promise<void> {
    return this.post(`/v1/users/${user_id}/applications/${application_id}/extreme_answers`, data);
  }
}

const apiClient = new ApiClient("/api");
export default apiClient;

export class AiClient extends ApiClient {
  async generateMedicalReportViaAI(data: AIMedicalReportRequest): Promise<void> {
    return this.post(`/v1/medical_report`, data);
  }
}

export const aiClient = new AiClient("/ai");
