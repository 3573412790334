import React, { useEffect, useState } from "react";
import { Col, Form, Row, ListGroup, Button, Spinner, Alert, Container } from "react-bootstrap";
import { ApiStatus } from "../../model/constant_data";
import ProgressSteps, { application_step_list } from "../../component/ProgressSteps";
import QuestionHeader from "./QuestionHeader";
import { useMemberContext } from "../../contexts/MemberContext";
import {
  AIStatusEnum,
  AIStatusItem,
  ApplicationProperties,
  ApplicationStatusEnum,
  UpdateAIStatusRequest,
} from "../../client";
import apiClient, { aiClient } from "../../api/api_client";
import { useAuthContext } from "../../contexts/AuthContext";
import { ApiErrorModal } from "../../component/ApiErrorModal";
import { convertApplicationDetailsToAIRequest } from "../../model/ai_model";
import "./CandidatePage.css";

const StepExtremeEnvironment: React.FC = () => {
  const {
    state: { user },
  } = useAuthContext();
  const {
    state: { step, general, question_from, demographics, medication, medical_visit, medical_history, extreme_qa_list },
    dispatcher,
  } = useMemberContext();

  const [initStatus, setInitStatus] = useState<ApiStatus>(ApiStatus.IDEL);
  const [saveStatus, setSaveStatus] = useState<ApiStatus>(ApiStatus.IDEL);
  const [submitStatus, setSubmitStatus] = useState<ApiStatus>(ApiStatus.IDEL);
  const [validated, setValidated] = useState<boolean | undefined>(undefined);
  const [hasError, setHasError] = useState<boolean>(false);
  const [updated, setUpdated] = useState<boolean>(false);
  const [apiFailedFlg, setApiFailedFlg] = useState<boolean>(false);

  useEffect(() => {
    void (async (): Promise<void> => {
      if (extreme_qa_list === undefined && general?.application_id && user) {
        setInitStatus(ApiStatus.BUSY);
        const res = await apiClient.getExtremeEnvironmentQAList(user.user_id, general.application_id);
        dispatcher({
          type: "SET_EXTREME_QA_LIST",
          payload: res,
        });
        setInitStatus(ApiStatus.IDEL);
      }
    })();
  }, []);

  const onUpdateAnswer = (question_id: number, val: string) => {
    setUpdated(true);
    dispatcher({
      type: "SET_EXTREME_QA_LIST",
      payload: extreme_qa_list?.map((item) => {
        if (item.question_id === question_id) {
          return {
            ...item,
            answer: val,
          };
        }
        return item;
      }),
    });
  };

  const onPrevious = (event: React.UIEvent<HTMLButtonElement>) => {
    event.preventDefault();
    dispatcher({
      type: "SET_STEP",
      payload: step - 1,
    });
  };

  const onCancel = (event: React.UIEvent<HTMLButtonElement>) => {
    event.preventDefault();
    dispatcher({
      type: "SET_STEP",
      payload: 0,
    });
  };

  const onSave = (event: React.UIEvent<HTMLButtonElement>) => {
    event.preventDefault();
    void (async (): Promise<void> => {
      setSaveStatus(ApiStatus.BUSY);
      try {
        await doSave();
      } catch (e) {
        setApiFailedFlg(true);
      }
      setSaveStatus(ApiStatus.IDEL);
    })();
  };

  const onSubmit = (event: React.UIEvent<HTMLButtonElement>) => {
    event.preventDefault();

    setValidated(true);
    setHasError(false);
    if (extreme_qa_list === undefined) {
      setHasError(true);
      return;
    } else {
      const has_empty = extreme_qa_list.some((item) => !item.answer);
      setHasError(has_empty);
      if (has_empty) {
        return;
      }
    }

    void (async (): Promise<void> => {
      try {
        setSubmitStatus(ApiStatus.BUSY);
        if (updated) {
          await doSave();
        }
        if (user && general && general.application_id !== undefined) {
          await apiClient.updateApplicationStatus(user.user_id, general.application_id, ApplicationStatusEnum._2, {
            submit_date: new Date().toISOString(),
          } as ApplicationProperties);

          const request_data = convertApplicationDetailsToAIRequest(
            general.application_id,
            true,
            question_from,
            demographics,
            medication,
            medical_visit,
            medical_history,
            extreme_qa_list,
            [] // No followup qestion at this point
          );
          aiClient.setAuthToken(apiClient.getAuthToken());
          await aiClient.generateMedicalReportViaAI(request_data);

          // Update AI running status
          await apiClient.updateApplicationAIStatus(user.user_id, general.application_id, {
            ai_status: {
              status: AIStatusEnum.PROCESSING,
              author: user.user_id,
              timestamp: new Date().toISOString(),
            } as AIStatusItem,
          } as UpdateAIStatusRequest);
        }
        dispatcher({
          type: "SET_STEP",
          payload: step + 1,
        });
        setSubmitStatus(ApiStatus.IDEL);
      } catch (e) {
        setSubmitStatus(ApiStatus.IDEL);
        setApiFailedFlg(true);
      }
    })();
  };

  const doSave = async () => {
    if (user && general && general.application_id !== undefined && extreme_qa_list !== undefined) {
      await apiClient.postExtremeEnvironmentAnswers(user.user_id, general.application_id, extreme_qa_list);
    }
  };

  return (
    <>
      <Container className="mt-3 border shadow rounded bg-light" fluid>
        <Row className="py-2 ">
          <Col>
            <ProgressSteps step_list={application_step_list} current_step={step} />
            <div className="d-flex justify-content-between mt-3">
              <div>
                <h3>Extreme Environment</h3>
              </div>
              <div>
                <Button variant="dark" className="mx-2" onClick={onPrevious}>
                  Previous
                </Button>
                <Button variant="warning" className="mx-2" onClick={onCancel}>
                  Cancel
                </Button>
                <Button variant="success" className="mx-2" onClick={onSave} disabled={saveStatus === ApiStatus.BUSY}>
                  {saveStatus === ApiStatus.BUSY && <Spinner animation="border" size="sm" className="me-2" />}
                  Save
                </Button>
                <Button variant="dark" className="mx-2" onClick={onSubmit} disabled={submitStatus === ApiStatus.BUSY}>
                  {submitStatus === ApiStatus.BUSY && <Spinner animation="border" size="sm" className="me-2" />}
                  Submit
                </Button>
              </div>
            </div>
            {hasError && <Alert variant="danger">Please complete the form.</Alert>}
          </Col>
        </Row>
      </Container>

      <Form noValidate validated={validated} className="candidate_overflower_container mt-3 px-0">
        <ListGroup>
          <QuestionHeader index={35} header="Extreme Environment Questions *" collapsed={false}>
            <small>&nbsp;</small>
          </QuestionHeader>
          <ListGroup.Item style={{ paddingLeft: "4em" }} className="bg-light">
            {initStatus === ApiStatus.BUSY && <Spinner animation="border" />}
            {extreme_qa_list?.map((item) => {
              return (
                <Row key={item.question_id}>
                  <Col lg="12" className="mt-3">
                    <Form.Group className="mb-3" controlId={`extreme_question_${item.question_id}`}>
                      <Form.Label className="fw-bolder">
                        {item.display_index}. {item.question}
                      </Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={3}
                        required
                        value={item.answer || ""}
                        onChange={(e) => {
                          onUpdateAnswer(item.question_id, e.target.value);
                        }}
                      />
                      <Form.Control.Feedback type="invalid">Please provide an answer.</Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
              );
            })}
          </ListGroup.Item>
        </ListGroup>
      </Form>

      <ApiErrorModal show={apiFailedFlg} setShow={setApiFailedFlg} />
    </>
  );
};

export default StepExtremeEnvironment;
