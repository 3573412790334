import React, { useEffect, useState } from "react";
import { v4 as uuid } from "uuid";
import { Col, Row, ListGroup, Button, Spinner, Container, Form } from "react-bootstrap";
import ProgressSteps, { application_step_list } from "../../component/ProgressSteps";
import QuestionHeader from "./QuestionHeader";
import QuestionItem from "./QuestionItem";
import QuestionItemList from "./QuestionItemList";
import apiClient from "../../api/api_client";
import { useMemberContext } from "../../contexts/MemberContext";
import { MedicalHistoryAnswerModel, MedicalHistoryBasic, QuestionTypeEnum } from "../../client";
import { useAuthContext } from "../../contexts/AuthContext";
import { ApiStatus } from "../../model/constant_data";
import { ApiErrorModal } from "../../component/ApiErrorModal";
import ConfirmModal from "../../component/ConfirmModal";
import "./CandidatePage.css";

const StepMedicalHistory: React.FC = () => {
  const {
    state: { user },
  } = useAuthContext();
  const {
    state: { step, general, medical_history, question_from },
    dispatcher,
  } = useMemberContext();

  const [initStatus, setInitStatus] = useState<ApiStatus>(ApiStatus.IDEL);
  const [saveStatus, setSaveStatus] = useState<ApiStatus>(ApiStatus.IDEL);
  const [nextStatus, setNextStatus] = useState<ApiStatus>(ApiStatus.IDEL);
  const [updated, setUpdated] = useState<boolean>(false);
  const [apiFailedFlg, setApiFailedFlg] = useState<boolean>(false);
  const [noHistoryFlg, setNoHistoryFlg] = useState<boolean>(false);

  useEffect(() => {
    void (async (): Promise<void> => {
      if (general && general.form_id !== undefined && question_from === undefined) {
        setInitStatus(ApiStatus.BUSY);
        const res = await apiClient.getQuestionForm(general.form_id);
        dispatcher({
          type: "SET_QUESTION_FORM",
          payload: res,
        });
        setInitStatus(ApiStatus.IDEL);
      }
    })();
  }, []);

  const onNoMedicalHistory = () => {
    const new_answer_list: MedicalHistoryAnswerModel[] = [];
    question_from?.group_list.map((group) => {
      group.question_list.map((question) => {
        new_answer_list.push({
          question_id: question.question_id,
          answer: question.category === QuestionTypeEnum._1 ? false : [],
          explanation_answer_list: null,
        });
      });
    });

    dispatcher({
      type: "SET_MEDICAL_HISTORY",
      payload: {
        form_id: question_from?.form_id,
        answer_list: new_answer_list,
      } as MedicalHistoryBasic,
    });

    setUpdated(true);
    setNoHistoryFlg(false);
  };

  const onPrevious = (event: React.UIEvent<HTMLButtonElement>) => {
    event.preventDefault();
    dispatcher({
      type: "SET_STEP",
      payload: step - 1,
    });
  };

  const onCancel = (event: React.UIEvent<HTMLButtonElement>) => {
    event.preventDefault();
    dispatcher({
      type: "SET_STEP",
      payload: 0,
    });
  };

  const onSave = (event: React.UIEvent<HTMLButtonElement>) => {
    event.preventDefault();
    void (async (): Promise<void> => {
      try {
        setSaveStatus(ApiStatus.BUSY);
        await doSave();
        setSaveStatus(ApiStatus.IDEL);
      } catch (e) {
        setSaveStatus(ApiStatus.IDEL);
        setApiFailedFlg(true);
      }
    })();
  };

  const onNext = (event: React.UIEvent<HTMLButtonElement>) => {
    event.preventDefault();
    void (async (): Promise<void> => {
      try {
        setNextStatus(ApiStatus.BUSY);
        if (updated) {
          await doSave();
        }
        setNextStatus(ApiStatus.IDEL);
        dispatcher({
          type: "SET_STEP",
          payload: step + 1,
        });
      } catch (e) {
        setNextStatus(ApiStatus.IDEL);
        setApiFailedFlg(true);
      }
    })();
  };

  const doSave = async () => {
    if (user && general && general.application_id !== undefined && medical_history !== undefined) {
      await apiClient.updateApplicationMedicalHistory(user.user_id, general.application_id, medical_history);
    }
  };

  const updateHistoryAnswer = (data: MedicalHistoryAnswerModel): void => {
    setUpdated(true);
    const history =
      medical_history ||
      ({
        form_id: question_from?.form_id,
        answer_list: [],
      } as MedicalHistoryBasic);
    const new_answer_list = [
      ...(history?.answer_list || []).filter((item) => item.question_id !== data.question_id),
      data,
    ];

    dispatcher({
      type: "SET_MEDICAL_HISTORY",
      payload: {
        ...history,
        answer_list: new_answer_list,
      } as MedicalHistoryBasic,
    });
  };

  return (
    <>
      <Container className="mt-3 border shadow rounded bg-light" fluid>
        <Row className="py-2 ">
          <Col>
            <ProgressSteps step_list={application_step_list} current_step={step} />
            <div className="d-flex justify-content-between mt-3">
              <div>
                <h3>Medical History</h3>
              </div>
              <div>
                <Button
                  variant="dark"
                  className="me-4"
                  onClick={() => {
                    setNoHistoryFlg(true);
                  }}
                >
                  NO to all
                </Button>
                <Button variant="dark" className="mx-2" onClick={onPrevious}>
                  Previous
                </Button>
                <Button variant="warning" className="mx-2" onClick={onCancel}>
                  Cancel
                </Button>
                <Button variant="success" className="mx-2" onClick={onSave}>
                  {saveStatus === ApiStatus.BUSY && <Spinner animation="border" size="sm" className="me-2" />}
                  Save
                </Button>
                <Button variant="dark" className="mx-2" onClick={onNext}>
                  {nextStatus === ApiStatus.BUSY && <Spinner animation="border" size="sm" className="me-2" />}
                  Next
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </Container>

      {initStatus === ApiStatus.BUSY && <Spinner animation="border" />}
      <Form className="candidate_overflower_container mt-3 px-0">
        <ListGroup>
          {question_from &&
            question_from.group_list.map((group) => {
              return (
                <ListGroup key={uuid()}>
                  <QuestionHeader index={12 + group.display_index} header={group.group_title || "-"} collapsed={false}>
                    <small>
                      Check each item &quot;Yes&quot; or &quot;No.&quot; All &quot;Yes&quot; items must include an
                      explanation
                    </small>
                  </QuestionHeader>

                  <QuestionItemList>
                    {group.question_list.map((question) => {
                      return (
                        <QuestionItem
                          group_id={group.group_id}
                          question_id={question.question_id}
                          updateHistoryAnswer={updateHistoryAnswer}
                          key={uuid()}
                        />
                      );
                    })}
                  </QuestionItemList>
                </ListGroup>
              );
            })}
        </ListGroup>
      </Form>

      <ApiErrorModal show={apiFailedFlg} setShow={setApiFailedFlg} />

      <ConfirmModal
        show={noHistoryFlg}
        setShow={setNoHistoryFlg}
        doContinue={() => {
          onNoMedicalHistory();
        }}
      >
        <p>Are you sure to set &quot;No Medical History&quot; to all questions?</p>
      </ConfirmModal>
    </>
  );
};

export default StepMedicalHistory;
