import React from "react";
import { Col, ListGroup, Row } from "react-bootstrap";
import { ExtremeQAModel } from "../client";

export interface ViewExtremeEnvQAProps {
  extreme_qa_list: ExtremeQAModel[] | undefined;
}

const ViewExtremeEnvQA: React.FC<ViewExtremeEnvQAProps> = ({ extreme_qa_list }) => {
  return (
    <ListGroup>
      <ListGroup.Item variant="secondary">
        <Row>
          <Col lg={1}>
            <strong>ID</strong>
          </Col>
          <Col lg={5}>
            <strong>Question</strong>
          </Col>
          <Col lg={6}>
            <strong>Answer</strong>
          </Col>
        </Row>
      </ListGroup.Item>
      {(!extreme_qa_list || extreme_qa_list.length === 0) && (
        <ListGroup.Item className="p-0" variant="secondary">
          <Row className="mx-0">
            <Col lg={12}>Extreme environment questions and answers are not available.</Col>
          </Row>
        </ListGroup.Item>
      )}
      {extreme_qa_list?.map((qa) => {
        return (
          <ListGroup.Item className="p-0" variant="secondary" key={qa.question_id}>
            <Row className="mx-0">
              <Col lg={1} className="py-2">
                {qa.display_index + 246}
              </Col>
              <Col lg={5} className="py-2">
                {qa.question}
              </Col>
              <Col lg={6} className="py-2 bg-light">
                {qa.answer || "-"}
              </Col>
            </Row>
          </ListGroup.Item>
        );
      })}
    </ListGroup>
  );
};

export default ViewExtremeEnvQA;
