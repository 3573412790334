import React from "react";
import { Col, ListGroup, Row } from "react-bootstrap";

export interface QuestionGroupProps {
  children?: React.ReactNode;
}

const QuestionItemList: React.FC<QuestionGroupProps> = ({ children }) => {
  return (
    <ListGroup.Item style={{ paddingLeft: "4em" }}>
      <Row>
        <Col>
          <ListGroup>
            <ListGroup.Item className="bg-light">
              <Row className="py-0">
                <Col lg={5}>
                  <b>Have you ever had?</b>
                </Col>
                <Col lg={2}>
                  <b>Response</b>
                </Col>
                <Col lg={5}>
                  <b>Explanation</b>
                </Col>
              </Row>
            </ListGroup.Item>
            {children}
          </ListGroup>
        </Col>
      </Row>
    </ListGroup.Item>
  );
};

export default QuestionItemList;
