import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card, Col, Form, Row, Spinner, Stack } from "react-bootstrap";
import { useManagerContext } from "../../contexts/ManagerContext";
import { faFolderPlus, faPenToSquare, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import ConfirmModal from "../../component/ConfirmModal";
import CohortEditModal from "./CohortEditModal";
import apiClient from "../../api/api_client";
import { ApiStatus } from "../../model/constant_data";
import CohortMemberTable from "./CohortMemberTable";
import { CohortProperties, QuerryCohortResponse } from "../../client";

const CohortSection: React.FC = () => {
  const {
    state: { cohort_list, cohort, cohort_user_list },
    dispatcher,
  } = useManagerContext();

  const [status, setStatus] = useState<ApiStatus>(ApiStatus.IDEL);
  const [showCohortModal, setShowCohortModal] = useState<boolean>(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState<boolean>(false);

  useEffect(() => {
    // When a list is updated
    if (cohort && cohort.cohort_id) {
      setStatus(ApiStatus.BUSY);
      void (async (): Promise<void> => {
        // Update cohort
        dispatcher({
          type: "SET_COHORT",
          payload: cohort_list.find((item) => item.cohort_id === cohort.cohort_id),
        });

        setStatus(ApiStatus.IDEL);
      })();
    }
  }, [cohort_list]);

  const onChangeCohort = (event: React.ChangeEvent<HTMLSelectElement>) => {
    dispatcher({
      type: "SET_COHORT",
      payload: cohort_list.find((item) => item.cohort_id === event.target.value),
    });

    if (event.target.value) {
      setStatus(ApiStatus.BUSY);
      void (async (): Promise<void> => {
        const users = await apiClient.getCohortUsers(event.target.value);
        dispatcher({
          type: "SET_COHORT_USER_LIST",
          payload: users.sort((a, b) =>
            `${a.first_name} ${a.last_name}`.localeCompare(`${b.first_name} ${b.last_name}`)
          ),
        });
        setStatus(ApiStatus.IDEL);
      })();
    } else {
      dispatcher({
        type: "SET_COHORT_USER_LIST",
        payload: undefined,
      });
    }
  };

  const onAddCohort = () => {
    dispatcher({
      type: "SET_COHORT",
      payload: {
        properties: {} as CohortProperties,
      } as QuerryCohortResponse,
    });

    setShowCohortModal(true);
  };

  const onClickEditCohort = () => {
    dispatcher({
      type: "SET_COHORT",
      payload: cohort,
    });
    setShowCohortModal(true);
  };

  const onClickDeleteCohort = () => {
    setShowDeleteConfirm(true);
  };

  const doDeleteCohort = () => {
    if (cohort !== undefined) {
      setStatus(ApiStatus.BUSY);
      void (async (): Promise<void> => {
        await apiClient.deleteCohort(cohort.cohort_id);
        const cohort_list = await apiClient.getCohortListForAdmin();

        dispatcher({
          type: "SET_COHORT_LIST",
          payload: cohort_list,
        });
        dispatcher({
          type: "SET_COHORT",
          payload: undefined,
        });

        setStatus(ApiStatus.IDEL);
        setShowCohortModal(false);
      })();
    }
  };

  return (
    <>
      <Card border="secondary" key={cohort?.cohort_id} className="mb-5">
        <Card.Header className="bg-warning fw-bold py-2">
          <Row>
            <Form.Group as={Col} lg={4} controlId="cohortName">
              <Form.Label>Available Cohorts:</Form.Label>
              <Form.Select onChange={onChangeCohort} defaultValue={cohort?.cohort_id} aria-label="Select target cohort">
                <option value={undefined}>Please select cohort</option>
                {cohort_list.map((item) => {
                  return (
                    <option value={item.cohort_id} key={item.cohort_id}>
                      {item.cohort_name}
                    </option>
                  );
                })}
              </Form.Select>
            </Form.Group>
            <Form.Group as={Col} lg={4} className="mb-3" controlId="cohortMission">
              <Form.Label>Mission Type:</Form.Label>
              <Form.Control type="text" value={cohort?.properties.mission_type || "-"} readOnly disabled />
            </Form.Group>

            <Col lg={4} className="mb-3 d-flex align-items-end justify-content-end">
              <Stack direction="horizontal" gap={2} className="d-flex align-items-end justify-content-end">
                {status === ApiStatus.BUSY && <Spinner animation="border" />}
                {status !== ApiStatus.BUSY && (
                  <>
                    <FontAwesomeIcon
                      icon={faFolderPlus}
                      size={"xl"}
                      color="green"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        onAddCohort();
                      }}
                    />
                    {cohort ? (
                      <>
                        <FontAwesomeIcon
                          icon={faPenToSquare}
                          size={"xl"}
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            onClickEditCohort();
                          }}
                        />
                        <FontAwesomeIcon
                          icon={faTrashCan}
                          size={"xl"}
                          color="red"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            onClickDeleteCohort();
                          }}
                        />
                      </>
                    ) : (
                      <>
                        <FontAwesomeIcon icon={faPenToSquare} size={"xl"} color="gray" />
                        <FontAwesomeIcon icon={faTrashCan} size={"xl"} color="gray" />
                      </>
                    )}
                  </>
                )}
              </Stack>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body className={`show`}>
          {status === ApiStatus.BUSY && <Spinner animation="border" />}
          {status !== ApiStatus.BUSY && cohort && cohort_user_list && <CohortMemberTable />}
        </Card.Body>
      </Card>

      {/* Add/Edit cohort dialog */}
      <CohortEditModal show={showCohortModal} setShow={setShowCohortModal} />

      {/* Delete cohort dialog */}
      <ConfirmModal show={showDeleteConfirm} setShow={setShowDeleteConfirm} doContinue={doDeleteCohort}>
        <span>
          Are you sure to delete the cohort
          {cohort?.cohort_id && <> ({cohort.cohort_name})</>}?
        </span>
      </ConfirmModal>
    </>
  );
};

export default CohortSection;
