import React from "react";
import { FloatingLabel, Form } from "react-bootstrap";
import { useFlightSurgeonContext } from "../../contexts/FlightSurgeonContext";
import { NotesItem, SurgeonNotesModelResponse } from "../../client";

export const SurgeonNotesSection: React.FC = () => {
  const {
    state: { surgeon_notes },
    dispatcher,
  } = useFlightSurgeonContext();

  return (
    <>
      <FloatingLabel controlId="float_notes" label="Surgeon Notes" className="h-100">
        <Form.Control
          as="textarea"
          className="pt-5 shadow h-100 "
          defaultValue={surgeon_notes?.notes?.contents || ""}
          onChange={(e) => {
            dispatcher({
              type: "SET_SURGEON_NOTES",
              payload: {
                ...surgeon_notes,
                notes: { contents: e.target.value, logs: surgeon_notes?.notes?.logs || [] } as NotesItem,
              } as SurgeonNotesModelResponse,
            });
          }}
        />
      </FloatingLabel>
    </>
  );
};
