import React, { useState } from "react";
import { faSquareMinus, faSquarePlus } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ListGroup } from "react-bootstrap";

export interface QuestionProps {
  index: string | number;
  header: string;
  collapsed?: boolean;
  children: React.ReactNode;
}

const QuestionHeader: React.FC<QuestionProps> = ({ index, header, collapsed = false, children }) => {
  const [collapseStatus, setCollapseStatus] = useState<boolean>(collapsed);
  const onClickCollapse = () => {
    setCollapseStatus(!collapseStatus);
  };

  return (
    <ListGroup.Item onClick={onClickCollapse} role="button" variant="secondary">
      <div className="d-flex">
        <div className="me-3">
          <FontAwesomeIcon icon={collapseStatus ? faSquarePlus : faSquareMinus} />
        </div>
        <div className="me-2">
          <strong>{index}</strong>
        </div>
        <div className="flex-grow-1">
          <strong>{header}</strong>
          <div id="q_1" className={`collapse ${!collapseStatus && "show"}`}>
            {children}
          </div>
        </div>
      </div>
    </ListGroup.Item>
  );
};

export default QuestionHeader;
