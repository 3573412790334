/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * User role (ID).
 */
export enum UserRoleEnum {
    '_1' = 1,
    '_2' = 2,
    '_8' = 8,
    '_9' = 9,
}
