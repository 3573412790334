import React, { useState } from "react";
import { v4 as uuid } from "uuid";
import { Button, Col, Container, Form, Row, Spinner, Stack } from "react-bootstrap";
import { useAuthContext } from "../../contexts/AuthContext";
import { useFlightSurgeonContext } from "../../contexts/FlightSurgeonContext";
import { ApiStatus } from "../../model/constant_data";
import {
  ApplicationReviewResult,
  AssessmentResult,
  DoctorQuestion,
  FollowUpQARequest,
  FollowUpQAStatusEnum,
  FollowUpStatusEnum,
  FollowUpStatusItem,
  MedeicalCategoryEnum,
  NotesItem,
  NotesLogItem,
  OfwodItem,
  OvewviewCostItem,
  OvewviewOfwodItem,
  OvewviewSuitabilityItem,
  OvewviewWaiverItem,
  ReviewFollowupActionRequest,
  ReviewOfwodRequest,
  ReviewRecord,
  ReviewSummaryRequest,
  ReviewWaiverRequest,
  SurgeonNotesModelRequest,
  UpdateAppOverviewAssessmentRequest,
  UpdateFollowUpStatusRequest,
  WaiverItem,
} from "../../client";
import apiClient from "../../api/api_client";
import { ASSESSMENT_CATEGORY_LIST } from "../../model/constant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-regular-svg-icons";
import { ApiErrorModal } from "../../component/ApiErrorModal";

export enum ReviewTypeEnum {
  FIRST_REVIEW = "first_review",
  SECOND_REVIEW = "second_review",
  AMB_REVIEW = "amb_review",
  COMMENT_ONLY = "comment_only",
  ASK_CANDIDATE = "ask_candidate",
}

export const TopMenuSection: React.FC = () => {
  const {
    state: { user },
  } = useAuthContext();

  const {
    state: {
      candidate,
      medical_report,
      review_summary,
      followup_action_list,
      waiver_list,
      ofwod_list,
      followup_qa_list,
      surgeon_notes,
    },
    dispatcher,
  } = useFlightSurgeonContext();

  const [reviewType, setReviewType] = useState<ReviewTypeEnum>(ReviewTypeEnum.COMMENT_ONLY);
  const first_reviewed = candidate?.review?.first_review?.user_id ? true : false;
  const second_reviewed = candidate?.review?.second_review?.user_id ? true : false;
  const amb_reviewed = candidate?.review?.amb_review?.user_id ? true : false;

  const [submitStatus, setSubmitStatus] = useState<ApiStatus>(ApiStatus.IDEL);
  const [apiFailedFlg, setApiFailedFlg] = useState<boolean>(false);

  const onCancelReview = () => {
    dispatcher({
      type: "RESET_DETAILS",
      payload: undefined,
    });
  };

  const getNewQaStatus = (
    reviewType: ReviewTypeEnum,
    qa_status: FollowUpQAStatusEnum | null | undefined
  ): FollowUpQAStatusEnum => {
    if (qa_status) {
      if (reviewType === ReviewTypeEnum.ASK_CANDIDATE) {
        return qa_status === FollowUpQAStatusEnum.ANSWERED ? FollowUpQAStatusEnum.ANSWERED : FollowUpQAStatusEnum.ASKED;
      } else {
        return qa_status;
      }
    } else {
      return reviewType === ReviewTypeEnum.ASK_CANDIDATE ? FollowUpQAStatusEnum.ASKED : FollowUpQAStatusEnum.DRAFT;
    }
  };

  const onSubmitReview = () => {
    void (async () => {
      setSubmitStatus(ApiStatus.BUSY);
      try {
        if (user && candidate && candidate.application_id) {
          // Follow up questions
          const qa_list = followup_qa_list?.map((qa) => {
            return {
              uid: qa.uid,
              application_id: candidate.application_id,
              qa_status: getNewQaStatus(reviewType, qa.qa_status),
              doctor_question: {
                question: qa.doctor_question?.question,
                context_to_candidate: qa.doctor_question?.context_to_candidate,
              } as DoctorQuestion,
              candidate_answer: qa.candidate_answer,
              doctor_notes: qa.doctor_notes,
            } as FollowUpQARequest;
          });
          await apiClient.setApplicationFollowUpQA(candidate.user_id, candidate.application_id, qa_list || []);

          // Follow up questions status
          if (reviewType === ReviewTypeEnum.ASK_CANDIDATE) {
            await apiClient.updateApplicationFollowUpStatus(candidate.user_id, candidate.application_id, {
              followup_status: {
                status: FollowUpStatusEnum.OPEN,
                author: user.user_id,
                timestamp: new Date().toISOString(),
              } as FollowUpStatusItem,
            } as UpdateFollowUpStatusRequest);
          }

          // Waivers
          const waivers = waiver_list?.map((val) => {
            return {
              uid: val.uid,
              application_id: candidate.application_id,
              waiver: {
                section: val.waiver.section || "",
                category: getReportCategory(val.waiver.category),
                title: val.waiver.title || "",
                contents: val.waiver.contents || "",
              } as WaiverItem,
            } as ReviewWaiverRequest;
          });
          await apiClient.overwriteReviewWaivers(candidate.user_id, candidate.application_id, waivers || []);

          // OFWODs
          const ofwods = ofwod_list?.map((val) => {
            return {
              uid: val.uid,
              application_id: candidate.application_id,
              ofwod: {
                section: val.ofwod.section || "",
                category: getReportCategory(val.ofwod.category),
                title: val.ofwod.title,
                contents: val.ofwod.contents,
              } as OfwodItem,
            } as ReviewOfwodRequest;
          });
          await apiClient.overwriteReviewOfwods(candidate.user_id, candidate.application_id, ofwods || []);

          // review summary
          if (review_summary) {
            await apiClient.updateMedicalReviewSummary(
              candidate.user_id,
              candidate.application_id,
              review_summary as ReviewSummaryRequest
            );
          }

          // follow up actions
          const actions = (followup_action_list || []).map((val) => {
            return val as ReviewFollowupActionRequest;
          });
          await apiClient.updateMedicalReviewFollowupActions(candidate.user_id, candidate.application_id, actions);

          // Application overview assessment
          const assessment = {
            cohort_id: candidate.cohort_id,
            assessment: {
              waivers: waivers?.map((val) => {
                return {
                  category: getReportCategory(val.waiver.category),
                  value: 1,
                } as OvewviewWaiverItem;
              }),
              ofwod: ofwods?.map((val) => {
                return {
                  category: getReportCategory(val.ofwod.category),
                  value: 1,
                } as OvewviewOfwodItem;
              }),
              suitability: {
                value: medical_report?.medical_report.suitability
                  ? Number(medical_report?.medical_report.suitability?.value)
                  : null,
                comment: medical_report?.medical_report.suitability?.comment || "",
              } as OvewviewSuitabilityItem,
              quality: medical_report?.medical_report.quality
                ? Number(medical_report?.medical_report.quality?.value)
                : null,
              cost: {
                hours: medical_report?.medical_report.cost ? Number(medical_report?.medical_report.cost?.time) : null,
                usd: medical_report?.medical_report.cost ? Number(medical_report?.medical_report.cost?.USD) : null,
                comment: medical_report?.medical_report.cost?.comment || "",
              } as OvewviewCostItem,
            } as AssessmentResult,
          } as UpdateAppOverviewAssessmentRequest;
          await apiClient.updateApplicationOverviewAssessment(candidate.user_id, candidate.application_id, assessment);

          // Application overview review status
          const review_record = {
            user_id: candidate.user_id,
            review_time: new Date().toISOString(),
          } as ReviewRecord;
          const review_data = {} as ApplicationReviewResult;
          if (reviewType === ReviewTypeEnum.FIRST_REVIEW) {
            review_data.first_review = review_record;
          }
          if (reviewType === ReviewTypeEnum.SECOND_REVIEW) {
            review_data.second_review = review_record;
          }
          if (reviewType === ReviewTypeEnum.AMB_REVIEW) {
            review_data.amb_review = review_record;
          }
          if (reviewType === ReviewTypeEnum.COMMENT_ONLY) {
            review_data.comment_only = review_record;
          }
          if (reviewType === ReviewTypeEnum.ASK_CANDIDATE) {
            review_data.ask_candidate = review_record;
          }
          await apiClient.updateApplicationOverviewReviewResultAssessment(
            candidate.user_id,
            candidate.application_id,
            review_data
          );

          await apiClient.updateApplicationSurgeonNotes(candidate.user_id, candidate.application_id, {
            ...(surgeon_notes || {}),
            uid: surgeon_notes?.uid || uuid(),
            application_id: candidate.application_id,
            notes: {
              contents: surgeon_notes?.notes?.contents || "",
              logs: [
                ...(surgeon_notes?.notes?.logs || []),
                { author: user.user_id, timestamp: new Date().toISOString() } as NotesLogItem,
              ],
            } as NotesItem,
          } as SurgeonNotesModelRequest);
        }

        setSubmitStatus(ApiStatus.IDEL);

        dispatcher({
          type: "RESET_DETAILS",
          payload: undefined,
        });
      } catch (e) {
        setSubmitStatus(ApiStatus.IDEL);
        setApiFailedFlg(true);
      }
    })();
  };

  const getReportCategory = (category: string | null | undefined): string => {
    const cat = category && ASSESSMENT_CATEGORY_LIST.includes(category) ? category : MedeicalCategoryEnum.UNCLASSIFIED;
    return cat;
  };

  return (
    <Container className="border shadow rounded mt-3 bg-light" fluid>
      <Row className="my-2 py-2">
        <Col className="d-flex justify-content-end">
          <div className="me-4">
            <Button variant="danger" className="mx-2" onClick={onCancelReview}>
              Cancel
            </Button>
            <Button
              variant="success"
              className="mx-2"
              onClick={onSubmitReview}
              disabled={submitStatus === ApiStatus.BUSY}
            >
              {submitStatus === ApiStatus.BUSY && <Spinner animation="border" size="sm" className="me-2" />}
              Submit
            </Button>
          </div>
          <div className="me-4">
            <Stack>
              {first_reviewed ? (
                <Form.Group>
                  <Form.Label className="mb-0">
                    <FontAwesomeIcon icon={faCircleCheck} className="fa-m me-2" color={"green"} />
                    First Review
                  </Form.Label>
                </Form.Group>
              ) : (
                <>
                  {amb_reviewed || second_reviewed ? (
                    <Form.Check
                      checked={false}
                      disabled
                      type="radio"
                      label={`First Review`}
                      name="rdo_review_type"
                      id={`rdo_first_review`}
                    />
                  ) : (
                    <Form.Check
                      checked={reviewType === ReviewTypeEnum.FIRST_REVIEW}
                      onChange={() => setReviewType(ReviewTypeEnum.FIRST_REVIEW)}
                      type="radio"
                      label={`First Review`}
                      name="rdo_review_type"
                      id={`rdo_first_review`}
                    />
                  )}
                </>
              )}
              {second_reviewed ? (
                <Form.Group>
                  <Form.Label className="mb-0">
                    <FontAwesomeIcon icon={faCircleCheck} className="fa-m me-2" color={"green"} />
                    Second Review
                  </Form.Label>
                </Form.Group>
              ) : (
                <>
                  {amb_reviewed ? (
                    <Form.Check
                      checked={false}
                      disabled
                      type="radio"
                      label={`Second Review`}
                      name="rdo_review_type"
                      id={`rdo_secon_review`}
                    />
                  ) : (
                    <Form.Check
                      checked={reviewType === ReviewTypeEnum.SECOND_REVIEW}
                      onChange={() => setReviewType(ReviewTypeEnum.SECOND_REVIEW)}
                      type="radio"
                      label={`Second Review`}
                      name="rdo_review_type"
                      id={`rdo_secon_review`}
                    />
                  )}
                </>
              )}
              {amb_reviewed ? (
                <Form.Group>
                  <Form.Label className="mb-0">
                    <FontAwesomeIcon icon={faCircleCheck} className="fa-m me-2" color={"green"} />
                    AMB Review
                  </Form.Label>
                </Form.Group>
              ) : (
                <Form.Check
                  checked={reviewType === ReviewTypeEnum.AMB_REVIEW}
                  onChange={() => setReviewType(ReviewTypeEnum.AMB_REVIEW)}
                  type="radio"
                  label={`AMB Review`}
                  name="rdo_review_type"
                  id={`rdo_amb_review`}
                />
              )}
              <Form.Check
                checked={reviewType === ReviewTypeEnum.COMMENT_ONLY}
                onChange={() => setReviewType(ReviewTypeEnum.COMMENT_ONLY)}
                type="radio"
                label={`Comment Only`}
                name="rdo_review_type"
                id={`rdo_comment_only`}
              />
              <Form.Check
                checked={reviewType === ReviewTypeEnum.ASK_CANDIDATE}
                onChange={() => setReviewType(ReviewTypeEnum.ASK_CANDIDATE)}
                type="radio"
                label={`Ask Candidate`}
                name="rdo_review_type"
                id={`rdo_ask_candidate`}
              />
            </Stack>
          </div>
        </Col>
      </Row>

      <ApiErrorModal show={apiFailedFlg} setShow={setApiFailedFlg} />
    </Container>
  );
};
