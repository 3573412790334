import React from "react";
import {
  FormCategoryEnum,
  ApplicationResponse,
  ApplicationDetailResponse,
  DemographicsBasic,
  MedicationBasic,
  MedicationItemModel,
  QueryFormResponse,
  MedicalHistoryBasic,
  MedicalVisitBasic,
  ExtremeQAModel,
  FollowUpQAResponse,
  ExerciseBasic,
  ApplicationOverviewModel,
} from "../client";

/**
 * Application Edit Data, optional version of ApplicationResponse.
 */
export type ApplicationGeneralModel = {
  application_id?: string;
  category?: FormCategoryEnum;
  form_id?: number;
};

export interface MemberContextState {
  step: number;
  application_list?: ApplicationResponse[];

  general?: ApplicationGeneralModel;

  demographics?: DemographicsBasic;

  medication?: MedicationBasic;
  medication_item?: MedicationItemModel;

  question_from?: QueryFormResponse;
  medical_history?: MedicalHistoryBasic;

  extreme_qa_list?: ExtremeQAModel[];

  medical_visit?: MedicalVisitBasic;

  exercise?: ExerciseBasic;

  overview?: ApplicationOverviewModel;

  followup_qa_list?: FollowUpQAResponse[] | undefined;
}

export type MemberActions =
  | {
      type: "SET_APPLICATION_LIST";
      payload: ApplicationResponse[];
    }
  | {
      type: "SET_APPLICATION_GENERAL";
      payload: ApplicationGeneralModel | undefined;
    }
  | {
      type: "SET_APPLICATION_DETAILS";
      payload: ApplicationDetailResponse | undefined;
    }
  | {
      type: "CLEAR_DETAILS";
      payload: undefined;
    }
  | {
      type: "SET_DEMOGRAPHICS";
      payload: DemographicsBasic | undefined;
    }
  | {
      type: "SET_MEDICATION";
      payload: MedicationBasic | undefined;
    }
  | {
      type: "SET_MEDICATION_ITEM";
      payload: MedicationItemModel | undefined;
    }
  | {
      type: "SET_QUESTION_FORM";
      payload: QueryFormResponse;
    }
  | {
      type: "SET_MEDICAL_HISTORY";
      payload: MedicalHistoryBasic;
    }
  | {
      type: "SET_MEDICAL_VISIT";
      payload: MedicalVisitBasic;
    }
  | {
      type: "SET_EXERCISE";
      payload: ExerciseBasic;
    }
  | {
      type: "SET_OVERVIEW";
      payload: ApplicationOverviewModel;
    }
  | {
      type: "SET_EXTREME_QA_LIST";
      payload: ExtremeQAModel[] | undefined;
    }
  | {
      type: "SET_FOLLOWUP_QA_LIST";
      payload: FollowUpQAResponse[] | undefined;
    }
  | {
      type: "SET_STEP";
      payload: number;
    };

const memberReducer: React.Reducer<MemberContextState, MemberActions> = (state, action) => {
  switch (action.type) {
    case "SET_STEP":
      return { ...state, step: action.payload };
    case "SET_APPLICATION_LIST":
      return { ...state, application_list: action.payload };
    case "SET_APPLICATION_GENERAL":
      return { ...state, general: action.payload };
    case "SET_APPLICATION_DETAILS":
      return {
        ...state,
        demographics: action.payload?.demographics,
        medication: action.payload?.medication,
        medical_history: action.payload?.medical_history,
        medical_visit: action.payload?.medical_visit,
        exercise: action.payload?.exercise || undefined,
        extreme_qa_list: action.payload?.extreme_qa_list,
        overview: action.payload?.overview || undefined,
      };
    case "CLEAR_DETAILS":
      return {
        ...state,
        application_id: undefined,
        general: undefined,
        demographics: undefined,
        medication: undefined,
        medication_item: undefined,
        question_from: undefined,
        medical_history: undefined,
        medical_visit: undefined,
        extreme_qa_list: undefined,
        overview: undefined,
      };
    case "SET_DEMOGRAPHICS":
      return { ...state, demographics: action.payload };
    case "SET_MEDICATION":
      return { ...state, medication: action.payload };
    case "SET_MEDICATION_ITEM":
      return { ...state, medication_item: action.payload };
    case "SET_QUESTION_FORM":
      return { ...state, question_from: action.payload };
    case "SET_MEDICAL_HISTORY":
      return { ...state, medical_history: action.payload };
    case "SET_MEDICAL_VISIT":
      return { ...state, medical_visit: action.payload };
    case "SET_EXERCISE":
      return { ...state, exercise: action.payload };
    case "SET_OVERVIEW":
      return { ...state, overview: action.payload };
    case "SET_EXTREME_QA_LIST":
      return { ...state, extreme_qa_list: action.payload };
    case "SET_FOLLOWUP_QA_LIST":
      return { ...state, followup_qa_list: action.payload };
  }
};

export type MemberContextType = {
  state: MemberContextState;
  dispatcher: React.Dispatch<React.ReducerAction<React.Reducer<MemberContextState, MemberActions>>>;
};

const MemberContext = React.createContext<MemberContextType | undefined>(undefined);

export interface MainProviderProps {
  children: React.ReactNode;
}

const MemberContextProvider: React.FC<MainProviderProps & MemberContextType> = ({ children, ...context }) => (
  <MemberContext.Provider value={context}>{children}</MemberContext.Provider>
);

const useMemberContext = (): MemberContextType => {
  const context = React.useContext(MemberContext) as MemberContextType;
  if (context == null) {
    throw new Error("useMemberContext must be used within MemberContextProvider");
  }
  return context;
};

export { MemberContextProvider, useMemberContext, memberReducer };
