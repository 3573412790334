/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * AI Status.
 */
export enum AIStatusEnum {
    PROCESSING = 'processing',
    DONE = 'done',
    ERROR = 'error',
}
