import React from "react";
import { Col, ListGroup, Row } from "react-bootstrap";
import { dosage_unit_list, frequency_list, getDisplayName } from "../model/constant_data";
import { MedicationBasic } from "../client";

export interface ViewMedicationProps {
  medication?: MedicationBasic | undefined;
}

const ViewMedication: React.FC<ViewMedicationProps> = ({ medication }) => {
  return (
    <ListGroup>
      <ListGroup.Item variant="secondary">
        <Row>
          <Col lg={1}>
            <strong>ID</strong>
          </Col>
          <Col lg={3}>
            <strong>Question</strong>
          </Col>
          <Col lg={8}>
            <strong>Answer</strong>
          </Col>
        </Row>
      </ListGroup.Item>
      <ListGroup.Item className="p-0" variant="secondary">
        <Row className="mx-0">
          <Col lg={1} className="py-2">
            10
          </Col>
          <Col lg={3} className="py-2">
            Do You Currently Use Any Medication (Prescription or Nonprescription)
          </Col>
          <Col lg={8} className="py-2 bg-light">
            <p className="mb-2">{medication?.has_medication ? "Yes" : "No"}</p>

            {medication?.has_medication && medication?.medication_list && medication?.medication_list.length > 0 && (
              <Row>
                <Col className="my-3">
                  <ListGroup>
                    <ListGroup.Item variant="secondary">
                      <Row className="py-1">
                        <Col lg={4}>Medication Name</Col>
                        <Col lg={2}>Dosage</Col>
                        <Col lg={2}>Dosage Unit</Col>
                        <Col lg={2}>Frequency</Col>
                        <Col lg={2}>Previously Reported</Col>
                      </Row>
                    </ListGroup.Item>
                    {medication?.medication_list.map((item, idx) => {
                      return (
                        <ListGroup.Item action key={idx}>
                          <Row className="py-2">
                            <Col lg={4}>{item.name}</Col>
                            <Col lg={2}>{item.dosage}</Col>
                            <Col lg={2}>{item.dosage_unit && getDisplayName(dosage_unit_list, item.dosage_unit)}</Col>
                            <Col lg={2}>{item.frequency && getDisplayName(frequency_list, item.frequency)}</Col>
                            <Col lg={2}>{item.reported ? "Y" : "N"}</Col>
                          </Row>
                        </ListGroup.Item>
                      );
                    })}
                  </ListGroup>
                </Col>
              </Row>
            )}
          </Col>
        </Row>
      </ListGroup.Item>
      <ListGroup.Item className="p-0" variant="secondary">
        <Row className="mx-0">
          <Col lg={1} className="py-2">
            11
          </Col>
          <Col lg={3} className="py-2">
            Do You Ever Use Near Vision Contact Lenses While Driving
          </Col>
          <Col lg={8} className="py-2 bg-light">
            <p className="mb-2">{medication?.has_contact_lens ? "Yes" : "No"}</p>
          </Col>
        </Row>
      </ListGroup.Item>
    </ListGroup>
  );
};

export default ViewMedication;
