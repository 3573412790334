/* eslint-disable no-case-declarations */
import React from "react";
import {
  QuerryCohortResponse,
  QuerryCohortUserResponse,
  QueryUserResponse,
} from "../client";

export interface ManagerContextState {
  surgeon_list?: QueryUserResponse[];
  flight_surgeon?: QueryUserResponse;

  cohort_list: QuerryCohortResponse[];
  cohort?: QuerryCohortResponse;
  cohort_user_list?: QuerryCohortUserResponse[];
  candidate?: QuerryCohortUserResponse;
}

export type ManagerActions =
  | {
      type: "SET_SURGEON_LIST";
      payload: QueryUserResponse[] | undefined;
    }
  | {
      type: "SET_FLIGHT_SURGEON";
      payload: QueryUserResponse | undefined;
    }
  | {
      type: "SET_COHORT_LIST";
      payload: QuerryCohortResponse[];
    }
  | {
      type: "SET_COHORT";
      payload: QuerryCohortResponse | undefined;
    }
  | {
      type: "SET_COHORT_USER_LIST";
      payload: QuerryCohortUserResponse[] | undefined;
    }
  // | {
  //     type: "ADD_COHORT";
  //     payload: CohortModel;
  //   }
  // | {
  //     type: "UPDATE_COHORT";
  //     payload: CohortModel;
  //   }
  // | {
  //     type: "DELETE_COHORT";
  //     payload: CohortModel;
  //   }
  // | {
  //     type: "ADD_CANDIDATE";
  //     payload: CohortMemberModel;
  //   }
  // | {
  //     type: "UPDATE_CANDIDATE";
  //     payload: CohortMemberModel;
  //   }
  // | {
  //     type: "DELETE_CANDIDATE";
  //     payload: CohortMemberModel;
  //   }
  | {
      type: "SET_CANDIDATE";
      payload: QuerryCohortUserResponse | undefined;
    };

const managerReducer: React.Reducer<ManagerContextState, ManagerActions> = (
  state,
  action
) => {
  switch (action.type) {
    case "SET_SURGEON_LIST":
      return { ...state, surgeon_list: action.payload };
    case "SET_FLIGHT_SURGEON":
      return { ...state, flight_surgeon: action.payload };
    case "SET_COHORT_LIST":
      return {
        ...state,
        cohort_list: action.payload,
        // cohort: undefined,
        // cohort_user_list: undefined,
      };
    case "SET_COHORT":
      return {
        ...state,
        cohort: action.payload,
        // cohort_user_list: undefined
      };
    case "SET_COHORT_USER_LIST":
      return { ...state, cohort_user_list: action.payload };
    // case "ADD_COHORT":
    //   return {
    //     ...state,
    //     cohort_list: [...state.cohort_list, action.payload].sort((a, b) =>
    //       a.name > b.name ? 1 : -1
    //     ),
    //   };
    // case "UPDATE_COHORT":
    //   return {
    //     ...state,
    //     cohort_list: [
    //       ...state.cohort_list.filter((item) => item.id !== action.payload.id),
    //       action.payload,
    //     ].sort((a, b) => (a.name > b.name ? 1 : -1)),
    //   };
    // case "DELETE_COHORT":
    //   return {
    //     ...state,
    //     cohort_list: [
    //       ...state.cohort_list.filter((item) => item.id !== action.payload.id),
    //     ],
    //   };
    // case "ADD_CANDIDATE":
    //   const cohort1 = state.cohort_list.find(
    //     (item) => item.id === action.payload.cohort_id
    //   );
    //   if (!cohort1) {
    //     throw new Error(
    //       "Can not add cannidate as the Cohort has been deleted."
    //     );
    //   }
    //   return {
    //     ...state,
    //     cohort_list: [
    //       ...state.cohort_list.filter(
    //         (item) => item.id !== action.payload.cohort_id
    //       ),
    //       {
    //         ...cohort1,
    //         candidate_list: [...cohort1.candidate_list, action.payload].sort(
    //           (a, b) => ((a.first_name || "") > (b.first_name || "") ? 1 : -1)
    //         ),
    //       },
    //     ].sort((a, b) => (a.name > b.name ? 1 : -1)),
    //     candidate: undefined,
    //   };
    // case "UPDATE_CANDIDATE":
    //   const cohort2 = state.cohort_list.find(
    //     (item) => item.id === action.payload.cohort_id
    //   );
    //   if (!cohort2) {
    //     throw new Error(
    //       "Can not add cannidate as the Cohort has been deleted."
    //     );
    //   }
    //   return {
    //     ...state,
    //     cohort_list: [
    //       ...state.cohort_list.filter(
    //         (item) => item.id !== action.payload.cohort_id
    //       ),
    //       {
    //         ...cohort2,
    //         candidate_list: [
    //           ...cohort2.candidate_list.filter(
    //             (item) => item.user_id !== action.payload.user_id
    //           ),
    //           action.payload,
    //         ].sort((a, b) =>
    //           (a.first_name || "") > (b.first_name || "") ? 1 : -1
    //         ),
    //       },
    //     ].sort((a, b) => (a.name > b.name ? 1 : -1)),
    //     candidate: undefined,
    //   };
    // case "DELETE_CANDIDATE":
    //   const cohort = state.cohort_list.find(
    //     (item) => item.id === action.payload.cohort_id
    //   );
    //   if (!cohort) {
    //     throw new Error(
    //       "Can not add cannidate as the Cohort has been deleted."
    //     );
    //   }
    //   return {
    //     ...state,
    //     cohort_list: [
    //       ...state.cohort_list.filter(
    //         (item) => item.id !== action.payload.cohort_id
    //       ),
    //       {
    //         ...cohort,
    //         candidate_list: cohort.candidate_list.filter(
    //           (item) => item.user_id !== action.payload.user_id
    //         ),
    //       },
    //     ].sort((a, b) => (a.name > b.name ? 1 : -1)),
    //   };
    case "SET_CANDIDATE":
      return { ...state, candidate: action.payload };
  }
};

export type ManagerContextType = {
  state: ManagerContextState;
  dispatcher: React.Dispatch<
    React.ReducerAction<React.Reducer<ManagerContextState, ManagerActions>>
  >;
};

const ManagerContext = React.createContext<ManagerContextType | undefined>(
  undefined
);

export interface ManagerProviderProps {
  children: React.ReactNode;
}

const ManagerContextProvider: React.FC<
  ManagerProviderProps & ManagerContextType
> = ({ children, ...context }) => (
  <ManagerContext.Provider value={context}>{children}</ManagerContext.Provider>
);

const useManagerContext = (): ManagerContextType => {
  const context = React.useContext(ManagerContext) as ManagerContextType;
  if (context == null) {
    throw new Error(
      "useManagerContext must be used within AdminContextProvider"
    );
  }
  return context;
};

export { ManagerContextProvider, useManagerContext, managerReducer };
