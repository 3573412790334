import React, { useState } from "react";
// import { v4 as uuid } from "uuid";
import {
  Button,
  Col,
  Container,
  FloatingLabel,
  Form,
  Modal,
  Row,
  Spinner,
} from "react-bootstrap";
import { useManagerContext } from "../../contexts/ManagerContext";
import apiClient from "../../api/api_client";
import { CreateCohortRequest, UpdateCohortRequest } from "../../client";
import { ApiStatus } from "../../model/constant_data";

export interface CohortEditModalProps {
  show: boolean;
  setShow: (val: boolean) => void;
}

const CohortEditModal: React.FC<CohortEditModalProps> = ({ show, setShow }) => {
  const {
    state: { cohort },
    dispatcher,
  } = useManagerContext();

  const [validated, setValidated] = useState<boolean | undefined>(undefined);
  const [status, setStatus] = useState<ApiStatus>(ApiStatus.IDEL);

  const onEditCohort = (key: string, value: string | number | undefined) => {
    if (cohort) {
      dispatcher({
        type: "SET_COHORT",
        payload: { ...cohort, [key]: value },
      });
    }
  };

  const onEditCohortProperties = (
    key: string,
    value: string | number | undefined
  ) => {
    if (cohort) {
      dispatcher({
        type: "SET_COHORT",
        payload: {
          ...cohort,
          properties: { ...cohort.properties, [key]: value },
        },
      });
    }
  };

  const onSubmitCohort = () => {
    if (cohort) {
      if (cohort?.cohort_name) {
        setStatus(ApiStatus.BUSY);
        setValidated(true);

        void (async (): Promise<void> => {
          if (cohort.cohort_id === undefined) {
            await apiClient.createCohort({
              cohort_name: cohort.cohort_name,
              properties: cohort.properties || {},
            } as CreateCohortRequest);
          } else {
            await apiClient.updateCohort({
              cohort_id: cohort.cohort_id,
              cohort_name: cohort.cohort_name,
              properties: cohort.properties || {},
            } as UpdateCohortRequest);
          }

          const cohorts = await apiClient.getCohortListForAdmin();
          dispatcher({
            type: "SET_COHORT_LIST",
            payload: cohorts,
          });
          dispatcher({
            type: "SET_COHORT",
            payload: undefined,
          });

          setStatus(ApiStatus.IDEL);
          setShow(false);
        })();
      } else {
        setValidated(false);
      }
    }
  };

  return (
    <>
      <Modal size="xl" centered show={show}>
        <Modal.Header closeButton onHide={() => setShow(false)}>
          <Modal.Title>
            {cohort?.cohort_id ? <>Edit Cohort</> : <>Add Cohort</>}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container className="mt-3 border rounded bg-light">
            <Form className="b">
              <Row className="mt-3">
                <Col lg={6}>
                  <FloatingLabel
                    controlId="f_name"
                    label="Name"
                    className="mb-3"
                  >
                    <Form.Control
                      placeholder="Cohort name"
                      required
                      defaultValue={cohort?.cohort_name}
                      isInvalid={validated === false && !cohort?.cohort_name}
                      onChange={(e) => {
                        onEditCohort("cohort_name", e.target.value);
                      }}
                    />
                    <Form.Control.Feedback type="invalid">
                      Cohort name is required
                    </Form.Control.Feedback>
                  </FloatingLabel>
                </Col>
                <Col lg={6}>
                  <FloatingLabel
                    controlId="f_mission_type"
                    label="Mission Type"
                    className="mb-3"
                  >
                    <Form.Control
                      placeholder="Mission Type"
                      required
                      defaultValue={
                        cohort?.properties.mission_type ?? undefined
                      }
                      isInvalid={
                        validated === false && !cohort?.properties?.mission_type
                      }
                      onChange={(e) => {
                        onEditCohortProperties("mission_type", e.target.value);
                      }}
                    />
                    <Form.Control.Feedback type="invalid">
                      Cohort name is required
                    </Form.Control.Feedback>
                  </FloatingLabel>
                </Col>
              </Row>
            </Form>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          {status === ApiStatus.BUSY ? (
            <>
              <Spinner animation="border" />
            </>
          ) : (
            <>
              <Button variant="primary" onClick={() => setShow(false)}>
                Cancel
              </Button>
              <Button variant="success" onClick={onSubmitCohort}>
                {cohort?.cohort_id ? <>Update</> : <>Add</>}
              </Button>
            </>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CohortEditModal;
