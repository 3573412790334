export enum AuthEvent {
    logout = 'logout',
  }
  
  const authEventBus = {
    on(event: AuthEvent, listener: EventListenerOrEventListenerObject) {
      document.addEventListener(event, listener);
    },
  
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dispatch(event: AuthEvent, data: any) {
      document.dispatchEvent(new CustomEvent(event, { detail: data }));
    },
  
    remove(event: AuthEvent, listener: EventListenerOrEventListenerObject) {
      document.removeEventListener(event, listener);
    },
  };
  
  export default authEventBus;
  