import React from "react";
import { v4 as uuid } from "uuid";
import { Col, ListGroup, Row } from "react-bootstrap";
import { getCountryName } from "./CountrySelector";
import { showMMYYYY } from "../api/ui_util";
import { MedicalVisitBasic } from "../client";

export interface ViewMedicationVisitProps {
  medical_visit?: MedicalVisitBasic | undefined;
}

const ViewMedicationVisit: React.FC<ViewMedicationVisitProps> = ({ medical_visit }) => {
  return (
    <ListGroup>
      <ListGroup.Item variant="secondary">
        <Row>
          <Col lg={1}>
            <strong>ID</strong>
          </Col>
          <Col lg={3}>
            <strong>Question</strong>
          </Col>
          <Col lg={8}>
            <strong>Answer</strong>
          </Col>
        </Row>
      </ListGroup.Item>

      <ListGroup.Item className="p-0" variant="secondary" key={uuid()}>
        <Row className="mx-0">
          <Col lg={1} className="py-2">
            246
          </Col>
          <Col lg={3} className="py-2">
            Have you visited any health professionals within the last 3 years?
          </Col>
          <Col lg={8} className="py-2 bg-light">
            <p className="mb-2">{medical_visit?.has_visit ? "Yes" : "No"}</p>
            {medical_visit?.has_visit && (
              <div className="m-2">
                <Row className="mt-4 py-2 bg-secondary-subtle rounded">
                  <Col lg={1}>Date</Col>
                  <Col lg={1}>Name</Col>
                  <Col lg={2}>Number/Street</Col>
                  <Col lg={1}>City</Col>
                  <Col lg={1}>State</Col>
                  <Col lg={1}>Zip Code</Col>
                  <Col lg={1}>Country</Col>
                  <Col lg={2}>Type Professional</Col>
                  <Col lg={2}>Reason</Col>
                </Row>
                {medical_visit?.visit_list?.map((visit) => {
                  return (
                    <Row className="py-2 border-bottom" key={uuid()}>
                      <Col lg={1}>{showMMYYYY(visit.date || undefined)}</Col>
                      <Col lg={1}>{visit.name}</Col>
                      <Col lg={2}>{visit.address}</Col>
                      <Col lg={1}>{visit.city}</Col>
                      <Col lg={1}>{visit.state}</Col>
                      <Col lg={1}>{visit.zip}</Col>
                      <Col lg={1}>{getCountryName(visit.country)}</Col>
                      <Col lg={2}>{visit.type}</Col>
                      <Col lg={2}>{visit.reason}</Col>
                    </Row>
                  );
                })}
                {(!medical_visit || !medical_visit.visit_list || medical_visit.visit_list.length === 0) && (
                  <Row className="py-2 border-bottom">
                    <Col lg={12} className="py-2">
                      No medical visit recorded
                    </Col>
                  </Row>
                )}
              </div>
            )}
          </Col>
        </Row>
      </ListGroup.Item>
    </ListGroup>
  );
};

export default ViewMedicationVisit;
